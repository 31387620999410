<div [class]="'qvsum quoting-theme ' + screenSize.getClasses()">
  <div class="qvsum-header">
    <div class="qvsum-header-info">
      <h4 class="qvsum-parts">Total Items: <span class="qvsum-parts-val">{{ itemTotal }}</span></h4>
      <h4 class="qvsum-cost">
        Total Cost: <span class="qvsum-parts-val"><span class="qvsum-cost-val-icon">$</span> {{ costTotal.toFixed(2) }}</span>
      </h4>
      <h4 class="qvsum-cost">
        Raw Cost: <span class="qvsum-parts-val"><span class="qvsum-cost-val-icon">$</span> {{ (partCost + laborCost).toFixed(2) }}</span>
      </h4>

      <hr />
      <h4 class="qvsum-parts">Mat Items: <span class="qvsum-parts-val">{{ partTotal }}</span></h4>
      <h4 class="qvsum-cost">
        Mat Cost: <span class="qvsum-parts-val"><span class="qvsum-cost-val-icon">$</span> {{ partTotalCost.toFixed(2) }}</span>
      </h4>
      <h4 class="qvsum-cost">
        Raw Mat Cost: <span class="qvsum-parts-val"><span class="qvsum-cost-val-icon">$</span> {{ partCost.toFixed(2) }}</span>
      </h4>

      <hr />
      <h4 class="qvsum-parts">Labor Items: <span class="qvsum-parts-val">{{ laborTotal }}</span></h4>
      <h4 class="qvsum-cost">
        Labor Cost: <span class="qvsum-parts-val"><span class="qvsum-cost-val-icon">$</span> {{ laborTotalCost.toFixed(2) }}</span>
      </h4>
      <h4 class="qvsum-cost">
        Raw Labor Cost:  <span class="qvsum-parts-val"><span class="qvsum-cost-val-icon">$</span> {{ laborCost.toFixed(2) }}</span>
      </h4>
      <hr />

      <div class="add-attachment" >
      <h4 class="qvsum-cost"> Attachments</h4>
        <button
        mat-flat-button
        class="add-attachment-btn" (click)="openDialog()"
        >
        <mat-icon mat-list-icon>folder_open</mat-icon>

        </button>
    </div>

    </div>
    <div class="qvsum-controls">
      <mat-form-field
        appearance="outline"
        class="qvsum-status-ff qvsum-ff"
      >
        <mat-label>Status</mat-label>
        <mat-select
          [disabled]="saving"
          [formControl]="statusControl"
          class="qvsum-status-ff-sel qvsum-sel"
        >
          <mat-option *ngFor="let status of quoteStatuses" [value]="status.valueOf()">
            {{ status }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="qvsum-ff">
        <mat-label>Region</mat-label>
        <mat-select
          [formControl]="regionControl"
          [disabled]="saving"
        >
          <mat-option *ngFor="let reg of regions" [value]="reg.valueOf()">{{reg}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        class="qvsum-desc-ff qvsum-ff"
      >
        <mat-label>Builder</mat-label>
        <input
          [disabled]="saving"
          [formControl]="builderControl"
          matInput
          type="text"
          class="qvsum-desc-ff-in"
        />
      </mat-form-field>

      <mat-form-field appearance="outline" class="qvsum-ff">
        <mat-label>Sq. Footage</mat-label>
        <input
          [disabled]="saving"
          [formControl]="squareFootageControl"
          (input)="squareFootageInput($event)"
          matInput
          type="text"
          class="quotesview-create-ff-in"
        />
      </mat-form-field>

      <mat-form-field appearance="outline" class="qvsum-exp-ff qvsum-ff">
        <mat-label>Expiration</mat-label>
        <input
          [formControl]="expirationControl"
          [matDatepicker]="datePicker"
          [min]="minExpiration"
          [disabled]="saving"
          matInput
          class="qvsum-exp-ff-in"
        />
        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker #datePicker [disabled]="saving"></mat-datepicker>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        class="qvsum-desc-ff qvsum-ff"
      >
        <mat-label>Description</mat-label>
        <textarea
          [disabled]="saving"
          [formControl]="descControl"
          matInput
          type="text"
          class="qvsum-desc-ff-in"
        ></textarea>
      </mat-form-field>

      <!-- <mat-form-field
        appearance="outline"
        class="qvsum-matmar-ff qvsum-ff qvsum-percent-ff"
      >
        <mat-label>Material Margin Cost</mat-label>
        <mat-icon matPrefix class="">
          <span class="material-symbols-outlined qvsum-ff-icon">
            $
          </span>
        </mat-icon>
        <input
          [disabled]="saving"
          [value]="getMaterialDollar()"
          (input)="materialDollarInput($event)"
          matInput
          type="text"
          class="qvsum-matmar-ff-in qvsum-margin-ff-in"
        />
      </mat-form-field> -->

      <mat-form-field
        appearance="outline"
        class="qvsum-matmar-ff qvsum-ff qvsum-percent-ff"
      >
        <mat-label>Material Margin</mat-label>
        <input
          [disabled]="saving"
          [formControl]="materialMarginControl"
          (input)="materialInput($event)"
          matInput
          type="text"
          class="qvsum-matmar-ff-in qvsum-margin-ff-in"
        />
        <mat-icon matSuffix class="">
          <span class="material-symbols-outlined qvsum-ff-icon">
            percent
          </span>
        </mat-icon>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        class="qvsum-labmar-ff qvsum-ff qvsum-percent-ff"
      >
        <mat-label>Labor Margin</mat-label>
        <input
          [disabled]="saving"
          [formControl]="laborMarginControl"
          (input)="laborInput($event)"
          matInput
          type="text"
          class="qvsum-labmar-ff-in qvsum-margin-ff-in"
        />
        <mat-icon matSuffix class="">
          <span class="material-symbols-outlined qvsum-ff-icon">
            percent
          </span>
        </mat-icon>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        class="qvsum-gibmar-ff qvsum-ff qvsum-percent-ff"
      >
        <mat-label>Gibson Margin</mat-label>
        <input
          [disabled]="saving"
          [formControl]="gibsonMarginControl"
          (input)="gibsonInput($event)"
          matInput
          type="text"
          class="qvsum-gibmar-ff-in qvsum-margin-ff-in"
        />
        <mat-icon matSuffix class="">
          <span class="material-symbols-outlined qvsum-ff-icon">
            percent
          </span>
        </mat-icon>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        class="qvsum-taxmar-ff qvsum-ff qvsum-percent-ff"
      >
        <mat-label>Tax Margin</mat-label>
        <input
          [disabled]="saving"
          [formControl]="taxMarginControl"
          (input)="taxInput($event)"
          matInput
          type="text"
          class="qvsum-taxmar-ff-in qvsum-margin-ff-in"
        />
        <mat-icon matSuffix class="">
          <span class="material-symbols-outlined qvsum-ff-icon">
            percent
          </span>
        </mat-icon>
      </mat-form-field>

      <mat-form-field
      appearance="outline"
      class="qvsum-fixtures-ff qvsum-ff"
    >
      <mat-label>Fixtures</mat-label>
      <textarea
        [disabled]="saving"
        [formControl]="fixturesControl"
        matInput
        type="text"
        class="qvsum-fixtures-ff-in"
      ></textarea>
    </mat-form-field>

    <mat-form-field
    appearance="outline"
    class="qvsum-stories-ff qvsum-ff"
  >
    <mat-label>Stories</mat-label>
    <textarea
      [disabled]="saving"
      [formControl]="storiesControl"
      matInput
      type="text"
      class="qvsum-stories-ff-in"
    ></textarea>
  </mat-form-field>

  <mat-form-field
  appearance="outline"
  class="qvsum-bath-ff qvsum-ff"
>
  <mat-label>Bath</mat-label>
  <textarea
    [disabled]="saving"
    [formControl]="bathControl"
    matInput
    type="text"
    class="qvsum-bath-ff-in"
  ></textarea>
</mat-form-field>

<mat-form-field
appearance="outline"
class="qvsum-waterheater-ff qvsum-ff"
>
<mat-label>water Heater</mat-label>
<textarea
  [disabled]="saving"
  [formControl]="waterHeatersControl"
  matInput
  type="text"
  class="qvsum-waterheater-ff-in"
></textarea>
</mat-form-field>

      <div
          class="qvsum-actions"
        >
          <button
            (click)="resetControls()"
            [disabled]="cancelDisabled()"
            color="warn"
            mat-flat-button
            class="qvsum-actions-cancel"
          >
            Cancel
          </button>
          <button
            [disabled]="!canSave()"
            (click)="saveChanges()"
            color="primary"
            mat-flat-button
            class="qvsum-actions-save"
          >
            Save
          </button>
        </div>
      </div>
      </div>
    </div>
    <div>
  <div class="qvsum-finalize">
    <button
    (click)="finalizeBid()"
    [disabled]="loadingPDF"
      breadcrumb-end-content
      color="primary"
      mat-flat-button
      class="qvsum-finalize-btn"
    >
      Finalize Bid
    </button>
  </div>
</div>
