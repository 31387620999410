import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { share, shareReplay } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { UploadModalComponent } from 'src/app/Components/Platform/upload-modal/upload-modal.component';

@Injectable({
  providedIn: 'root',
})
export class UploadModalService {
  constructor(private dialog: MatDialog) {}

  openWasabiUpload(
    title: string,
    upload: string,
    download: string,
    uploadType?: undefined | 'all' | 'atATime'
  ): Observable<File[]> {
    const obs: Observable<File[]> = new Observable(subscriber => {
      const dialogRef = this.dialog.open(UploadModalComponent, {
        data: { title, upload, download, uploadType },
      });
      dialogRef.afterClosed().subscribe((result: File[]) => {
        if (result != null && result.length > 0) {
          subscriber.next(result);
          subscriber.complete();
        } else {
          subscriber.next([]);
          subscriber.complete();
        }
      });
    });
    const sobs = obs.pipe(shareReplay());
    sobs.subscribe(() => {
      // TODO: Nothing, we just need to make sure this is subscribed to
    });
    return sobs;
  }
}
