import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import ContextKitDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextKitDtoInterface';
import { FormControl, Validators } from '@angular/forms';
import KitPartPhases from '../KitPartPhases';
import QuoteOptionKitPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteOptionKitPartDtoInterface';
import QuotePartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuotePartDtoInterface';
import QuoteOptionKitDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteOptionKitDtoInterface';
import QuoteOptionEditableInterface, {
  QuoteOptionKitPartEditableInterface,
} from '../../../_services/QuoteEditableService/interfaces/QuoteOptionEditableInterface';
import QuoteOptionDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteOptionDtoInterface';
import {
  PostAddToQuoteOptionInterface,
  QuoteEditableResponseType,
  QuoteEditableService,
} from 'src/app/_services/QuoteEditableService/QuoteEditableService';
import { AddItemsOutputInterface } from '../KitOrPartPickerBotSheetComponent/KitOrPartPickerBotSheetComponent';
import QuoteKitPartEditableInterface from 'src/app/_services/QuoteEditableService/interfaces/QuoteKitPartEditableInterface';
import CustomQuoteKitToAddInterface from 'src/app/_services/sageApi/interfaces/pullReport/CustomQuoteKitToAddInterface';
import CustomQuotePartToAddInterface from 'src/app/_services/sageApi/interfaces/pullReport/CustomQuotePartToAddInterface';

export type QuoteOptionKitPartRowOnSaveType =
  | QuoteOptionKitPartDtoInterface
  | QuotePartDtoInterface
  | ContextKitDtoInterface
  | QuoteOptionKitDtoInterface;

@Component({
  selector: 'app-qoption',
  templateUrl: './QuoteOptionComponent.html',
  styleUrls: ['./QuoteOptionComponent.css', '../QuotingTheme.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteOptionComponent implements OnInit, OnChanges {
  @Input() option: QuoteOptionEditableInterface;

  cost = 0;
  totalParts = 0;

  kitPartPhases = KitPartPhases;

  showCreditedActions = false;

  addSheetOpen = false;
  addBotSheetTarget: {
    parentName: string;
    parentGuid?: string;
    data: QuoteOptionKitPartEditableInterface | null;
  } | null = null;

  customKitSheetOpen = false;
  customKitTarget: QuoteOptionKitPartEditableInterface = null;

  customPartSheetOpen = false;
  customPartTarget: QuoteOptionKitPartEditableInterface = null;

  editPartBotSheetOpen = false;

  @Output()
  save: EventEmitter<QuoteOptionEditableInterface> = new EventEmitter<QuoteOptionEditableInterface>();
  @Output() onDelete: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  openAddSheet: EventEmitter<QuoteOptionKitPartEditableInterface> = new EventEmitter<QuoteOptionKitPartEditableInterface>();
  @Output()
  customKitSheet: EventEmitter<QuoteOptionKitPartEditableInterface> = new EventEmitter<QuoteOptionKitPartEditableInterface>();
  @Output()
  customPartSheet: EventEmitter<QuoteOptionKitPartEditableInterface> = new EventEmitter<QuoteOptionKitPartEditableInterface>();

  constructor(
    public api: SageApiService,
    public qe: QuoteEditableService,
    public screenSize: ScreenSizeService,
    public dialog: MatDialog,
    public cdr: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    document.addEventListener('keydown', e => {
      if (e.key == 'Delete') {
        if (
          this.qe.getSelectedQuoteOptionKitPartChildren({
            quoteOption: this.option,
          }).length > 0
        ) {
          this.qe.destroySelectedQuoteOptionChildren({
            quoteOption: this.option,
          });
        }
      }
      if (e.key == 'Escape') {
        this.qe.deselectQuoteOptionKitPartChildren({
          quoteOption: this.option,
        });
      }
    });

    this.qe.updated.subscribe(() => {
      this.showCreditedActions = this.qe.isQuoteOptionCreditedChanged({
        quoteOption: this.option,
      });
      this.cdr.detectChanges();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetControls();
    this.generateCost();
    this.generateTotalParts();
  }

  resetControls() {
    this.option.nameControl.setValue(this.option.data.QuoteOption_Name);
    this.option.descControl.setValue(this.option.data.QuoteOption_Desc);
  }

  generateCost() {
    // ToDo: Implement this
  }

  generateTotalParts() {
    // ToDo: Implement this
  }

  getTotalParts(children: QuoteOptionKitPartEditableInterface[]) {
    // Loop through data.children, and their children, adding all the quantities of the QuoteParts
    let totalParts = 0;
    for (const qkp of children) {
      if (qkp.data.QuoteOptionPart) {
        totalParts += qkp.data.QuoteOptionKitPart.QuoteOptionKitPart_Quantity;
      }
      if (qkp.data.QuoteOptionKit) {
        totalParts +=
          this.getTotalParts(qkp.children) *
          qkp.data.QuoteOptionKitPart.QuoteOptionKitPart_Quantity;
      }
    }
    return totalParts;
  }

  getTotalCost(children: QuoteOptionKitPartEditableInterface[]) {
    // Loop through data.children, and their children, adding all the costs * the quantities of the QuoteParts
    let totalCost = 0;
    for (const qkp of children) {
      if (qkp.data.QuoteOptionPart) {
        totalCost +=
          qkp.data.QuoteOptionPart.QuotePart.QuotePart_Cost *
          qkp.data.QuoteOptionKitPart.QuoteOptionKitPart_Quantity;
      }
      if (qkp.data.QuoteOptionKit) {
        totalCost +=
          this.getTotalCost(qkp.children) *
          qkp.data.QuoteOptionKitPart.QuoteOptionKitPart_Quantity;
      }
    }
    return totalCost;
  }

  getSubkits() {
    return this.option.children.filter(
      child => child.data.QuoteOptionKit != null
    );
  }

  getParts() {
    return this.option.children.filter(
      child => child.data.QuoteOptionPart != null
    );
  }

  quoteKitNameInput(e: InputEvent) {
    this.option.nameControl.setValue((e.target as HTMLInputElement).value);
  }

  saveOptionChanges() {
    if (
      this.option.data?.QuoteOption_guid != null &&
      (this.option.nameControl.value != this.option.data?.QuoteOption_Name ||
        this.option.descControl.value != this.option.data?.QuoteOption_Desc)
    ) {
      this.api
        .patchRequest(`quoteoption/${this.option.data?.QuoteOption_guid}`, {
          QuoteOption_Name: this.option.nameControl.value,
          QuoteOption_Desc: this.option.descControl.value,
        })
        .subscribe(
          (data: QuoteOptionDtoInterface) => {
            this.option.data = data;
            this.resetControls();
            this.generateCost();
            this.generateTotalParts();
            this.save.emit(this.option);
          },
          error => {
            console.log(error);
            this.snackBar.open('Failed to save changes', 'Close', {
              duration: Infinity,
            });
          }
        );
    }
    this.generateCost();
    this.generateTotalParts();
  }

  cancelOptionChanges() {
    this.resetControls();
  }

  optionNameInput(e: InputEvent) {
    const value = (e.target as HTMLInputElement).value;
    if (value.length > 100) {
      const newVal = value.slice(0, 100);
      this.option.nameControl.setValue(newVal);
      (e.target as HTMLInputElement).value = newVal;
    }
  }

  optionNameBlur() {
    if (this.option.nameControl.value === '') {
      this.option.nameControl.setValue('Untitled');
    }
  }

  isSaveOptionDisabled() {
    const different =
      this.option.nameControl.value !== this.option.data.QuoteOption_Name ||
      this.option.descControl.value !== this.option.data.QuoteOption_Desc;

    const valid =
      this.option.nameControl.valid && this.option.descControl.valid;

    return !different || !valid;
  }

  isCancelOptionDisabled() {
    const different =
      this.option.nameControl.value !== this.option.data.QuoteOption_Name ||
      this.option.descControl.value !== this.option.data.QuoteOption_Desc;
    return !different;
  }

  trackByField<ObjInterface>(field: keyof ObjInterface) {
    return (index: number, item: ObjInterface) => item[field];
  }

  setShowCreditedActions(show: boolean) {
    this.showCreditedActions = show;
  }

  resetSelectedCredited() {
    this.qe.resetQuoteOptionCreditedPartsControl({ quoteOption: this.option });
  }

  saveChangesCredited() {
    this.qe
      .saveQuoteOptionCreditedPartsControl({ quoteOption: this.option })
      .subscribe(() => {
        this.showCreditedActions = false;
      });
  }

  closeAddPartsSheet() {
    this.addSheetOpen = false;
  }

  addToQuoteOption(addedItems: AddItemsOutputInterface) {
    console.log('addedItems');
    console.log(addedItems);
    const kitsAndPartsToAdd: PostAddToQuoteOptionInterface = {
      parts: addedItems.parts.map(part => {
        return {
          Part_guid: part.Part_guid,
          QuoteOptionKitPart_Quantity: part.quantity,
          QuoteOptionKitPart_Phase: part.phase,
          QuotePart_Cost: part.cost,
        };
      }),
      kits: addedItems.kits.map(kit => {
        return {
          Kit_guid: kit.Kit_guid,
          QuoteOptionKitPart_Quantity: kit.quantity,
        };
      }),
      customParts: [],
      customKits: [],
    };
    this.qe.addToQuoteOption({
      quoteOption: this.option,
      kitsAndPartsToAdd,
      parentQuoteOptionKitPart: this.addBotSheetTarget?.data || undefined,
    });
  }

  destroyQuoteOptionKitParts() {
    this.qe.destroyQuoteOptionKitParts({
      quoteOption: this.option,
      quoteOptionKitParts: this.qe.getSelectedQuoteOptionKitPartChildren({
        quoteOption: this.option,
      }),
    });
  }

  closeCustomKitSheet() {
    this.customKitSheetOpen = false;
  }

  closeCustomPartSheet() {
    this.customPartSheetOpen = false;
  }

  openAddBotSheet(e?: QuoteOptionKitPartEditableInterface) {
    if (e == undefined) {
      this.addBotSheetTarget = {
        parentName: this.option.data.QuoteOption_Name,
        data: null,
      };
    } else {
      this.addBotSheetTarget = {
        parentName: e.data?.QuoteOptionKit.QuoteOptionKit.QuoteOptionKit_Name,
        parentGuid: e.data.QuoteOptionKitPart.QuoteOptionKitPart_guid,
        data: e,
      };
    }

    this.addSheetOpen = true;
    this.cdr.detectChanges();
  }

  openCustomKitSheet(e?: QuoteOptionKitPartEditableInterface) {
    this.customKitTarget = e;
    this.customKitSheetOpen = true;
    this.cdr.detectChanges();
  }

  openCustomPartSheet(e?: QuoteOptionKitPartEditableInterface) {
    this.customPartTarget = e;
    this.customPartSheetOpen = true;
    this.cdr.detectChanges();
  }

  openEditSheet(e?: QuoteOptionKitPartEditableInterface) {
    this.editPartBotSheetOpen = true;
    this.cdr.detectChanges();
  }

  addCustomKitToQuoteOption(ckit: CustomQuoteKitToAddInterface) {
    const kitsAndPartsToAdd: PostAddToQuoteOptionInterface = {
      parts: [],
      kits: [],
      customParts: [],
      customKits: [
        {
          QuoteOptionKit_Name: ckit.name,
          QuoteOptionKit_Desc: ckit.desc,
          QuoteOptionKit_Region: ckit.region,
          QuoteOptionKitPart_Quantity: ckit.quantity,
        },
      ],
    };
    this.qe.addToQuoteOption({
      quoteOption: this.option,
      kitsAndPartsToAdd,
      parentQuoteOptionKitPart: this.customKitTarget || undefined,
    });
    this.customKitSheetOpen = false;
  }

  addCustomPartToQuoteOption(cpart: CustomQuotePartToAddInterface) {
    const kitsAndPartsToAdd: PostAddToQuoteOptionInterface = {
      parts: [],
      kits: [],
      customKits: [],
      customParts: [
        {
          QuotePart_Code: cpart.code,
          QuotePart_Desc: cpart.desc,
          QuotePart_Cost: parseInt(`${cpart.cost}`),
          QuoteOptionKitPart_Quantity: cpart.quantity,
          QuoteOptionKitPart_Phase: cpart.phase,
        },
      ],
    };
    this.qe.addToQuoteOption({
      quoteOption: this.option,
      kitsAndPartsToAdd,
      parentQuoteOptionKitPart: this.customPartTarget || undefined,
    });
    this.customPartSheetOpen = false;
  }

  destroyQuoteOption() {
    this.qe
      .destroyQuoteOption({ quoteOption: this.option })
      .subscribe((r: QuoteEditableResponseType) => {
        if (r == 'complete') {
          this.onDelete.emit();
        }
      });
  }

  saveQuoteOptionChanges() {
    this.qe.changeQuoteOption({ quoteOption: this.option }).subscribe(() => {
      this.save.emit(this.option);
    });
  }
}
