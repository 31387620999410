<div class="container pb-5">
  <div class="card my-5 shadow-lg text-center" id="noprint">
    <h1>Material Issue To Truck</h1>

    <ng-container>
      <div class="col-12 py-2">
        <input
          [disabled]="itemList.length > 0"
          class="form-control input"
          #input
          [(ngModel)]="JobNumber"
          (keyup.enter)="getTransferList(JobNumber)"
          placeholder="Scan or Enter Job Number"
          autocomplete="off"
        />
        <button
          class="btn btn-primary m-1"
          (click)="getTransferList(JobNumber)"
          [disabled]="itemList.length > 0"
        >
          <span *ngIf="loading"><i class="fas fa-sync fa-spin"></i></span>
          Get Items
        </button>
        <button class="btn btn-primary m-1" (click)="clear()">Cancel</button>
      </div>
    </ng-container>
    <ng-container>
      <table class="table table-stripped table-hover">
        <thead>
          <th>From Warehouse</th>
          <th>To Truck</th>
          <th>Item Code</th>
          <th>Item Description</th>
          <th>Ordered</th>
          <th>Issued</th>
          <th>On Hand</th>
          <th>In Truck</th>
          <th>Comment</th>
          <th>User</th>
        </thead>
        <tbody>
          <tr
            *ngFor="let item of itemList"
            [ngStyle]="{ 'font-weight': background(item) }"
          >
            <td>{{ item.WarehouseFrom }}</td>
            <td>{{ item.WarehouseTo }}</td>
            <td>{{ item.ItemCode }}</td>
            <td>{{ item.Description }}</td>
            <td>{{ item.Ordered }}</td>
            <td>
              <input
                type="number"
                min="0"
                class="form-control input"
                [(ngModel)]="item.Shipped"
              />
            </td>
            <td>{{ item.OnHand }}</td>
            <td>{{ item.InTruck }}</td>
            <td>{{ item.Comment }}</td>
            <td>{{ item.User }}</td>
          </tr>
        </tbody>
      </table>
    </ng-container>
    <div id="issueButtons" >
<div *ngIf="!confirmIfRestock()" class="actionButtons">
  <button class="btn btn-primary" (click)="issueAll()"
  [disabled]="itemList.length == 0 || sending"
  >Issue All</button>
  <button class="btn btn-primary" (click)="unissueAll()"
  [disabled]="itemList.length == 0 || sending">
  Unissue All
</button>
</div>
      <button
      class="btn btn-primary"
      (click)="transfer()"
      [disabled]="itemList.length == 0 || sending"
      >
      <span *ngIf="sending"><i class="fas fa-sync fa-spin"></i></span>
      <span *ngIf="!sending">Transfer</span>
    </button>
  </div>
  </div>
</div>
