  <h1 mat-dialog-title>Add Attachments</h1>
  <div mat-dialog-content>
    <label for="requestAttachments"> Attachments </label>
    <div class="custom-file">
      <input multiple type="file" class="custom-file-input" id="requestAttachments" (change)="fileChosen($event)">
      <label class="custom-file-label text-left" for="requestAttachments">Choose file</label>
    </div>
    <div class="col" *ngFor="let file of fileArray">
      <small> {{ file.name }}</small>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button color="warn" mat-flat-button (click)="onNoClick()">No Thanks</button>

    <button mat-button color="primary" mat-flat-button (click)="fileUpload()">Upload File</button>
  </div>


<div class="files" *ngIf="!loading">
  <div *ngFor="let file of allFiles" >
    <a [href]="file" target="_blank" class="fileStructure">
    <i class="fas fa-file-alt fa-4x"></i>{{getFileName(file)}}</a>
  </div>


<!-- <label for="requestAttachments"> Attachments </label>
    <div class="custom-file">
      <input multiple type="file" class="custom-file-input" id="requestAttachments" (change)="fileChosen($event)">
      <label class="custom-file-label text-left" for="requestAttachments">Choose file</label>
    </div>
    <div class="col" *ngFor="let file of fileArray">
      <small> {{ file.name }}</small>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button color="warn" mat-flat-button (click)="onNoClick()">No Thanks</button>

    <button mat-button color="primary" mat-flat-button (click)="fileUpload()">Upload File</button> -->
  </div>
