<ng-container>
  <ng-container *ngIf="qkp.data?.QuotePart">
    <mat-card
      [class.hover]="isHovered"
      [class.selected]="qkp.selected.value"
      [class]="
        screenSize.getClasses() + ' quotekprow-card quotekprow-card-part'
      "
    >
      <div class="quotekprow-card-header">
        <div class="quotekprow-card-header-front">
          <mat-checkbox
            *ngIf="qkp.editable"
            [formControl]="qkp.selected"
            (change)="selectedChanged($event.checked)"
            class="quotekprow-card-header-front-cb"
          ></mat-checkbox>
          <div class="quotekprow-card-header-front-title">
            <h3 class="quotekprow-card-header-front-title-txt">
              {{ qkp.data.QuotePart.QuotePart.QuotePart_Code }}
            </h3>
            <h6 class="quotekprow-card-phase">
              {{ qkp.data.QuoteKitPart.QuoteKitPart_Phase }}
            </h6>
          </div>
          <div class="quotekprow-card-header-front-quan">
            <i class="quotekprow-card-header-front-quan-icon">&times;</i>
            <app-grow-input
              *ngIf="qkp.editable"
              [control]="qkp.quantityControl"
              (inputEmitter)="quantityControlInput($event)"
              [useNativeInput]="true"
              minWidth="unset"
              maxWidth="unset"
              class="quotekprow-card-header-front-quan-in"
            ></app-grow-input>
            <span
              *ngIf="!qkp.editable"
              class="quotekprow-card-header-front-quan-txt"
              >{{ qkp.quantityControl.value }}</span
            >
            <div
              *ngIf="
                qkp.quantityControl.value !=
                  qkp.data.QuoteKitPart.QuoteKitPart_Quantity ||
                qkp.phaseControl.value !=
                  qkp.data.QuoteKitPart.QuoteKitPart_Phase ||
                qkp.costControl.value !=
                  qkp.data.QuotePart.QuotePart.QuotePart_Cost ||
                qkp.buildLocationControl.value !=
                  (qkp.data?.BuildLocation?.BuildLocation_Code || '') ||
                tagsControlsChanged()
              "
              class="quotekprow-card-header-front-quan-controls"
            >
              <button
                [disabled]="
                  qkp.quantityControl.value == '' ||
                  qkp.quantityControl.valid == false ||
                  qkp.phaseControl.value === '' ||
                  qkp.costControl.value === ''
                "
                (click)="saveChanges()"
                color="primary"
                mat-flat-button
                class="quotekprow-card-header-front-quan-controls-item quotekprow-card-header-front-quan-controls-save"
              >
                Save
              </button>
              <button
                (click)="cancelChanges()"
                color="warn"
                mat-flat-button
                class="quotekprow-card-header-front-quan-controls-item quotekprow-card-header-front-quan-controls-cancel"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        <div class="quotekprow-card-header-end">
          <div
            [class.cost-hovered]="isCostHovered"
            class="quotekprow-card-header-end-content"
          >
            <h4
              [class.hidden]="costControlVisible"
              class="quotekprow-card-header-end-content-cost"
            >
              <span class="quotekprow-card-header-end-content-cost-icon">
                $
              </span>
              {{ cost.toFixed(2) }}
            </h4>
            <div
              [class.focused]="
                costControlVisible ||
                qkp.costControl.value !=
                  qkp.data.QuotePart.QuotePart.QuotePart_Cost
              "
              class="quotekprow-card-header-end-content-explanation"
            >
              <h4 class="quotekprow-card-header-end-content-explanation-cost">
                <span class="quotekprow-card-header-end-content-cost-icon"
                  >${{ ' ' }}</span
                >
                <!-- {{ quoteKitPart.QuotePart.QuotePart.QuotePart_Cost.toFixed(2) }} -->
                <app-grow-input
                  [control]="qkp.costControl"
                  (inputEmitter)="costControlInput($event)"
                  (blurEmitter)="costControlBlur($event)"
                  (focusEmitter)="costControlVisible = true"
                  [useNativeInput]="true"
                  minWidth="25px"
                  maxWidth="100px"
                  class="quotekprow-card-header-front-quan-in"
                ></app-grow-input>
                <span class="quotekprow-card-header-end-content-cost-per">
                  /part
                </span>
              </h4>
            </div>
          </div>
        </div>
      </div>
      <span *ngIf="cost == 0" class="quotekprow-card-noprice"
        >No price associated with this part!</span
      >
      <div
        [class.editable]="qkp.editable"
        class="quotekprow-card-content active"
      >
        <div class="quotekprow-card-content-tags">
          <span
            (click)="isLocationPickerOpen = !isLocationPickerOpen"
            type="button"
            cdkOverlayOrigin
            #buildLocationPill="cdkOverlayOrigin"
            [matTooltip]="
              qe.getBuildLocation({
                buildLocationCode: qkp?.buildLocationControl.value
              })?.BuildLocation_Desc
            "
            [style.background]="
              getTagColor(
                qkp?.buildLocationControl.value != 'NONE'
                  ? qkp?.buildLocationControl.value
                  : ''
              )
            "
            [style.color]="
              qkp?.buildLocationControl.value == 'NONE' ? '#000' : '#fff'
            "
            class="quotekprow-card-location-item"
          >
            <ng-container *ngIf="qkp?.buildLocationControl.value == 'NONE'">
              <i class="fa fa-plus"></i>
            </ng-container>
            <ng-container *ngIf="qkp?.buildLocationControl.value != 'NONE'">
              {{ qkp?.buildLocationControl.value }}
            </ng-container>

            <ng-template
              cdkConnectedOverlay
              [cdkConnectedOverlayOrigin]="buildLocationPill"
              [cdkConnectedOverlayOpen]="isLocationPickerOpen"
            >
              <app-searchable-sel
                placeholder="Build Location"
                [options]="getBuildLocations()"
                [required]="true"
                [defaultSelected]="qkp?.buildLocationControl.value"
                (onSelectChange)="buildLocationControlChange($event)"
                class="quotekprow-card-location-item-sel"
              ></app-searchable-sel>
            </ng-template>
          </span>

          <span *ngIf="qkp.data.QuotePart.QuotePartTags.length != 0">{{
            ' | '
          }}</span>

          <span
            *ngFor="let tag of qkp.tagsControl.controls"
            (click)="removeTag(tag.value)"
            [matTooltip]="qe.getTag({ tagName: tag.value }).Tag_Desc"
            [style.background]="getTagColor(tag.value)"
            class="quotekprow-card-content-tags-item"
          >
            {{ tag.value }}
          </span>
          <span
            *ngIf="getAvailableTags().length > 0"
            (click)="tagPickerOpen = !tagPickerOpen"
            cdkOverlayOrigin
            #tagPill="cdkOverlayOrigin"
            matTooltip="Add a tag"
            style.background="#fff"
            [style.color]="'#000'"
            class="quotekprow-card-content-tags-item"
          >
            <i class="fa fa-plus"></i>
            <ng-template
              cdkConnectedOverlay
              [cdkConnectedOverlayOrigin]="tagPill"
              [cdkConnectedOverlayOpen]="tagPickerOpen"
            >
              <app-searchable-sel
                *ngIf="qkp.data.QuotePart != undefined"
                (onSelectChange)="tagPickerControlChange($event)"
                placeholder="Tags"
                [options]="getAvailableTags()"
                [required]="true"
                class="quotekprow-card-location-item-sel"
              ></app-searchable-sel>
            </ng-template>
          </span>
        </div>
        <div class="quotekprow-card-content-desc">
          {{ qkp.data.QuotePart.QuotePart.QuotePart_Desc }}
        </div>
      </div>
    </mat-card>
  </ng-container>

  <ng-container *ngIf="qkp.data?.QuoteKit">
    <mat-card
      [class.hover]="isHovered"
      [class.selected]="qkp.selected.value"
      [class.expanded]="qkp.expanded"
      [class]="screenSize.getClasses() + ' quotekprow-card quotekprow-card-kit'"
    >
      <ng-container *ngIf="qkp.expanded">
        <div class="quotekprow-card-follower">
          <h4 class="quotekprow-card-follower-title">
            {{ qkp.nameControl.value }}
          </h4>
        </div>
        <div class="quotekprow-card-followadd">
          <button
            long-press
            (onLongPress)="menuTrigger.openMenu()"
            (onLongPressMiss)="openAddSheet.emit(qkp)"
            mat-fab
            class="quotekprow-card-followadd-btn"
          >
            <i class="fas fa-plus"></i>
            <button
              mat-icon-button
              #menuTrigger="matMenuTrigger"
              [matMenuTriggerFor]="menu"
              [style]="{ width: '0px', height: '0px' }"
            ></button>
          </button>
          <mat-menu #menu="matMenu">
            <button (click)="openAddSheet.emit(qkp)" mat-menu-item>
              Add parts / kits
            </button>
            <button (click)="customKitSheet.emit(qkp)" mat-menu-item>
              Add a custom kit
            </button>
            <button (click)="customPartSheet.emit(qkp)" mat-menu-item>
              Add a custom part
            </button>
          </mat-menu>
        </div>
      </ng-container>
      <div class="quotekprow-card-header quotekprow-card-kitheader">
        <div class="quotekprow-card-header-front">
          <mat-checkbox
            *ngIf="qkp.editable"
            [formControl]="qkp.selected"
            (change)="selectedChanged($event.checked)"
            class="quotekprow-card-header-front-cb"
          ></mat-checkbox>
          <h3 class="quotekprow-card-header-front-title">
            <app-grow-input
              *ngIf="qkp.editable"
              [control]="qkp.nameControl"
              [useNativeInput]="true"
              minWidth="25px"
              maxWidth="50vw"
              class="quotekprow-card-header-front-title-in"
            ></app-grow-input>
          </h3>
          <div class="quotekprow-card-header-front-quan">
            <i class="quotekprow-card-header-front-quan-icon">&times;</i>
            <app-grow-input
              *ngIf="qkp.editable"
              [control]="qkp.quantityControl"
              (inputEmitter)="quantityControlInput($event)"
              (keydownEmitter)="$event.stopPropagation()"
              [useNativeInput]="true"
              minWidth="25px"
              maxWidth="100px"
              class="quotekprow-card-header-front-quan-in"
            ></app-grow-input>
            <span
              *ngIf="!qkp.editable"
              class="quotekprow-card-header-front-quan-txt"
              >{{ qkp.quantityControl.value }}</span
            >
            <div
              *ngIf="
                qkp.quantityControl.value !=
                  qkp.data.QuoteKitPart.QuoteKitPart_Quantity ||
                qkp.nameControl.value !=
                  qkp.data.QuoteKit.QuoteKit.QuoteKit_Name ||
                qkp.buildLocationControl.value !=
                  (qkp.data?.BuildLocation?.BuildLocation_Code || '')
              "
              class="quotekprow-card-header-front-quan-controls"
            >
              <button
                [disabled]="
                  qkp.quantityControl.value == '' ||
                  qkp.quantityControl.valid == false
                "
                (click)="saveChanges()"
                color="primary"
                mat-flat-button
                class="quotekprow-card-header-front-quan-controls-item quotekprow-card-header-front-quan-controls-save"
              >
                Save
              </button>
              <button
                (click)="cancelChanges()"
                color="warn"
                mat-flat-button
                class="quotekprow-card-header-front-quan-controls-item quotekprow-card-header-front-quan-controls-cancel"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        <div class="quotekprow-card-header-end">
          <div class="quotekprow-card-header-end-content">
            <h5 class="quotekprow-card-header-end-content-parts">
              {{ totalParts * qkp.data.QuoteKitPart.QuoteKitPart_Quantity }}
              parts
            </h5>
            <h4 class="quotekprow-card-header-end-content-cost">
              <span class="quotekprow-card-header-end-content-cost-icon">
                $
              </span>
              {{ cost.toFixed(2) }}
            </h4>
            <div class="quotekprow-card-header-end-content-explanation">
              <h5 class="quotekprow-card-header-end-content-explanation-parts">
                {{ totalParts }} parts/kit
              </h5>
              <h4 class="quotekprow-card-header-end-content-explanation-cost">
                <span class="quotekprow-card-header-end-content-cost-icon">
                  $
                </span>
                {{
                  (cost / qkp.data.QuoteKitPart.QuoteKitPart_Quantity).toFixed(
                    2
                  )
                }}
                <span class="quotekprow-card-header-end-content-cost-per">
                  /kit
                </span>
              </h4>
            </div>
          </div>
          <button
            (click)="qkp.expanded = !qkp.expanded"
            mat-mini-fab
            class="quotekprow-card-header-end-drop mat-elevation-z2"
          >
            <i
              [class.active]="qkp.expanded"
              class="quotekprow-card-header-end-drop-icon fas fa-chevron-down"
            ></i>
          </button>
        </div>
      </div>
      <div class="quotekprow-card-location quotekprow-card-kitlocation">
        <span
          (click)="isLocationPickerOpen = !isLocationPickerOpen"
          type="button"
          cdkOverlayOrigin
          #buildLocationPill="cdkOverlayOrigin"
          [matTooltip]="
            qe.getBuildLocation({
              buildLocationCode: qkp?.buildLocationControl.value
            })?.BuildLocation_Desc
          "
          [style.background]="
            getTagColor(
              qkp?.buildLocationControl.value != 'NONE'
                ? qkp?.buildLocationControl.value
                : ''
            )
          "
          [style.color]="
            qkp?.buildLocationControl.value == 'NONE' ? '#000' : '#fff'
          "
          class="quotekprow-card-location-item"
        >
          <ng-container *ngIf="qkp?.buildLocationControl.value == 'NONE'">
            <i class="fa fa-plus"></i>
          </ng-container>
          <ng-container *ngIf="qkp?.buildLocationControl.value != 'NONE'">
            {{ qkp?.buildLocationControl.value }}
          </ng-container>

          <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="buildLocationPill"
            [cdkConnectedOverlayOpen]="isLocationPickerOpen"
          >
            <app-searchable-sel
              placeholder="Build Location"
              [options]="getBuildLocations()"
              [required]="true"
              [defaultSelected]="qkp?.buildLocationControl.value"
              (onSelectChange)="buildLocationControlChange($event)"
              class="quotekprow-card-location-item-sel"
            ></app-searchable-sel>
          </ng-template>
        </span>
      </div>
      <div [class.active]="qkp.expanded" class="quotekprow-card-content">
        <div class="quotekprow-card-content-add">
          <button
            long-press
            (onLongPress)="menuTrigger.openMenu()"
            (onLongPressMiss)="openAddSheet.emit(qkp)"
            mat-fab
            class="quotekprow-card-content-add-btn"
          >
            <i class="fas fa-plus"></i>
            <button
              mat-icon-button
              #menuTrigger="matMenuTrigger"
              [matMenuTriggerFor]="menu"
              [style]="{ width: '0px', height: '0px' }"
            ></button>
          </button>
          <mat-menu #menu="matMenu">
            <button (click)="openAddSheet.emit(qkp)" mat-menu-item>
              Add parts / kits
            </button>
            <button (click)="customKitSheet.emit(qkp)" mat-menu-item>
              Add a custom kit
            </button>
            <button (click)="customPartSheet.emit(qkp)" mat-menu-item>
              Add a custom part
            </button>
          </mat-menu>
        </div>
        <div class="quotekprow-card-content-subkit">
          <ng-container *ngIf="qkp.expanded || qkp.children.length > 30">
            <ng-container *ngFor="let row of getParts()">
              <app-quotekprow
                (save)="generateCost(); save.emit($event)"
                (openAddSheet)="openAddSheet.emit($event)"
                (customKitSheet)="customKitSheet.emit($event)"
                (customPartSheet)="customPartSheet.emit($event)"
                [qkp]="row"
              ></app-quotekprow>
            </ng-container>
            <ng-container *ngFor="let row of getSubkits()">
              <app-quotekprow
                (save)="generateCost(); save.emit($event)"
                (openAddSheet)="openAddSheet.emit($event)"
                (customKitSheet)="customKitSheet.emit($event)"
                (customPartSheet)="customPartSheet.emit($event)"
                [qkp]="row"
              ></app-quotekprow>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </mat-card>
  </ng-container>
</ng-container>
