import { Component, Inject, OnInit } from '@angular/core';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import {
  SalesOrder,
  SalesOrderStatus,
} from 'src/app/_services/sageApi/interfaces/pullReport/SalesOrderLookup';
import { AlertifyService } from 'src/app/_services/alertify/alertify.service';
import { WasabiApiService } from 'src/app/_services/wasabiApi/wasabiApi.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { AuthService } from 'src/app/_services/auth/auth.service';
export interface DialogData {
  wasabiList: [];
  nativeFileList: [];
  address: '';
}

@Component({
  selector: 'app-sales-order-lookup',
  templateUrl: './sales-order-lookup.component.html',
  styleUrls: ['./sales-order-lookup.component.css'],
})
export class SalesOrderLookupComponent implements OnInit {
  houseInformation = [];
  salesOrderNo = '';
  houseInformationLoading: boolean;
  addressLoading: boolean;
  addressList = [];
  addressName = '';
  houseStatus = [];
  houseStatusLoading: boolean;
  WasabiList = [];
  fileList = [];
  customerInformation = {
    Customer: '',
    GarageLocation: '',
    PlanNumber: '',
    Type: '',
    Warehouse: '',
    EnergyType: '',
    Super: '',
    Subdivision: '',
    Address: '',
  };

  selectedPhase = {
    WTNumber: '',
    SalesOrderNo: '',
    ParentItemCode: '',
    Username: '',
  };
  phases = [];

  loadingPDF: boolean;
  constructor(
    private sageApi: SageApiService,
    private alertify: AlertifyService,
    private wasabiApi: WasabiApiService,
    private dialog: MatDialog,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.loadAddressList();
  }

  pullInformation() {
    this.houseInformation = [];
    this.houseInformationLoading = true;
    this.sageApi
      .pullReport('SalesOrderLookup?salesorder=' + this.salesOrderNo)
      .subscribe((rows: Array<SalesOrder>) => {
        rows.forEach(row => {
          if (this.houseInformation.length === 0) {
            this.clearcustomer();

            this.customerInformation = {
              Customer: row.Customer,
              GarageLocation: row.GarageLocation,
              PlanNumber: row.PlanNumber,
              Type: row.Type,
              Warehouse: row.WarehouseCode,
              Super: row.SuperIntendent,
              EnergyType: row.EnergyType,
              Subdivision: row.Subdivision,
              Address: row.Address,
            };
          }

          this.houseInformation.push(row);
        });
        //this.loadImagesForViewing();
        //this.fileNames();
        this.houseInformationLoading = false;
      });
  }

  normalizeMoney(val): string {
    return `$${parseFloat(val.UnitPrice).toFixed(2)}`;
  }

  pullStatusInformation() {
    this.selectedPhase = {
      WTNumber: '',
      SalesOrderNo: '',
      ParentItemCode: '',
      Username: '',
    };
    this.phases = [];
    this.houseStatus = [];
    this.houseStatusLoading = true;
    this.sageApi
      .pullReport('SalesOrderLookup/Status?salesorder=' + this.salesOrderNo)
      .subscribe((rows: Array<SalesOrderStatus>) => {
        rows.forEach(row => {
          const obj = {
            WTNumber: row.WTNumber,
            ParentItemCode: row.HdrParentItemCode,
            SalesOrderNo: row.SalesOrderNo,
          };
          this.phases.push(obj);
          this.houseStatus.push(row);
        });
        this.houseStatusLoading = false;
      });
  }
  clearcustomer() {
    this.customerInformation = {
      Customer: '',
      GarageLocation: '',
      PlanNumber: '',
      Type: '',
      Warehouse: '',
      Super: '',
      EnergyType: '',
      Subdivision: '',
      Address: '',
    };
  }
  onPhaseSelected() {
    //print pullsheet
    this.selectedPhase.Username = this.authService.decodedToken.nameid;
    console.log(this.selectedPhase);

    // this.loadingPDF = true;
    // console.log(this.loadingPDF);

    this.sageApi
      .postBlob('SalesOrderLookup/PullSheet', this.selectedPhase)
      .subscribe(
        response => {
          const blob = new Blob([response], { type: 'application/pdf' });
          const blobURL = URL.createObjectURL(blob);

          const iframe = document.createElement('iframe');
          document.body.appendChild(iframe);

          iframe.style.display = 'none';
          iframe.src = blobURL;
          iframe.onload = function () {
            setTimeout(function () {
              iframe.focus();
              iframe.contentWindow.print();
            }, 1);
          };
        },
        e => {
          console.log(e);
          this.loadingPDF = false;
          console.log(this.loadingPDF);
          this.alertify.error(e.message);
          throw Error(e);
        },
        () => {
          this.loadingPDF = false;
        }
      );
  }

  loadAddressList() {
    this.addressList = [];
    this.addressLoading = true;
    this.sageApi.pullReport('HouseEditor/Addresses').subscribe(
      (rows: Array<any>) => {
        if (Array.isArray(rows)) {
          rows.forEach(row => {
            const address = {
              name: row.Address + ' - ' + row.SalesOrderNo,
              address: row.Address,
              salesOrderNo: row.SalesOrderNo,
            };
            this.addressList.push(address);
          });
          this.addressLoading = false;
        }
      },
      err => {
        this.alertify.error(err.message);
        console.log(err);
      }
    );
  }

  addressSelected(event: TypeaheadMatch) {
    this.salesOrderNo = '';
    this.salesOrderNo = event.item.salesOrderNo;
    this.addressName = event.item.address;
    this.pullInformation();
    this.pullStatusInformation();
  }

  openDialog() {
    const dialogRef = this.dialog.open(SalesOrderDialogComponent, {
      data: {
        wasabiList: this.WasabiList,
        nativeFileList: this.fileList,
        address: this.addressName,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Closed');
    });

    // this.modalTemplate = this.modalService.show(template);
  }

  loadImagesForViewing() {
    this.houseInformationLoading = true;
    this.onGetTicketImages(this.addressName).then(() => {
      this.houseInformationLoading = false;
    });
  }

  async onGetTicketImages(address: string) {
    const response = await this.wasabiApi.getImages(address);
    try {
      this.WasabiList = response;
    } catch (error) {
      this.alertify.error(
        'There was an error loading the images for this house - please try again'
      );
      console.warn(`Error loading images: ${error}`);
    }
  }

  fileNames() {
    const route = `HouseEditor/file?address=${this.addressName}`;
    this.fileList = [];
    this.sageApi.pullReport(route).subscribe(
      (response: any) => {
        const splitFiles = response.split(', \\\\gp-nas2\\SO_WT_Files');
        splitFiles.forEach(file => {
          this.fileList.push({
            name: file.split(this.addressName + '\\')[1],
          });
        });
      },
      err => {
        console.log(err);
        this.alertify.error(err.message);
      }
    );
  }
}
@Component({
  selector: 'app-sales-order-wasabi',
  templateUrl: './sales-order-wasabi.html',
  styleUrls: ['./sales-order-lookup.component.css'],
})
export class SalesOrderDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<SalesOrderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private sageApi: SageApiService
  ) {}

  getFileName(file: string) {
    if (
      file.includes(
        'https://s3.wasabisys.com/gibson-qa/uploads/' + this.data.address
      )
    ) {
      let name = file.replace(
        'https://s3.wasabisys.com/gibson-qa/uploads/' + this.data.address + '/',
        ''
      );
      name = name.replace('%20', ' ');
      return name;
    } else {
      return file;
    }
  }
  openFileExplorer() {
    this.sageApi
      .pullReport('SalesOrderLookup/FileExplorer?filePath=' + this.data.address)
      .subscribe(
        result => {
          console.log(result);
        },
        err => {
          console.log(err);
        },
        () => {
          console.log(this.data.address);
        }
      );
  }

  houseFolder() {
    const newAddr = 'search-ms:crumb=&crumb=location://gp-nas2/SO_WT_Files/';
    const address = 'file://gp-nas2/SO_WT_Files/' + this.data.address;
    return address;
  }
}
