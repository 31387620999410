import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { concat } from 'rxjs';
import { QuoteEditableService } from 'src/app/_services/QuoteEditableService/QuoteEditableService';
import {
  postBidInterface,
  QuoteBidInterface,
} from 'src/app/_services/sageApi/interfaces/pullReport/QuoteBid';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';

@Component({
  selector: 'app-bid-create-association-component',
  templateUrl: './BidCreateAssociationComponent.html',
  styleUrls: ['./BidCreateAssociationComponent.css'],
})
export class BidCreateAssociationComponent implements OnInit, OnChanges {
  quotesToAddToBid: QuoteBidInterface[] = [];
  searchKeyword = '';

  quotesSearched: QuoteBidInterface[] = [];
  constructor(
    public screenSize: ScreenSizeService,
    private sageApi: SageApiService,
    public qe: QuoteEditableService
  ) {}
  @Input() openBid = false;
  @Input() guid: string;
  @Input() availablebids: QuoteBidInterface[] = [];
  @Output() sheetClosed = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.resetPage();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.resetPage();
  }

  resetPage() {
    this.availablebids.forEach(element => {
      if (element.checked == true) {
        element.checked = false;
      }
    });
  }
  isSaveDisabled() {
    const avail = this.availablebids.filter(x => x.checked == true);
    if (avail.length == 0) {
      return true;
    } else {
      return false;
    }
  }
  saveChanges() {
    const toSend = [];
    this.availablebids.forEach(element => {
      if (element.checked == true) {
        toSend.push(element.Guid);
      }
    });
    const postBid: postBidInterface = {
      ParentQuote_id: this.guid,
      ChildQuote_ids: toSend,
    };
    const option = { quoteBid: postBid, showErrors: true };
    this.qe.addQuoteBid(option);
    this.sheetClosed.emit(true);
  }
  resetControls() {
    this.resetPage();
    this.sheetClosed.emit(false);
  }

  searchBids() {
    this.quotesSearched = this.availablebids;
    if (this.searchKeyword != '') {
      this.quotesSearched = this.quotesSearched.filter(x =>
        x.QuoteName.toUpperCase().includes(this.searchKeyword.toUpperCase())
      );
    }
    return this.quotesSearched;
  }
}
