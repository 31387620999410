<div [class]="screenSize.getClasses() + ' quoting-theme'">
  <ng-container *ngIf="isLoading==true">
    <div class="kitview-loading">
      <mat-spinner
        [diameter]="80"
        class="kitview-loading-spinner"
      ></mat-spinner>
    </div>
  </ng-container>

  <app-koqappbar
    *ngIf="kit != null"
    [title]="kit.Kit.Kit_Name"
    [saving]="koqappbarSaving"
    [region]="kit.Kit.Kit_Region"
    [description]="kit.Kit.Kit_Desc"
    [partTotal]="totalParts"
    [costTotal]="totalCost"
    (onChange)="kitChange($event)"
    (onUpdateParts)="updateKitParts()"
    (onTotalHover)="sidepanelOpen=true"
  >
  </app-koqappbar>
  <app-kit-sidepanel
    *ngIf="kit != null"
    [open]="sidepanelOpen"
    [title]="kit.Kit.Kit_Name"
    [saving]="koqappbarSaving"
    [region]="kit.Kit.Kit_Region"
    [description]="kit.Kit.Kit_Desc"
    [partTotal]="totalParts"
    [costTotal]="totalCost"
    [buildLocation]="kit?.BuildLocation?.BuildLocation_Code"
    (onChange)="kitChange($event)"
    (onUpdateParts)="updateKitParts()"
    (openChange)="sidepanelOpen=$event"
  ></app-kit-sidepanel>

  <mat-progress-bar *ngIf="addPartsLoading" mode="indeterminate" class="kitview-progress"></mat-progress-bar>

  <ng-container *ngIf="isLoading!=true && kit != null">
    <div class="kitview-add">
      <button (click)="openAddPartsSheet()" mat-fab class="kitview-add-button">
        <i class="fas fa-plus"></i>
      </button>
    </div>
    <div class="kitview-list">
      <ng-container *ngFor="let sub of subKits; let skIndex = index">
        <app-kitpartrow
          [kitPart]="sub.data"
          [selected]="sub.selected"
          (selectedChange)="sub.selected = $event"
          (save)="totalParts=getKitTotalParts(kit); totalCost=getKitCost(kit)"
          [editable]="true"
          [subEditable]="false"
        ></app-kitpartrow>
      </ng-container>
      <ng-container *ngFor="let part of parts; let pIndex = index">
        <app-kitpartrow
          [kitPart]="part.data"
          [selected]="part.selected"
          (selectedChange)="part.selected = $event"
          (save)="totalParts=getKitTotalParts(kit); totalCost=getKitCost(kit)"
          [editable]="true"
          [subEditable]="false"
        ></app-kitpartrow>
      </ng-container>
    </div>

    <app-korp-picker-botsheet
      [open]="addPartsSheetOpen"
      [parent]="kit?{parentName: kit?.Kit?.Kit_Name||'', parentGuid: kit?.Kit?.Kit_guid||''}:{parentName: ''}"
      (sheetClosed)="closeAddPartsSheet()"
      (addItems)="addToKit($event); closeAddPartsSheet();"
      class="quoteview-add"
    >
    </app-korp-picker-botsheet>
  </ng-container>

  <div
    *ngIf="getSelectedKitParts().length > 0"
    class="kitview-kitpart-controls"
  >
    <button
      (click)="destroySelectedKitParts()"
      color="warn"
      mat-flat-button
      class="kitview-kitpart-controls-delete"
    >
      Delete
    </button>
    <button
      [disabled]="getSelectedKitParts().length > 1"
      (click)="openEditPartBotSheet()"
      color="primary"
      mat-flat-button
      class="kitview-kitpart-controls-delete"
    >
      Edit
    </button>
  </div>
  
  <app-bottom-sheet
    *ngIf="getSelectedKitParts().length == 1 && getSelectedKitParts()[0].data.Part != null"
    [open]="editPartBotSheetOpen"
    (sheetClosed)="editPartBotSheetOpen=false"
    [sheetWidth]="screenSize.getClassesArray().includes('smd')? '100vw' : '75vw'"
    sheetBackground="#fafafa"
    class="kitview-editpart"
  >
    <div
      class="kitview-editpart-header"
    >
      <h3 class="kitview-editpart-title">
        Edit: {{ getSelectedKitParts()[0].data.Part.Part.Part_Code }}
      </h3>
      <div class="kitview-editpart-header-controls">
        <mat-form-field
          appearance="outline"
          class="kitview-editpart-header-controls-ff kitview-editpart-header-controls-quantity"
        >
          <mat-label>Quantity</mat-label>
          <input
            [formControl]="editPartQuantityControl"
            [disabled]="editPartLoading"
            matInput
            type="text"
            class="kitview-editpart-header-controls-ff-in"
          />
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          class="kitview-editpart-header-controls-ff kitview-editpart-header-controls-phase"
        >
          <mat-label>Phase</mat-label>
          <mat-select
            [formControl]="editPartPhaseControl"
            class="kitpartrow-card-header-front-phase-ff-sel"
          >
            <mat-option
              *ngFor="let phase of kitPartPhases"
              [value]="phase.valueOf()"
              >{{ phase.toString() }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <p class="kitview-editpart-desc">
      {{ getSelectedKitParts()[0].data.Part.Part.Part_Desc }}
    </p>
    <div class="kitview-editpart-actions">
      <button
        (click)="editPartBotSheetOpen=false"
        color="warn"
        mat-flat-button
        class="kitview-editpart-actions-cancel"
      >
        Cancel
      </button>
      <button
        [disabled]="editPartBotSheetSaveDisabled()"
        (click)="saveKitPartChanges()"
        color="primary"
        mat-flat-button
        class="kitview-editpart-actions-cancel"
      >
        Save
      </button>
    </div>
  </app-bottom-sheet>
  <app-bottom-sheet
    *ngIf="getSelectedKitParts().length == 1 && getSelectedKitParts()[0].data.Kit != undefined"
    [open]="editPartBotSheetOpen"
    (sheetClosed)="editPartBotSheetOpen=false"
    [sheetWidth]="screenSize.getClassesArray().includes('smd')? '100vw' : '75vw'"
    sheetBackground="#fafafa"
    class="kitview-editpart"
  >
    <div
      class="kitview-editpart-header"
    >
      <h3 class="kitview-editpart-title">
        Edit: {{ getSelectedKitParts()[0].data.Kit.Kit.Kit_Name }}
      </h3>
      <div class="kitview-editpart-header-controls">
        <mat-form-field
          appearance="outline"
          class="kitview-editpart-header-controls-ff kitview-editpart-header-controls-quantity"
        >
          <mat-label>Quantity</mat-label>
          <input
            [formControl]="editPartQuantityControl"
            [disabled]="editPartLoading"
            matInput
            type="text"
            class="kitview-editpart-header-controls-ff-in"
            digitOnly
          />
        </mat-form-field>
      </div>
    </div>
    <p class="kitview-editpart-desc">
      {{ getSelectedKitParts()[0].data.Kit.Kit.Kit_Desc }}
    </p>
    <div class="kitview-editpart-actions">
      <button
        (click)="editPartBotSheetOpen=false"
        color="warn"
        mat-flat-button
        class="kitview-editpart-actions-cancel"
      >
        Cancel
      </button>
      <button
        [disabled]="editPartBotSheetSaveDisabled()"
        (click)="saveKitPartChanges()"
        color="primary"
        mat-flat-button
        class="kitview-editpart-actions-cancel"
      >
        Save
      </button>
    </div>
  </app-bottom-sheet>
</div>
