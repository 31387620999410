<app-sidebar
  *ngIf="quote != null"
  [open]="open"
  (sidebarClosed)="openChangeHandler($event)"
  sidebarDirection="right"
>
  <div class="quoteside">
    <h2 class="quoteside-title">{{ quote.Quote_Name }}</h2>

    <hr />
    <h4 class="quoteside-parts">Total Items: <span class="quoteside-parts-val">{{ itemTotal }}</span></h4>
    <h4 class="quoteside-cost">
      Total Cost:  <span class="quoteside-parts-val"><span class="quoteside-cost-val-icon">$</span> {{ costTotal.toFixed(2) }}</span>
    </h4>

    <hr />
    <h4 class="quoteside-parts">Part Items: <span class="quoteside-parts-val">{{ partTotal }}</span></h4>
    <h4 class="quoteside-cost">
      Mat Cost:  <span class="quoteside-parts-val"><span class="quoteside-cost-val-icon">$</span> {{ partCost.toFixed(2) }}</span>
    </h4>

    <hr />
    <h4 class="quoteside-parts">Labor Items: <span class="quoteside-parts-val">{{ laborTotal }}</span></h4>
    <h4 class="quoteside-cost">
      Labor Cost:  <span class="quoteside-parts-val"><span class="quoteside-cost-val-icon">$</span> {{ laborCost.toFixed(2) }}</span>
    </h4>

    <hr />

    <span class="quoteside-cost-disclaimer">
      Total Cost calculated to the nearest penny, but the true cost of parts often contain a percentage of a penny which is reflected here.
    </span>

    <!-- <div class="quoteside-update">
      <button
        (click)="updatePrices()"
        mat-mini-fab
        color="primary"
        class="quoteside-update-btn"
      >
        <i class="fa fa-redo quoteside-update-btn-icon"></i>
      </button>
      <h5 class="quoteside-update-title">Update Part Prices</h5>
    </div> -->

    <mat-form-field
      appearance="outline"
      class="quoteside-status-ff quoteside-ff"
    >
      <mat-label>Status</mat-label>
      <mat-select
        [disabled]="saving"
        [formControl]="statusControl"
        class="quoteside-status-ff-sel quoteside-sel"
      >
        <mat-option *ngFor="let status of quoteStatuses" [value]="status.valueOf()">
          {{ status }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="quoteside-ff">
      <mat-label>Region</mat-label>
      <mat-select
        [formControl]="regionControl"
        [disabled]="saving"
      >
        <mat-option *ngFor="let reg of regions" [value]="reg.valueOf()">{{reg}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      class="quoteside-desc-ff quoteside-ff"
    >
      <mat-label>Builder</mat-label>
      <input
        [disabled]="saving"
        [formControl]="builderControl"
        matInput
        type="text"
        class="quoteside-desc-ff-in"
      />
    </mat-form-field>

    <mat-form-field appearance="outline" class="quoteside-ff">
      <mat-label>Sq. Footage</mat-label>
      <input
        [disabled]="saving"
        [formControl]="squareFootageControl"
        (input)="squareFootageInput($event)"
        matInput
        type="text"
        class="quotesview-create-ff-in"
      />
    </mat-form-field>

    <mat-form-field appearance="outline" class="quoteside-exp-ff quoteside-ff">
      <mat-label>Expiration</mat-label>
      <input
        [disabled]="saving"
        [formControl]="expirationControl"
        [matDatepicker]="datePicker"
        [min]="minExpiration"
        [disabled]="saving"
        matInput
        class="quoteside-exp-ff-in"
      />
      <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
      <mat-datepicker #datePicker [disabled]="saving"></mat-datepicker>
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      class="quoteside-desc-ff quoteside-ff"
    >
      <mat-label>Description</mat-label>
      <textarea
        [disabled]="saving"
        [formControl]="descControl"
        matInput
        type="text"
        class="quoteside-desc-ff-in"
      ></textarea>
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      class="quoteside-matmar-ff quoteside-ff quoteside-percent-ff"
    >
      <mat-label>Material Margin</mat-label>
      <input
        [disabled]="saving"
        [formControl]="materialMarginControl"
        (input)="materialInput($event)"
        matInput
        type="text"
        class="quoteside-matmar-ff-in quoteside-margin-ff-in"
      />
      <mat-icon matSuffix class="">
        <span class="material-symbols-outlined quoteside-ff-icon">
          percent
        </span>
      </mat-icon>
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      class="quoteside-labmar-ff quoteside-ff quoteside-percent-ff"
    >
      <mat-label>Labor Margin</mat-label>
      <input
        [disabled]="saving"
        [formControl]="laborMarginControl"
        (input)="laborInput($event)"
        matInput
        type="text"
        class="quoteside-labmar-ff-in quoteside-margin-ff-in"
      />
      <mat-icon matSuffix class="">
        <span class="material-symbols-outlined quoteside-ff-icon">
          percent
        </span>
      </mat-icon>
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      class="quoteside-gibmar-ff quoteside-ff quoteside-percent-ff"
    >
      <mat-label>Gibson Margin</mat-label>
      <input
        [disabled]="saving"
        [formControl]="gibsonMarginControl"
        (input)="gibsonInput($event)"
        matInput
        type="text"
        class="quoteside-gibmar-ff-in quoteside-margin-ff-in"
      />
      <mat-icon matSuffix class="">
        <span class="material-symbols-outlined quoteside-ff-icon">
          percent
        </span>
      </mat-icon>
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      class="quoteside-taxmar-ff quoteside-ff quoteside-percent-ff"
    >
      <mat-label>Tax Margin</mat-label>
      <input
        [disabled]="saving"
        [formControl]="taxMarginControl"
        (input)="taxInput($event)"
        matInput
        type="text"
        class="quoteside-taxmar-ff-in quoteside-margin-ff-in"
      />
      <mat-icon matSuffix class="">
        <span class="material-symbols-outlined quoteside-ff-icon">
          percent
        </span>
      </mat-icon>
    </mat-form-field>

    <mat-form-field
    appearance="outline"
    class="quoteside-fixtures-ff quoteside-ff"
  >
    <mat-label>Fixtures</mat-label>
    <textarea
      [disabled]="saving"
      [formControl]="fixturesControl"
      matInput
      type="text"
      class="quoteside-fixtures-ff-in"
    ></textarea>
  </mat-form-field>

  <mat-form-field
  appearance="outline"
  class="quoteside-stories-ff quoteside-ff"
>
  <mat-label>Stories</mat-label>
  <input
    [disabled]="saving"
    [formControl]="storiesControl"
    matInput
    type="text"
    class="quoteside-stories-ff-in"
  >
</mat-form-field>

<mat-form-field
appearance="outline"
class="quoteside-bath-ff quoteside-ff"
>
<mat-label>Bath</mat-label>
<input
  [disabled]="saving"
  [formControl]="bathControl"
  matInput
  type="text"
  class="quoteside-bath-ff-in"
>
</mat-form-field>

<mat-form-field
appearance="outline"
class="quoteside-waterheaters-ff quoteside-ff"
>
<mat-label>WaterHeaters</mat-label>
<textarea
  [disabled]="saving"
  [formControl]="waterHeatersControl"
  matInput
  type="text"
  class="quoteside-waterheaters-ff-in"
></textarea>
</mat-form-field>
    <div
      *ngIf="showActions()"
      class="quoteside-actions"
    >
      <button
        (click)="resetControls()"
        color="warn"
        mat-flat-button
        class="quoteside-actions-cancel"
      >
        Cancel
      </button>
      <button
        [disabled]="!canSave()"
        (click)="saveChanges()"
        color="primary"
        mat-flat-button
        class="quoteside-actions-save"
      >
        Save
      </button>
    </div>
  </div>
</app-sidebar>
