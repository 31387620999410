<h1 mat-dialog-title>Wasabi and Local Files</h1>
<div mat-dialog-content>
  <h5>Local Files</h5>
  <div class="col" *ngFor="let file of data.nativeFileList">
    <small> {{ file.name }}</small>
  </div>

  <div class="localFileFolder">
    <button
      mat-flat-button
      color="warn"
      (click)="openFileExplorer()"
      class="localFileButton"
    >
      <i class="far fa-folder-open fa-3x text-dark"></i> Local Files
    </button>
  </div>

  <hr />
  <h5>Wasabi Files</h5>
  <div class="files">
    <div *ngFor="let file of data.wasabiList">
      <a [href]="file" target="_blank" class="fileStructure">
        <i class="fas fa-file-alt fa-4x"></i>{{ getFileName(file) }}</a
      >
    </div>
  </div>
</div>
