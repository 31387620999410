<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content>
  <p>{{ data.message }}</p>

  <h5>Type the following to confirm:</h5>
  <p class="confmod-nosel">
    <strong>"{{ data.superConfirmText }}"</strong>
  </p>
  <mat-form-field appearance="outline" class="confmod-ff">
    <mat-label>Confirmation</mat-label>
    <input (paste)="(false)" [formControl]="confTextControl" matInput />
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions class="confmod-controls">
  <button
    [disabled]="confTextControl.value != data.superConfirmText"
    (click)="confTextControl.value == data.superConfirmText ? ok() : undefined"
    mat-button
  >
    Confirm
  </button>
  <button (click)="cancel()" cdkFocusInitial mat-button>Cancel</button>
  <pre></pre>
</mat-dialog-actions>
