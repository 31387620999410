<div [class]="scr.getClasses() + ' addrcuass'">
  <mat-card class="addrcuass-cd">
    <div class="addrcuass-cd-hdr">
      <h4>Confirmation Assignments</h4>
      <ng-container *ngIf="resultsLoading.isLoading()">
        <div class="addrcuass-cd-hdr-ldng">
          <mat-spinner class="addrcuass-cd-hdr-ldng-spnr"></mat-spinner>
        </div>
      </ng-container>
      <ng-container *ngIf="!resultsLoading.isLoading()">
        <div class="addrcuass-cd-hdr-ctrls">
          <mat-form-field
            appearance="outline"
            class="addrcuass-cd-hdr-ctrls-ff addrcuass-cd-hdr-ctrls-son"
          >
            <mat-label>SO#</mat-label>
            <input
              [formControl]="salesOrderFilterControl"
              type="text"
              matInput
              class="addrcuass-cd-hdr-ctrls-ff-in"
            />
          </mat-form-field>

          <mat-form-field
            appearance="outline"
            class="addrcuass-cd-hdr-ctrls-ff addrcuass-cd-hdr-ctrls-addr"
          >
            <mat-label>Address</mat-label>
            <input
              [formControl]="addressFilterControl"
              type="text"
              matInput
              class="addrcuass-cd-hdr-ctrls-ff-in"
            />
          </mat-form-field>

          <mat-form-field
            appearance="outline"
            class="addrcuass-cd-hdr-ctrls-ff addrcuass-cd-hdr-ctrls-subd"
          >
            <mat-label>Subdivision</mat-label>
            <input
              [formControl]="subdivFilterControl"
              type="text"
              matInput
              class="addrcuass-cd-hdr-ctrls-ff-in"
            />
          </mat-form-field>

          <mat-form-field
            appearance="outline"
            class="addrcuass-cd-hdr-ctrls-ff"
          >
            <mat-label>Customer</mat-label>
            <input
              [formControl]="customerFilterControl"
              type="text"
              matInput
              class="addrcuass-cd-hdr-ctrls-ff-in"
            />
          </mat-form-field>
          <div class="addrcuass-cd-hdr-ctrls-crtcstmr">
            <button
              (click)="createCustomerModalOpen = true"
              color="primary"
              mat-flat-button
            >
              Create customer
            </button>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="addrcuass-cd-ctnt">
      <div class="addrcuass-cd-ctnt-data">
        <ng-container *ngIf="!resultsLoading.isLoading()">
          <!-- Now make a mat table that has the columns address, customer, subdivision, primary email, secondary email, tertiary email, and a edit/save column -->
          <table
            mat-table
            [dataSource]="addresses"
            matSort
            class="mat-elevation-z8 addrcuass-cd-ctnt-data-tbl"
          >
            <!-- SalesOrderNo Column -->
            <ng-container matColumnDef="SalesOrderNo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>SO#</th>
              <td
                mat-cell
                *matCellDef="let element"
                [innerHTML]="
                  highlightSearchText(
                    element.SalesOrderNo,
                    salesOrderFilterControl.value
                  )
                "
                class="addrcuass-cd-ctnt-data-tbl-col addrcuass-cd-ctnt-data-tbl-col-son addrcuass-cd-ctnt-data-tbl-col-sm"
              ></td>
            </ng-container>

            <!-- Address Column -->
            <ng-container matColumnDef="ShipToName">
              <th
                (click)="setTheSorter()"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
              >
                Address
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                [innerHTML]="
                  highlightSearchText(
                    element.ShipToName,
                    addressFilterControl.value
                  )
                "
                class="addrcuass-cd-ctnt-data-tbl-col addrcuass-cd-ctnt-data-tbl-col-lg"
              ></td>
            </ng-container>

            <!-- Customer Column -->
            <ng-container matColumnDef="CustomerName">
              <th
                (click)="setTheSorter()"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
              >
                Customer
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                [innerHTML]="
                  highlightSearchText(
                    element.CustomerName,
                    customerFilterControl.value
                  )
                "
                class="addrcuass-cd-ctnt-data-tbl-col addrcuass-cd-ctnt-data-tbl-col-md"
              ></td>
            </ng-container>

            <!-- Subdivision Column -->
            <ng-container matColumnDef="Subdivision">
              <th
                (click)="setTheSorter()"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
              >
                Subdivision
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                [innerHTML]="
                  highlightSearchText(
                    element.Subdivision,
                    subdivFilterControl.value
                  )
                "
                class="addrcuass-cd-ctnt-data-tbl-col addrcuass-cd-ctnt-data-tbl-col-md"
              ></td>
            </ng-container>

            <!-- Primary Email Column -->
            <ng-container matColumnDef="primaryEmailControl">
              <th mat-header-cell *matHeaderCellDef>Primary Email</th>
              <td
                mat-cell
                *matCellDef="let element"
                class="addrcuass-cd-ctnt-data-tbl-col addrcuass-cd-ctnt-data-tbl-col-md"
              >
                <mat-form-field
                  appearance="outline"
                  (contextmenu)="
                    showContextMenu($event, primaryTrigger); (false)
                  "
                  class="addrcuass-cd-ctnt-data-tbl-col-ff"
                >
                  <button
                    [matMenuTriggerFor]="primaryMenu"
                    #primaryTrigger="matMenuTrigger"
                    class="addrcuass-cd-ctnt-data-tbl-col-ctxtrgr"
                  ></button>
                  <mat-menu
                    #primaryMenu="matMenu"
                    class="addrcuass-cd-ctnt-data-tbl-col-ctxtmenu"
                  >
                    <div
                      (click)="
                        $event.preventDefault(); $event.stopPropagation()
                      "
                      class="addrcuass-cd-ctnt-data-tbl-col-ctxtmenu-info"
                    >
                      <span
                        *ngIf="
                          emailToCustomer(element.primaryEmailControl.value)
                        "
                        class="addrcuass-cd-ctnt-data-tbl-col-ctxtmenu-info-nm"
                      >
                        {{
                          emailToCustomer(element.primaryEmailControl.value)
                            .AppCustomer.AppCustomer_FirstName
                        }}
                        {{
                          emailToCustomer(element.primaryEmailControl.value)
                            .AppCustomer.AppCustomer_LastName
                        }}
                      </span>
                      <span
                        class="addrcuass-cd-ctnt-data-tbl-col-ctxtmenu-info-eml"
                      >
                        {{ element.primaryEmailControl.value }}
                      </span>
                      <span
                        *ngIf="
                          emailToCustomer(element.primaryEmailControl.value)
                        "
                        class="addrcuass-cd-ctnt-data-tbl-col-ctxtmenu-info-pn"
                      >
                        {{
                          emailToCustomer(element.primaryEmailControl.value)
                            .AppCustomer.AppCustomer_Email | phone
                        }}
                      </span>
                    </div>
                    <hr class="addrcuass-cd-ctnt-data-tbl-col-ctxtmenu-spcr" />
                    <button
                      (click)="
                        setAllInSubdivision(element, { primaryEmail: true })
                      "
                      mat-menu-item
                    >
                      Apply to {{ element.Subdivision }} subdivision
                    </button>
                    <button
                      *ngIf="addresses.filteredData.length < 300"
                      (click)="
                        setAllInFiltered(element, { primaryEmail: true })
                      "
                      mat-menu-item
                    >
                      Apply to filtered
                    </button>
                  </mat-menu>
                  <mat-select
                    [formControl]="element.primaryEmailControl"
                    (opened)="selsearch.focus()"
                    #sel
                    class="addrcuass-cd-ctnt-data-tbl-col-ff-sel"
                  >
                    <input
                      [formControl]="element.primaryEmailSearchControl"
                      [autofocus]="true"
                      #selsearch
                      (keydown)="$event.stopPropagation()"
                      placeholder="Search"
                      type="text"
                      class="addrcuass-cd-ctnt-data-tbl-col-ff-sel-srch"
                    />
                    <hr class="addrcuass-cd-ctnt-data-tbl-col-ff-sel-hr" />
                    <div class="addrcuass-cd-ctnt-data-tbl-col-ff-sel-val">
                      <button
                        *ngIf="
                          emailToCustomer(element.primaryEmailControl.value) ==
                          undefined
                        "
                        (click)="
                          openPrefilledCreateCustomerModal({
                            email: element.primaryEmailControl.value
                          });
                          sel.close()
                        "
                        color="accent"
                        mat-flat-button
                        class="addrcuass-cd-ctnt-data-tbl-col-ff-sel-val-prfl"
                      >
                        Create new customer
                      </button>
                    </div>
                    <mat-option hidden></mat-option>
                    <mat-option
                      *ngIf="
                        emailToCustomer(element.primaryEmailControl.value) !=
                        undefined
                      "
                      [value]="
                        emailToCustomer(element.primaryEmailControl.value)
                          .AppCustomer.AppCustomer_Email
                      "
                      class="addrcuass-cd-ctnt-data-tbl-col-ff-sel-op"
                    >
                      {{
                        emailToCustomer(element.primaryEmailControl.value)
                          .AppCustomer.AppCustomer_FirstName
                      }}
                      {{
                        emailToCustomer(element.primaryEmailControl.value)
                          .AppCustomer.AppCustomer_LastName
                      }}
                    </mat-option>
                    <mat-option
                      *ngFor="let cstmr of element.primaryCustomers"
                      [hidden]="
                        cstmr.data.AppCustomer.AppCustomer_Email ==
                          emailToCustomer(element.primaryEmailControl.value)
                            ?.AppCustomer.AppCustomer_Email &&
                        cstmr.data.AppCustomer.AppCustomer_FirstName ==
                          emailToCustomer(element.primaryEmailControl.value)
                            ?.AppCustomer.AppCustomer_FirstName &&
                        cstmr.data.AppCustomer.AppCustomer_LastName ==
                          emailToCustomer(element.primaryEmailControl.value)
                            ?.AppCustomer.AppCustomer_LastName
                      "
                      [value]="cstmr.data.AppCustomer.AppCustomer_Email"
                      class="addrcuass-cd-ctnt-data-tbl-col-ff-sel-op"
                    >
                      <span [innerHTML]="cstmr.matchText"></span>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </ng-container>

            <!-- Secondary Email Column -->
            <ng-container matColumnDef="secondaryEmailControl">
              <th mat-header-cell *matHeaderCellDef>Secondary Email</th>
              <td
                mat-cell
                *matCellDef="let element"
                class="addrcuass-cd-ctnt-data-tbl-col addrcuass-cd-ctnt-data-tbl-col-md"
              >
                <mat-form-field
                  appearance="outline"
                  (contextmenu)="
                    showContextMenu($event, secondaryTrigger); (false)
                  "
                  class="addrcuass-cd-ctnt-data-tbl-col-ff"
                >
                  <button
                    [matMenuTriggerFor]="secondaryMenu"
                    #secondaryTrigger="matMenuTrigger"
                    class="addrcuass-cd-ctnt-data-tbl-col-ctxtrgr"
                  ></button>
                  <mat-menu
                    #secondaryMenu="matMenu"
                    class="addrcuass-cd-ctnt-data-tbl-col-ctxtmenu"
                  >
                    <div
                      (click)="
                        $event.preventDefault(); $event.stopPropagation()
                      "
                      class="addrcuass-cd-ctnt-data-tbl-col-ctxtmenu-info"
                    >
                      <span
                        *ngIf="
                          emailToCustomer(element.secondaryEmailControl.value)
                        "
                        class="addrcuass-cd-ctnt-data-tbl-col-ctxtmenu-info-nm"
                      >
                        {{
                          emailToCustomer(element.secondaryEmailControl.value)
                            .AppCustomer.AppCustomer_FirstName
                        }}
                        {{
                          emailToCustomer(element.secondaryEmailControl.value)
                            .AppCustomer.AppCustomer_LastName
                        }}
                      </span>
                      <span
                        class="addrcuass-cd-ctnt-data-tbl-col-ctxtmenu-info-eml"
                      >
                        {{ element.secondaryEmailControl.value }}
                      </span>
                      <span
                        *ngIf="
                          emailToCustomer(element.secondaryEmailControl.value)
                        "
                        class="addrcuass-cd-ctnt-data-tbl-col-ctxtmenu-info-pn"
                      >
                        {{
                          emailToCustomer(element.secondaryEmailControl.value)
                            .AppCustomer.AppCustomer_Phone | phone
                        }}
                      </span>
                    </div>
                    <hr class="addrcuass-cd-ctnt-data-tbl-col-ctxtmenu-spcr" />
                    <button
                      (click)="
                        setAllInSubdivision(element, { secondaryEmail: true })
                      "
                      mat-menu-item
                    >
                      Apply to {{ element.Subdivision }} subdivision
                    </button>
                    <button
                      *ngIf="addresses.filteredData.length < 300"
                      (click)="
                        setAllInFiltered(element, { secondaryEmail: true })
                      "
                      mat-menu-item
                    >
                      Apply to filtered
                    </button>
                  </mat-menu>
                  <mat-select
                    [formControl]="element.secondaryEmailControl"
                    (opened)="selsearch.focus()"
                    #sel
                    class="addrcuass-cd-ctnt-data-tbl-col-ff-sel"
                  >
                    <input
                      [formControl]="element.secondaryEmailSearchControl"
                      [autofocus]="true"
                      #selsearch
                      (keydown)="$event.stopPropagation()"
                      placeholder="Search"
                      type="text"
                      class="addrcuass-cd-ctnt-data-tbl-col-ff-sel-srch"
                    />
                    <hr class="addrcuass-cd-ctnt-data-tbl-col-ff-sel-hr" />
                    <div class="addrcuass-cd-ctnt-data-tbl-col-ff-sel-val">
                      <button
                        *ngIf="
                          emailToCustomer(
                            element.secondaryEmailControl.value
                          ) == undefined
                        "
                        (click)="
                          openPrefilledCreateCustomerModal({
                            email: element.secondaryEmailControl.value
                          });
                          sel.close()
                        "
                        color="accent"
                        mat-flat-button
                        class="addrcuass-cd-ctnt-data-tbl-col-ff-sel-val-prfl"
                      >
                        Create new customer
                      </button>
                    </div>
                    <mat-option hidden></mat-option>
                    <mat-option
                      *ngIf="
                        emailToCustomer(element.secondaryEmailControl.value) !=
                        undefined
                      "
                      [value]="
                        emailToCustomer(element.secondaryEmailControl.value)
                          .AppCustomer.AppCustomer_Email
                      "
                      class="addrcuass-cd-ctnt-data-tbl-col-ff-sel-op"
                    >
                      {{
                        emailToCustomer(element.secondaryEmailControl.value)
                          .AppCustomer.AppCustomer_FirstName
                      }}
                      {{
                        emailToCustomer(element.secondaryEmailControl.value)
                          .AppCustomer.AppCustomer_LastName
                      }}
                    </mat-option>
                    <mat-option
                      *ngFor="let cstmr of element.secondaryCustomers"
                      [hidden]="
                        cstmr.data.email ==
                          emailToCustomer(element.secondaryEmailControl.value)
                            ?.AppCustomer.AppCustomer_Email &&
                        cstmr.data.FirstName ==
                          emailToCustomer(element.secondaryEmailControl.value)
                            ?.AppCustomer.AppCustomer_FirstName &&
                        cstmr.data.LastName ==
                          emailToCustomer(element.secondaryEmailControl.value)
                            ?.AppCustomer.AppCustomer_LastName
                      "
                      [value]="cstmr.data.AppCustomer.AppCustomer_Email"
                      class="addrcuass-cd-ctnt-data-tbl-col-ff-sel-op"
                    >
                      <span [innerHTML]="cstmr.matchText"></span>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </ng-container>

            <!-- Tertiary Email Column -->
            <ng-container matColumnDef="tertiaryEmailControl">
              <th mat-header-cell *matHeaderCellDef>Tertiary Email</th>
              <td
                mat-cell
                *matCellDef="let element"
                class="addrcuass-cd-ctnt-data-tbl-col addrcuass-cd-ctnt-data-tbl-col-md"
              >
                <mat-form-field
                  appearance="outline"
                  class="addrcuass-cd-ctnt-data-tbl-col-ff"
                  (contextmenu)="
                    showContextMenu($event, tertiaryTrigger); (false)
                  "
                >
                  <button
                    [matMenuTriggerFor]="tertiaryMenu"
                    #tertiaryTrigger="matMenuTrigger"
                    class="addrcuass-cd-ctnt-data-tbl-col-ctxtrgr"
                  ></button>
                  <mat-menu
                    #tertiaryMenu="matMenu"
                    class="addrcuass-cd-ctnt-data-tbl-col-ctxtmenu"
                  >
                    <div
                      (click)="
                        $event.preventDefault(); $event.stopPropagation()
                      "
                      class="addrcuass-cd-ctnt-data-tbl-col-ctxtmenu-info"
                    >
                      <span
                        *ngIf="
                          emailToCustomer(element.tertiaryEmailControl.value)
                        "
                        class="addrcuass-cd-ctnt-data-tbl-col-ctxtmenu-info-nm"
                      >
                        {{
                          emailToCustomer(element.tertiaryEmailControl.value)
                            .AppCustomer.AppCustomer_FirstName
                        }}
                        {{
                          emailToCustomer(element.tertiaryEmailControl.value)
                            .AppCustomer.AppCustomer_LastName
                        }}
                      </span>
                      <span
                        class="addrcuass-cd-ctnt-data-tbl-col-ctxtmenu-info-eml"
                      >
                        {{ element.tertiaryEmailControl.value }}
                      </span>
                      <span
                        *ngIf="
                          emailToCustomer(element.tertiaryEmailControl.value)
                        "
                        class="addrcuass-cd-ctnt-data-tbl-col-ctxtmenu-info-pn"
                      >
                        {{
                          emailToCustomer(element.tertiaryEmailControl.value)
                            .AppCustomer.AppCustomer_Phone | phone
                        }}
                      </span>
                    </div>
                    <hr class="addrcuass-cd-ctnt-data-tbl-col-ctxtmenu-spcr" />
                    <button
                      (click)="
                        setAllInSubdivision(element, { tertiaryEmail: true })
                      "
                      mat-menu-item
                    >
                      Apply to {{ element.Subdivision }} subdivision
                    </button>
                    <button
                      *ngIf="addresses.filteredData.length < 300"
                      (click)="
                        setAllInFiltered(element, { tertiaryEmail: true })
                      "
                      mat-menu-item
                    >
                      Apply to filtered
                    </button>
                  </mat-menu>
                  <mat-select
                    [formControl]="element.tertiaryEmailControl"
                    (opened)="selsearch.focus()"
                    #sel
                    class="addrcuass-cd-ctnt-data-tbl-col-ff-sel"
                  >
                    <input
                      [formControl]="element.tertiaryEmailSearchControl"
                      [autofocus]="true"
                      #selsearch
                      (keydown)="$event.stopPropagation()"
                      placeholder="Search"
                      type="text"
                      class="addrcuass-cd-ctnt-data-tbl-col-ff-sel-srch"
                    />
                    <hr class="addrcuass-cd-ctnt-data-tbl-col-ff-sel-hr" />
                    <div class="addrcuass-cd-ctnt-data-tbl-col-ff-sel-val">
                      <button
                        *ngIf="
                          emailToCustomer(element.tertiaryEmailControl.value) ==
                          undefined
                        "
                        (click)="
                          openPrefilledCreateCustomerModal({
                            email: element.tertiaryEmailControl.value
                          });
                          sel.close()
                        "
                        color="accent"
                        mat-flat-button
                        class="addrcuass-cd-ctnt-data-tbl-col-ff-sel-val-prfl"
                      >
                        Create new customer
                      </button>
                    </div>
                    <mat-option hidden></mat-option>
                    <mat-option
                      *ngIf="
                        emailToCustomer(element.tertiaryEmailControl.value) !=
                        undefined
                      "
                      [value]="
                        emailToCustomer(element.tertiaryEmailControl.value)
                          .AppCustomer.AppCustomer_Email
                      "
                      class="addrcuass-cd-ctnt-data-tbl-col-ff-sel-op"
                    >
                      {{
                        emailToCustomer(element.tertiaryEmailControl.value)
                          .AppCustomer.AppCustomer_FirstName
                      }}
                      {{
                        emailToCustomer(element.tertiaryEmailControl.value)
                          .AppCustomer.AppCustomer_LastName
                      }}
                    </mat-option>
                    <mat-option
                      *ngFor="let cstmr of element.tertiaryCustomers"
                      [hidden]="
                        cstmr.data.email ==
                          emailToCustomer(element.tertiaryEmailControl.value)
                            ?.AppCustomer.AppCustomer_Email &&
                        cstmr.data.FirstName ==
                          emailToCustomer(element.tertiaryEmailControl.value)
                            ?.AppCustomer.AppCustomer_FirstName &&
                        cstmr.data.LastName ==
                          emailToCustomer(element.tertiaryEmailControl.value)
                            ?.AppCustomer.AppCustomer_LastName
                      "
                      [value]="cstmr.data.AppCustomer.AppCustomer_Email"
                      class="addrcuass-cd-ctnt-data-tbl-col-ff-sel-op"
                    >
                      <span [innerHTML]="cstmr.matchText"></span>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </ng-container>

            <!-- Edit Column -->
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>Actions</th>
              <td
                mat-cell
                *matCellDef="let element"
                class="addrcuass-cd-ctnt-data-tbl-col addrcuass-cd-ctnt-data-tbl-col-md"
              >
                <div class="addrcuass-cd-ctnt-data-tbl-col-actns">
                  <button
                    (click)="resetControls([element])"
                    [disabled]="
                      !isChanged([element]) ||
                      element.primaryEmailControl?.disabled ||
                      element.secondaryEmailControl?.disabled ||
                      element.tertiaryEmailControl?.disabled
                    "
                    mat-flat-button
                    color="warn"
                    class="addrcuass-cd-ctnt-data-tbl-col-actns-actn addrcuass-cd-ctnt-data-tbl-col-actns-rst"
                  >
                    cancel
                  </button>
                  <button
                    (click)="saveChanges([element])"
                    [disabled]="
                      !isChanged([element]) ||
                      element.primartEmailControl?.disabled ||
                      element.secondaryEmailControl?.disabled ||
                      element.tertiaryEmailControl?.disabled
                    "
                    color="primary"
                    mat-flat-button
                    class="addrcuass-cd-ctnt-data-tbl-col-actns-actn addrcuass-cd-ctnt-data-tbl-col-actns-save"
                  >
                    save
                  </button>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </ng-container>
      </div>
      <mat-paginator
        [hidden]="resultsLoading.isLoading()"
        [pageSizeOptions]="[10, 15, 20]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </mat-card>
  <div class="addrcuass-actns">
    <ng-container *ngIf="getChangedElements().length > 0">
      <button
        [disabled]="saving.isLoading()"
        mat-flat-button
        color="warn"
        class="addrcuass-actns-btn"
        (click)="resetControls()"
      >
        {{
          getChangedElements().length > 1 ? 'Reset addresses' : 'Reset address'
        }}
      </button>
      <button
        [disabled]="saving.isLoading()"
        mat-flat-button
        color="primary"
        class="addrcuass-actns-btn"
        (click)="saveChanges()"
      >
        {{
          getChangedElements().length > 1
            ? 'Update ' + getChangedElements().length + ' addresses'
            : 'Update address'
        }}
      </button>
    </ng-container>
  </div>
</div>

<app-bottom-sheet
  [open]="createCustomerModalOpen || createCustomerLoading"
  (sheetClosed)="createCustomerModalOpen = false; resetCustomerControls()"
  [sheetMaxWidth]="'600px'"
>
  <div class="addrcuass-bs">
    <h1 class="addrcuass-bs-title">Create Customer</h1>
    <div class="addrcuass-bs-ctrls">
      <div class="addrcuass-bs-ctrls-itm">
        <mat-form-field
          appearance="outline"
          class="addrcuass-bs-ctrls-itm-ff addrcuass-bs-ctrls-itm-nm"
        >
          <mat-label>First Name</mat-label>
          <input
            [formControl]="ccFirstNameControl"
            matInput
            class="addrcuass-bs-ctrls-itm-ff-in"
          />
          <mat-error>Invalid first name</mat-error>
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          class="addrcuass-bs-ctrls-itm-ff addrcuass-bs-ctrls-itm-nm"
        >
          <mat-label>Last Name</mat-label>
          <input
            [formControl]="ccLastNameControl"
            matInput
            class="addrcuass-bs-ctrls-itm-ff-in"
          />
          <mat-error>Invalid last name</mat-error>
        </mat-form-field>
      </div>
      <div class="addrcuass-bs-ctrls-itm">
        <mat-form-field
          appearance="outline"
          class="addrcuass-bs-ctrls-itm-ff addrcuass-bs-ctrls-itm-eml"
        >
          <mat-label>Email</mat-label>
          <input
            [formControl]="ccEmailControl"
            matInput
            class="addrcuass-bs-ctrls-itm-ff-in"
          />
          <mat-error>Invalid email</mat-error>
        </mat-form-field>
      </div>
      <div class="addrcuass-bs-ctrls-itm">
        <mat-form-field
          appearance="outline"
          class="addrcuass-bs-ctrls-itm-ff addrcuass-bs-ctrls-itm-pn"
        >
          <mat-label>Phone #</mat-label>
          <input
            [formControl]="ccPhoneControl"
            matInput
            class="addrcuass-bs-ctrls-itm-ff-in"
          />
          <mat-error>Invalid phone number</mat-error>
        </mat-form-field>
      </div>
      <div class="addrcuass-bs-ctrls-itm">
        <mat-form-field
          appearance="outline"
          class="addrcuass-bs-ctrls-itm-ff addrcuass-bs-ctrls-itm-tp"
        >
          <mat-label>Type</mat-label>
          <mat-select
            [formControl]="ccTypeControl"
            class="addrcuass-bs-ctrls-itm-ff-sel"
          >
            <mat-option
              *ngFor="let customerType of customerTypes"
              [value]="customerType.AppCustomerType_guid"
            >
              {{ customerType.AppCustomerType_Name }}
            </mat-option>
          </mat-select>
          <mat-error>Invalid customer type</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="addrcuass-bs-actions">
      <button
        [disabled]="
          saving.isLoading() ||
          ccFirstNameControl.invalid ||
          ccLastNameControl.invalid ||
          ccEmailControl.invalid ||
          ccPhoneControl.invalid ||
          ccTypeControl.invalid
        "
        mat-flat-button
        color="primary"
        class="addrcuass-bs-actions-btn"
        (click)="createCustomer()"
      >
        Create
      </button>
    </div>
  </div>
</app-bottom-sheet>
