import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AlertifyService } from '../../../_services/alertify/alertify.service';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import { AuthService } from '../../../_services/auth/auth.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MultiIsLoadingService } from 'src/app/_services/multi-is-loading/multi-is-loading.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { MatMenuTrigger } from '@angular/material/menu';
import { BaseModalService } from 'src/app/_services/BaseModalService/BaseModalService';
import { shareReplay } from 'rxjs/operators';

interface AddressInterface {
  CustomerName: string;
  Expr1: string;
  Expr2: string | null;
  OrderType: string;
  PhoneNumberPrimary: string;
  PhoneNumberSecondary: string;
  PhoneNumberTertiary: string;
  SalesOrderNo: string;
  ShipToName: string;
  Subdivision: string;
  UDF_BUILDER_EMAIL_1: string;
  UDF_BUILDER_EMAIL_2: string;
  UDF_BUILDER_EMAIL_3: string;
  WarehouseCode: string;
}

interface AddressEditableInterface extends AddressInterface {
  primaryEmailControl: FormControl;
  primaryEmailSearchControl: FormControl;
  primaryCustomers: FilteredCustomerInterface[];
  secondaryEmailControl: FormControl;
  secondaryEmailSearchControl: FormControl;
  secondaryCustomers: FilteredCustomerInterface[];
  tertiaryEmailControl: FormControl;
  tertiaryEmailSearchControl: FormControl;
  tertiaryCustomers: FilteredCustomerInterface[];
}

interface CustomerInterface {
  FirstName: string;
  LastName: string;
  email: string;
  id: number;
  phoneNumber: string;
}

interface FilteredCustomerInterface {
  data: CustomerInterface;
  searchText: string;
  matchText: string;
}

interface SaveEmailInterface {
  salesorderno: string;
  email: string;
  emailSelection: 'primary' | 'secondary' | 'tertiary';
  user: string;
}

interface SaveNewCustomerInterface {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  user: string;
}

@Component({
  selector: 'app-address-customer-assignment',
  templateUrl: './address-customer-assignment.component.html',
  styleUrls: ['./address-customer-assignment.component.css'],
  providers: [
    { provide: 'resultsLoading', useClass: MultiIsLoadingService },
    { provide: 'saving', useClass: MultiIsLoadingService },
  ],
})
export class AddressCustomerAssignmentComponent
  implements OnInit, AfterViewInit {
  customers: CustomerInterface[] = [];

  addresses: MatTableDataSource<AddressEditableInterface> = new MatTableDataSource(
    []
  );
  displayedColumns: string[] = [
    'SalesOrderNo',
    'ShipToName',
    'CustomerName',
    'Subdivision',
    'primaryEmailControl',
    'secondaryEmailControl',
    'tertiaryEmailControl',
    'actions',
  ];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sorter: MatSort;

  salesOrderFilterControl = new FormControl('');
  addressFilterControl = new FormControl('');
  subdivFilterControl = new FormControl('');
  customerFilterControl = new FormControl('');

  createCustomerModalOpen = false;
  createCustomerLoading = false;
  ccFirstNameControl = new FormControl('', [
    Validators.required,
    Validators.minLength(3),
  ]);
  ccLastNameControl = new FormControl('', [
    Validators.required,
    Validators.minLength(3),
  ]);
  ccEmailControl = new FormControl('', [Validators.required, Validators.email]);
  ccPhoneControl = new FormControl('');

  constructor(
    private alertify: AlertifyService,
    private sageApi: SageApiService,
    private authService: AuthService,
    public scr: ScreenSizeService,
    public cd: ChangeDetectorRef,
    @Inject('resultsLoading') public resultsLoading: MultiIsLoadingService,
    @Inject('saving') public saving: MultiIsLoadingService,
    private snkbr: MatSnackBar,
    private bm: BaseModalService
  ) {}

  ngOnInit(): void {
    this.loadCustomers();
    this.loadAddresses();

    this.resultsLoading.loadingStateChange.subscribe(() => {
      this.setTheSorter();
    });
  }

  ngAfterViewInit(): void {
    this.setTheSorter();
    this.setFilter();

    // Listen for changes on all the filter controls and call setFilter to update the filterPredicate
    this.salesOrderFilterControl.valueChanges.subscribe(() => this.setFilter());
    this.addressFilterControl.valueChanges.subscribe(() => this.setFilter());
    this.subdivFilterControl.valueChanges.subscribe(() => this.setFilter());
    this.customerFilterControl.valueChanges.subscribe(() => this.setFilter());
  }

  setTheSorter() {
    this.addresses.paginator = this.paginator;
    this.addresses.sort = this.sorter;
  }

  setFilter() {
    // Set the filterPredicate function to filter the data based on the filter controls
    this.addresses.filterPredicate = (data, filter) => {
      const filterSalesOrderText = this.salesOrderFilterControl.value.toLowerCase();
      const filterAddressText = this.addressFilterControl.value.toLowerCase();
      const filterSubdivText = this.subdivFilterControl.value.toLowerCase();
      const filterCustomerText = this.customerFilterControl.value.toLowerCase();
      // If all three are empty, return true to show all data
      const result =
        (filterSalesOrderText == '' ||
          data.SalesOrderNo.toLowerCase().includes(filterSalesOrderText)) &&
        // If filterAddressText is not empty, check if the ShipToName includes the filterAddressText
        (filterAddressText == '' ||
          data.ShipToName.toLowerCase().includes(filterAddressText)) &&
        // If filterCustomerText is not empty, check if the CustomerName includes the filterCustomerText
        (filterCustomerText == '' ||
          data.CustomerName.toLowerCase().includes(filterCustomerText)) &&
        // If filterSubdivText is not empty, check if the Subdivision includes the filterSubdivText
        (filterSubdivText == '' ||
          data.Subdivision.toLowerCase().includes(filterSubdivText));
      // If filterSalesOrderText is not empty, check if the SalesOrderNo includes the filterSalesOrderText
      return result;
    };
    this.addresses.filter = [
      this.addressFilterControl.value,
      this.subdivFilterControl.value,
      this.customerFilterControl.value,
    ].join(' ');
  }

  highlightSearchText(text: string, search: string): string {
    // Find the start of all occurances of search in text. Then, starting at the end, insert a </b> tag to a copy of text.
    // Then, starting at the start, insert a <b> tag to the copy of text.
    // This will bold the search text in the text.
    let result = text;
    if (search == '') {
      return result;
    }
    const searchLower = search.toLowerCase();
    const textLower = text.toLowerCase();
    // First, find all the indexes of the search text in the text and put it in an array
    let searchIndexes = [];
    let index = textLower.indexOf(searchLower);
    while (index != -1) {
      searchIndexes.push(index);
      index = textLower.indexOf(searchLower, index + 1);
    }
    // Sort the indexes in desc order
    searchIndexes = searchIndexes.sort((a, b) => b - a);
    // Now, starting at the end, insert a </b> tag to a copy of text
    for (let i = 0; i < searchIndexes.length; i++) {
      const start = searchIndexes[i];
      const end = start + search.length;
      result =
        result.slice(0, start) +
        '<b>' +
        result.slice(start, end) +
        '</b>' +
        result.slice(end);
    }
    return result;
  }

  loadAddresses(): Observable<any> {
    const obs = this.resultsLoading.loadingUntilComplete(
      this.sageApi.pullReport('Address', {
        matchCriteria: ['Address'],
        exp: 60000 * 2, // Cache for 2 minutes
      })
    );
    obs.subscribe(
      (address: Array<any>) => {
        this.addresses = new MatTableDataSource<AddressEditableInterface>(
          address
            .map((addr: AddressInterface) => {
              const ed: AddressEditableInterface = {
                ...addr,
                primaryEmailControl: new FormControl(addr.UDF_BUILDER_EMAIL_1),
                primaryCustomers: this.filteredCustomers(''),
                secondaryEmailControl: new FormControl(
                  addr.UDF_BUILDER_EMAIL_2
                ),
                secondaryCustomers: this.filteredCustomers(''),
                tertiaryEmailControl: new FormControl(addr.UDF_BUILDER_EMAIL_3),
                tertiaryCustomers: this.filteredCustomers(''),
                primaryEmailSearchControl: new FormControl(''),
                secondaryEmailSearchControl: new FormControl(''),
                tertiaryEmailSearchControl: new FormControl(''),
              };
              ed.primaryEmailControl.disabled;
              return ed;
            })
            .sort((a, b) => a.SalesOrderNo.localeCompare(b.SalesOrderNo))
        );

        // Now loop through the addresses and listen to the search controls for each address. Use that to filter the customers
        this.addresses.data.forEach(addr => {
          addr.primaryEmailSearchControl.valueChanges.subscribe(() => {
            addr.primaryCustomers = this.filteredCustomers(
              addr.primaryEmailSearchControl.value
            );
          });
          addr.secondaryEmailSearchControl.valueChanges.subscribe(() => {
            addr.secondaryCustomers = this.filteredCustomers(
              addr.secondaryEmailSearchControl.value
            );
          });
          addr.tertiaryEmailSearchControl.valueChanges.subscribe(() => {
            addr.tertiaryCustomers = this.filteredCustomers(
              addr.tertiaryEmailSearchControl.value
            );
          });
        });
        this.setTheSorter();
        this.setFilter();
      },
      err => {
        console.log(err);
        this.snkbr.open('Failed to load addresses', 'Dismiss', {
          duration: Infinity,
        });
      }
    );
    return obs;
  }

  loadCustomers(): Observable<any> {
    const obs = this.resultsLoading.loadingUntilComplete(
      this.sageApi.pullReport('Users/Customers', {
        matchCriteria: ['Customer'],
        exp: 60000 * 2, // Cache for 2 minutes
      })
    );
    obs.subscribe(
      (customers: Array<any>) => {
        this.customers = customers;
      },
      err => {
        console.log(err);
        this.snkbr.open('Failed to load customers', 'Dismiss', {
          duration: Infinity,
        });
      }
    );
    return obs;
  }

  emailToCustomer(email: string): CustomerInterface | undefined {
    return this.customers.find(cust => cust.email == email);
  }

  filteredCustomers(name: string): FilteredCustomerInterface[] {
    return this.customers
      .map(c => {
        return {
          data: c,
          searchText: `${c.FirstName} ${c.LastName}`,
          matchText: this.highlightSearchText(
            `${c.FirstName} ${c.LastName}`,
            name
          ),
        };
      })
      .filter(fc => {
        // If name is empty, return false to not show any customers
        if (name.trim() == '') {
          return false;
        }

        // check if the matchText and searchText are the same
        // If they aren't, you know that match text is bolded because it matched the name
        return fc.matchText != fc.searchText;
      });
  }

  resetControls(elements?: AddressEditableInterface[]) {
    // If no element is provided, reset all the controls
    if (elements == undefined) {
      this.getChangedElements().forEach(addr => {
        addr.primaryEmailControl.setValue(addr.UDF_BUILDER_EMAIL_1);
        addr.primaryEmailSearchControl.setValue('');
        addr.secondaryEmailControl.setValue(addr.UDF_BUILDER_EMAIL_2);
        addr.secondaryEmailSearchControl.setValue('');
        addr.tertiaryEmailControl.setValue(addr.UDF_BUILDER_EMAIL_3);
        addr.tertiaryEmailSearchControl.setValue('');
      });
    } else {
      // If a element is provided, only reset those controls
      for (const element of elements) {
        element.primaryEmailControl.setValue(element.UDF_BUILDER_EMAIL_1);
        element.primaryEmailSearchControl.setValue('');
        element.secondaryEmailControl.setValue(element.UDF_BUILDER_EMAIL_2);
        element.secondaryEmailSearchControl.setValue('');
        element.tertiaryEmailControl.setValue(element.UDF_BUILDER_EMAIL_3);
        element.tertiaryEmailSearchControl.setValue('');
      }
    }
  }

  disableControls(elements?: AddressEditableInterface[]) {
    // If no element is provided, disable all the controls
    if (elements == undefined) {
      this.addresses.data.forEach(addr => {
        addr.primaryEmailControl.disable();
        addr.primaryEmailSearchControl.disable();
        addr.secondaryEmailControl.disable();
        addr.secondaryEmailSearchControl.disable();
        addr.tertiaryEmailControl.disable();
        addr.tertiaryEmailSearchControl.disable();
      });
    } else {
      // If a element is provided, only disable those controls
      for (const element of elements) {
        element.primaryEmailControl.disable();
        element.primaryEmailSearchControl.disable();
        element.secondaryEmailControl.disable();
        element.secondaryEmailSearchControl.disable();
        element.tertiaryEmailControl.disable();
        element.tertiaryEmailSearchControl.disable();
      }
    }
  }

  enableControls(elements?: AddressEditableInterface[]) {
    // If no element is provided, enable all the controls
    if (elements == undefined) {
      this.addresses.data.forEach(addr => {
        addr.primaryEmailControl.enable();
        addr.primaryEmailSearchControl.enable();
        addr.secondaryEmailControl.enable();
        addr.secondaryEmailSearchControl.enable();
        addr.tertiaryEmailControl.enable();
        addr.tertiaryEmailSearchControl.enable();
      });
    } else {
      // If an element is provided, only enable that element's controls
      for (const element of elements) {
        element.primaryEmailControl.enable();
        element.primaryEmailSearchControl.enable();
        element.secondaryEmailControl.enable();
        element.secondaryEmailSearchControl.enable();
        element.tertiaryEmailControl.enable();
        element.tertiaryEmailSearchControl.enable();
      }
    }
  }

  setAllInSubdivision(
    element: AddressEditableInterface,
    which?: {
      primaryEmail?: boolean;
      secondaryEmail?: boolean;
      tertiaryEmail?: boolean;
    }
  ): void {
    // Sets all the email controls with the same subdivision as the element
    // If the which is set, use that to set the emails, if not, set all emails
    const subdivision = element.Subdivision;
    const same = this.addresses.data.filter(
      addr => addr.Subdivision == subdivision
    );
    if (which == undefined) {
      for (const addr of same) {
        addr.primaryEmailControl.setValue(element.primaryEmailControl.value);
        addr.secondaryEmailControl.setValue(
          element.secondaryEmailControl.value
        );
        addr.tertiaryEmailControl.setValue(element.tertiaryEmailControl.value);
      }
    } else {
      for (const addr of same) {
        if (which?.primaryEmail === true) {
          addr.primaryEmailControl.setValue(element.primaryEmailControl.value);
        }
        if (which?.secondaryEmail === true) {
          addr.secondaryEmailControl.setValue(
            element.secondaryEmailControl.value
          );
        }
        if (which?.tertiaryEmail === true) {
          addr.tertiaryEmailControl.setValue(
            element.tertiaryEmailControl.value
          );
        }
      }
    }
    this.cd.detectChanges(); // force a rerender
  }

  setAllInFiltered(
    element: AddressEditableInterface,
    which?: {
      primaryEmail?: boolean;
      secondaryEmail?: boolean;
      tertiaryEmail?: boolean;
    }
  ): void {
    this.bm
      .confirm(
        'Really apply to all filtered results?',
        'Applying to all filtered will apply this email to all of the columns for the addresses that are currently in the table, not just the ones on this page. After saving, there is no way to undo this. Make sure this is what you mean to do before your select this option.'
      )
      .subscribe(result => {
        if (result) {
          // If the which is not set, get all the filtered elements in this.addresses and set the emails
          // If it is set, use that to set the emails
          const filtered = this.addresses.filteredData;
          if (which == undefined) {
            for (const addr of filtered) {
              addr.primaryEmailControl.setValue(
                element.primaryEmailControl.value
              );
              addr.secondaryEmailControl.setValue(
                element.secondaryEmailControl.value
              );
              addr.tertiaryEmailControl.setValue(
                element.tertiaryEmailControl.value
              );
            }
          } else {
            for (const addr of filtered) {
              if (which?.primaryEmail === true) {
                addr.primaryEmailControl.setValue(
                  element.primaryEmailControl.value
                );
              }
              if (which?.secondaryEmail === true) {
                addr.secondaryEmailControl.setValue(
                  element.secondaryEmailControl.value
                );
              }
              if (which?.tertiaryEmail === true) {
                addr.tertiaryEmailControl.setValue(
                  element.tertiaryEmailControl.value
                );
              }
            }
          }
          this.cd.detectChanges(); // force a rerender
        }
      });
    this.cd.detectChanges(); // force a rerender
    // Go forward a page, go back a page, go back a page, go forward a page
    this.paginator.nextPage();
    this.paginator.previousPage();
    this.cd.detectChanges(); // force a rerender
    this.paginator.previousPage();
    this.paginator.nextPage();
    this.cd.detectChanges(); // force a rerender
  }

  isChanged(elements?: AddressEditableInterface[]): boolean {
    // If no element is provided, check if any of the controls have changed
    if (elements == undefined) {
      return this.addresses.data.some(
        addr =>
          addr.primaryEmailControl.value != addr.UDF_BUILDER_EMAIL_1 ||
          addr.secondaryEmailControl.value != addr.UDF_BUILDER_EMAIL_2 ||
          addr.tertiaryEmailControl.value != addr.UDF_BUILDER_EMAIL_3
      );
    } else {
      // If elements are provided, check if those controls have changed
      return elements.some(
        element =>
          element.primaryEmailControl.value != element.UDF_BUILDER_EMAIL_1 ||
          element.secondaryEmailControl.value != element.UDF_BUILDER_EMAIL_2 ||
          element.tertiaryEmailControl.value != element.UDF_BUILDER_EMAIL_3
      );
    }
  }

  getChangedElements(): AddressEditableInterface[] {
    return this.addresses.data.filter(addr => this.isChanged([addr]));
  }

  saveEmail(toSave: SaveEmailInterface) {
    return this.sageApi.putRequest('Users/Customers', toSave);
  }

  saveEmails(toSave: SaveEmailInterface[]) {
    return this.sageApi.putRequest('users-customers', toSave);
  }

  applyChanges(elements?: AddressEditableInterface[]) {
    // Loop through all the elements if provided, otherwise loop through all the elements in this.addresses
    // For each set the UDF_BUILDER_EMAIL_1, UDF_BUILDER_EMAIL_2, and UDF_BUILDER_EMAIL_3 to the value of the primaryEmailControl, secondaryEmailControl, and tertiaryEmailControl
    if (elements == undefined) {
      this.addresses.data.forEach(addr => {
        addr.UDF_BUILDER_EMAIL_1 = addr.primaryEmailControl.value;
        addr.UDF_BUILDER_EMAIL_2 = addr.secondaryEmailControl.value;
        addr.UDF_BUILDER_EMAIL_3 = addr.tertiaryEmailControl.value;
      });
    } else {
      for (const element of elements) {
        element.UDF_BUILDER_EMAIL_1 = element.primaryEmailControl.value;
        element.UDF_BUILDER_EMAIL_2 = element.secondaryEmailControl.value;
        element.UDF_BUILDER_EMAIL_3 = element.tertiaryEmailControl.value;
      }
    }
  }

  saveChanges(elements?: AddressEditableInterface[]) {
    // if element is provided, only save that element
    if (elements == undefined) {
      const changed = this.getChangedElements();
      this.disableControls(changed);
      const saveEmails: SaveEmailInterface[] = [];
      changed.map(addr => {
        if (addr.primaryEmailControl.value != addr.UDF_BUILDER_EMAIL_1) {
          const bdy: SaveEmailInterface = {
            salesorderno: addr.SalesOrderNo,
            email: addr.primaryEmailControl.value,
            emailSelection: 'primary',
            user: this.authService.decodedToken?.nameid, // TODO: Make this more secure!
          };
          saveEmails.push(bdy);
        }
        if (addr.secondaryEmailControl.value != addr.UDF_BUILDER_EMAIL_2) {
          const bdy: SaveEmailInterface = {
            salesorderno: addr.SalesOrderNo,
            email: addr.secondaryEmailControl.value,
            emailSelection: 'secondary',
            user: this.authService.decodedToken?.nameid, // TODO: Make this more secure!
          };
          saveEmails.push(bdy);
        }
        if (addr.tertiaryEmailControl.value != addr.UDF_BUILDER_EMAIL_3) {
          const bdy: SaveEmailInterface = {
            salesorderno: addr.SalesOrderNo,
            email: addr.tertiaryEmailControl.value,
            emailSelection: 'tertiary',
            user: this.authService.decodedToken?.nameid, // TODO: Make this more secure!
          };
          saveEmails.push(bdy);
        }
      });
      this.saving.loadingUntilComplete(this.saveEmails(saveEmails)).subscribe({
        complete: () => {
          this.applyChanges(changed);
          this.enableControls(changed);
          this.snkbr.open('Changes saved', 'Dismiss', { duration: 2000 });
          this.cd.detectChanges(); // force a rerender
        },
        error: () => {
          this.enableControls(changed);
          this.snkbr.open('There was an error saving your changes', 'Dismiss', {
            duration: Infinity,
          });
        },
      });
    } else {
      const saveEmails: SaveEmailInterface[] = [];
      this.disableControls(elements);
      for (const element of elements) {
        const obs: Observable<any>[] = [];
        if (element.primaryEmailControl.value != element.UDF_BUILDER_EMAIL_1) {
          const bdy: SaveEmailInterface = {
            salesorderno: element.SalesOrderNo,
            email: element.primaryEmailControl.value,
            emailSelection: 'primary',
            user: this.authService.decodedToken?.nameid, // TODO: Make this more secure!
          };
          saveEmails.push(bdy);
        }
        if (
          element.secondaryEmailControl.value != element.UDF_BUILDER_EMAIL_2
        ) {
          const bdy: SaveEmailInterface = {
            salesorderno: element.SalesOrderNo,
            email: element.secondaryEmailControl.value,
            emailSelection: 'secondary',
            user: this.authService.decodedToken?.nameid, // TODO: Make this more secure!
          };
          saveEmails.push(bdy);
        }
        if (element.tertiaryEmailControl.value != element.UDF_BUILDER_EMAIL_3) {
          const bdy: SaveEmailInterface = {
            salesorderno: element.SalesOrderNo,
            email: element.tertiaryEmailControl.value,
            emailSelection: 'tertiary',
            user: this.authService.decodedToken?.nameid, // TODO: Make this more secure!
          };
          saveEmails.push(bdy);
        }
      }
      this.saving.loadingUntilComplete(this.saveEmails(saveEmails)).subscribe({
        complete: () => {
          this.applyChanges(elements);
          this.enableControls(elements);
          this.snkbr.open('Changes saved', 'Dismiss', { duration: 2000 });
          this.cd.detectChanges(); // force a rerender
        },
        error: () => {
          this.enableControls(elements);
          this.snkbr.open('There was an error saving your changes', 'Dismiss', {
            duration: Infinity,
          });
        },
      });
    }
  }

  showContextMenu(event: MouseEvent, trgr: MatMenuTrigger) {
    event.preventDefault();
    trgr.openMenu();
  }

  resetCustomerControls() {
    this.ccFirstNameControl.reset('');
    this.ccLastNameControl.reset('');
    this.ccEmailControl.reset('');
    this.ccPhoneControl.reset('');
  }

  disableCustomerControls() {
    this.ccFirstNameControl.disable();
    this.ccLastNameControl.disable();
    this.ccEmailControl.disable();
    this.ccPhoneControl.disable();
  }

  enableCustomerControls() {
    this.ccFirstNameControl.enable();
    this.ccLastNameControl.enable();
    this.ccEmailControl.enable();
    this.ccPhoneControl.enable();
  }

  openPrefilledCreateCustomerModal(prefill: {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
  }) {
    this.ccFirstNameControl.setValue(prefill.firstName || '');
    this.ccLastNameControl.setValue(prefill.lastName || '');
    this.ccEmailControl.setValue(prefill.email || '');
    this.ccPhoneControl.setValue(prefill.phone || '');
    this.createCustomerModalOpen = true;
  }

  createCustomer() {
    this.createCustomerModalOpen = true;
    this.createCustomerLoading = true;
    this.disableCustomerControls();
    const newCustomer: SaveNewCustomerInterface = {
      firstName: this.ccFirstNameControl.value,
      lastName: this.ccLastNameControl.value,
      email: this.ccEmailControl.value,
      phoneNumber: this.ccPhoneControl.value,
      user: this.authService.decodedToken?.nameid, // TODO: Make this more secure!
    };
    this.sageApi.postRequest('Users/Customers', newCustomer).subscribe(
      () => {
        this.createCustomerLoading = false;
        this.createCustomerModalOpen = false;
        // Get the last element in customers
        const lastCustomer = this.customers[this.customers.length - 1];
        this.customers.push({
          FirstName: newCustomer.firstName,
          LastName: newCustomer.lastName,
          email: newCustomer.email,
          id: lastCustomer.id + 1, // The things we do because nobody does REST right
          phoneNumber: newCustomer.phoneNumber,
        });
        this.resetCustomerControls();
      },
      () => {
        this.enableCustomerControls();
        this.createCustomerLoading = false;
        this.snkbr.open('Failed to create customer', 'Dismiss', {
          duration: Infinity,
        });
      }
    );
  }
}
