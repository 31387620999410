import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import QuoteDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteDtoInterface';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import DateValidators from 'src/app/Validators/DateValidators';
import QuoteStatuses from '../QuoteStatuses';
import InputDecimalSanitizer from 'src/app/utils/InputDecimalSanitizer';
import KitRegions from '../KitRegions';
import { WasabiApiService } from 'src/app/_services/wasabiApi/wasabiApi.service';

export interface DialogData {
  guid: string;
  type: string;
}
import { QuoteEditableService } from 'src/app/_services/QuoteEditableService/QuoteEditableService';
import { BaseModalService } from 'src/app/_services/BaseModalService/BaseModalService';
import { UploadModalService } from 'src/app/_services/UploadModalService/UploadModalService';

@Component({
  selector: 'app-qvsummary',
  templateUrl: './QuoteViewSummaryComponent.html',
  styleUrls: ['../QuotingTheme.scss', './QuoteViewSummaryComponent.css'],
})
export class QuoteViewSummaryComponent implements OnInit, OnChanges {
  @Input() quote: QuoteDtoInterface;

  @Input() itemTotal = 0;
  @Input() costTotal = 0;
  @Input() rawCost = 0;
  @Input() partCost = 0;
  @Input() partTotalCost = 0;
  @Input() partTotal = 0;
  @Input() laborCost = 0;
  @Input() laborTotalCost = 0;
  @Input() laborTotal = 0;

  saving = false;

  nameControl = new FormControl('', [Validators.required]);
  descControl = new FormControl('', [
    Validators.required,
    Validators.maxLength(255),
  ]);
  statusControl = new FormControl('', [Validators.required]);
  builderControl = new FormControl('', [Validators.required]);
  squareFootageControl = new FormControl('', [
    Validators.required,
    Validators.min(1),
    Validators.max(999999.99),
  ]);
  minExpiration = new Date().toISOString().slice(0, 10);
  expirationControl = new FormControl(new Date(), [Validators.required]);
  materialMarginControl = new FormControl('', [
    Validators.required,
    Validators.min(0),
    Validators.max(9999.9999),
  ]);
  laborMarginControl = new FormControl('', [
    Validators.required,
    Validators.min(0),
    Validators.max(9999.9999),
  ]);
  gibsonMarginControl = new FormControl('', [
    Validators.required,
    Validators.min(0),
    Validators.max(9999.9999),
  ]);
  taxMarginControl = new FormControl('', [
    Validators.required,
    Validators.min(0),
    Validators.max(9999.9999),
  ]);

  regionControl = new FormControl('', [Validators.required]);
  fixturesControl = new FormControl('', [
    Validators.required,
    Validators.maxLength(255),
  ]);
  storiesControl = new FormControl('', [
    Validators.required,
    Validators.maxLength(100),
  ]);
  bathControl = new FormControl('', [
    Validators.required,
    Validators.maxLength(50),
  ]);
  waterHeatersControl = new FormControl('', [
    Validators.required,
    Validators.maxLength(255),
  ]);
  loadingPDF: boolean;

  regions = KitRegions;

  quoteStatuses = QuoteStatuses;

  @Output() quoteChanged = new EventEmitter<QuoteDtoInterface>();

  constructor(
    public api: SageApiService,
    public screenSize: ScreenSizeService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private upmod: UploadModalService,
    private qe: QuoteEditableService
  ) {}

  ngOnInit() {
    this.resetControls();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetControls();
  }

  resetControls() {
    const expDate = new Date(this.quote.Quote_Expiration);
    // Add one day to the expDate because the date picker will default to the day before the date
    // BTW, copilot just knew this was the issue and autogenerated this comment. How cool is that?
    expDate.setDate(expDate.getDate() + 1);

    this.nameControl = new FormControl(this.quote.Quote_Name, [
      Validators.required,
    ]);
    this.statusControl = new FormControl(this.quote.Quote_Status, [
      Validators.required,
    ]);
    this.builderControl = new FormControl(this.quote.Quote_Builder, [
      Validators.required,
    ]);
    this.squareFootageControl = new FormControl(
      this.quote.Quote_SquareFootage,
      [Validators.required, Validators.min(1), Validators.max(999999.99)]
    );
    this.expirationControl = new FormControl(expDate, [
      Validators.required,
      DateValidators.dateAfterValidator(
        this.minExpiration,
        this.quote.Quote_Expiration,
        {}
      ),
    ]);
    this.descControl = new FormControl(this.quote.Quote_Desc, [
      Validators.maxLength(255),
    ]);

    this.materialMarginControl = new FormControl(
      this.convertToPercent(this.quote.Quote_MaterialMargin),
      [Validators.min(0), Validators.max(9999.9999)]
    );
    this.laborMarginControl = new FormControl(
      this.convertToPercent(this.quote.Quote_LaborMargin),
      [Validators.min(0), Validators.max(9999.9999)]
    );
    this.gibsonMarginControl = new FormControl(
      this.convertToPercent(this.quote.Quote_GibsonMargin),
      [Validators.min(0), Validators.max(9999.9999)]
    );
    this.taxMarginControl = new FormControl(
      this.convertToPercent(this.quote.Quote_TaxMargin),
      [Validators.min(0), Validators.max(9999.9999)]
    );
    this.regionControl = new FormControl(this.quote.Quote_Region, [
      Validators.required,
    ]);
    this.fixturesControl = new FormControl(this.quote.Quote_Fixtures, [
      Validators.required,
      Validators.maxLength(255),
    ]);
    this.storiesControl = new FormControl(this.quote.Quote_Stories, [
      Validators.required,
      Validators.maxLength(100),
    ]);
    this.bathControl = new FormControl(this.quote.Quote_Bath, [
      Validators.required,
      Validators.maxLength(50),
    ]);
    this.waterHeatersControl = new FormControl(this.quote.Quote_WaterHeaters, [
      Validators.required,
      Validators.maxLength(255),
    ]);
  }

  convertToPercent(value: number) {
    const val = value * 1000000 - 1000000; // Avoid floating point errors by multiplying by 1000000
    // See how older ARM chips without an FPU do division with floating point numbers
    // *hint, it's just multiplication of integers and then a shift
    return val / 10000;
  }

  convertFromPercent(value: number) {
    const val = value / 100;
    return val + 1;
  }

  getExpirationControlValue() {
    if (this.expirationControl.value instanceof Date) {
      return new Date(this.expirationControl?.value)
        ?.toISOString()
        ?.slice(0, 10);
    }
    return this.expirationControl.value;
  }

  canSave() {
    const expDate = new Date(this.quote.Quote_Expiration);
    // Add one day to the expDate because the date picker will default to the day before the date
    expDate.setDate(expDate.getDate() + 1);

    const isValid =
      this.nameControl.valid &&
      this.statusControl.valid &&
      this.builderControl.valid &&
      this.squareFootageControl.valid &&
      this.expirationControl.valid &&
      this.materialMarginControl.valid &&
      this.laborMarginControl.valid &&
      this.gibsonMarginControl.valid &&
      this.taxMarginControl.valid &&
      this.regionControl.valid &&
      this.fixturesControl.valid &&
      this.storiesControl.valid &&
      this.bathControl.valid &&
      this.waterHeatersControl.valid;
    const hasChanges =
      this.nameControl.value != this.quote.Quote_Name ||
      this.descControl.value != this.quote.Quote_Desc ||
      this.statusControl.value != this.quote.Quote_Status ||
      this.builderControl.value != this.quote.Quote_Builder ||
      this.squareFootageControl.value != this.quote.Quote_SquareFootage ||
      new Date(this.expirationControl.value).toISOString() !=
        expDate.toISOString() ||
      this.convertFromPercent(this.materialMarginControl.value) !=
        this.quote.Quote_MaterialMargin ||
      this.convertFromPercent(this.laborMarginControl.value) !=
        this.quote.Quote_LaborMargin ||
      this.convertFromPercent(this.gibsonMarginControl.value) !=
        this.quote.Quote_GibsonMargin ||
      this.convertFromPercent(this.taxMarginControl.value) !=
        this.quote.Quote_TaxMargin ||
      this.regionControl.value != this.quote.Quote_Region ||
      this.fixturesControl.value != this.quote.Quote_Fixtures ||
      this.storiesControl.value != this.quote.Quote_Stories ||
      this.bathControl.value != this.quote.Quote_Bath ||
      this.waterHeatersControl.value != this.quote.Quote_WaterHeaters;
    return isValid && hasChanges;
  }

  cancelDisabled() {
    const expDate = new Date(this.quote.Quote_Expiration);
    // Add one day to the expDate because the date picker will default to the day before the date
    expDate.setDate(expDate.getDate() + 1);

    const hasChanges =
      this.nameControl.value != this.quote.Quote_Name ||
      this.descControl.value != this.quote.Quote_Desc ||
      this.statusControl.value != this.quote.Quote_Status ||
      this.builderControl.value != this.quote.Quote_Builder ||
      this.squareFootageControl.value != this.quote.Quote_SquareFootage ||
      new Date(this.expirationControl.value).toISOString() !=
        expDate.toISOString() ||
      this.convertFromPercent(this.materialMarginControl.value) !=
        this.quote.Quote_MaterialMargin ||
      this.convertFromPercent(this.laborMarginControl.value) !=
        this.quote.Quote_LaborMargin ||
      this.convertFromPercent(this.gibsonMarginControl.value) !=
        this.quote.Quote_GibsonMargin ||
      this.convertFromPercent(this.taxMarginControl.value) !=
        this.quote.Quote_TaxMargin ||
      this.regionControl.value != this.quote.Quote_Region;
    return !hasChanges;
  }

  saveChanges() {
    this.quote.Quote_Name = this.nameControl.value;
    this.quote.Quote_Status = this.statusControl.value;
    this.quote.Quote_Builder = this.builderControl.value;
    this.quote.Quote_SquareFootage = this.squareFootageControl.value;
    this.quote.Quote_Expiration = new Date(this.expirationControl.value)
      .toISOString()
      .slice(0, 10);
    this.quote.Quote_Desc = this.descControl.value;
    this.quote.Quote_MaterialMargin = this.convertFromPercent(
      this.materialMarginControl.value
    );
    this.quote.Quote_LaborMargin = this.convertFromPercent(
      this.laborMarginControl.value
    );
    this.quote.Quote_GibsonMargin = this.convertFromPercent(
      this.gibsonMarginControl.value
    );
    this.quote.Quote_TaxMargin = this.convertFromPercent(
      this.taxMarginControl.value
    );
    this.quote.Quote_Region = this.regionControl.value;
    this.quote.Quote_Fixtures = this.fixturesControl.value;
    this.quote.Quote_Stories = this.storiesControl.value;
    this.quote.Quote_Bath = this.bathControl.value;
    this.quote.Quote_WaterHeaters = this.waterHeatersControl.value;
    this.quoteChanged.emit(this.quote);
  }

  cancelChanges() {
    this.nameControl.setValue(this.quote.Quote_Name);
    this.descControl.setValue(this.quote.Quote_Desc);
    this.statusControl.setValue(this.quote.Quote_Status);
    this.builderControl.setValue(this.quote.Quote_Builder);
    this.squareFootageControl.setValue(this.quote.Quote_SquareFootage);
    this.expirationControl.setValue(this.quote.Quote_Expiration);
    this.materialMarginControl.setValue(this.quote.Quote_MaterialMargin);
    this.laborMarginControl.setValue(this.quote.Quote_LaborMargin);
    this.gibsonMarginControl.setValue(this.quote.Quote_GibsonMargin);
    this.taxMarginControl.setValue(this.quote.Quote_TaxMargin);
    this.regionControl.setValue(this.quote.Quote_Region);
    this.fixturesControl.setValue(this.quote.Quote_Fixtures);
    this.storiesControl.setValue(this.quote.Quote_Stories);
    this.bathControl.setValue(this.quote.Quote_Bath);
    this.waterHeatersControl.setValue(this.quote.Quote_WaterHeaters);
  }

  materialInput(e) {
    this.materialMarginControl.setValue(InputDecimalSanitizer(e, 3));
  }

  laborInput(e) {
    this.laborMarginControl.setValue(InputDecimalSanitizer(e, 3));
  }

  gibsonInput(e) {
    this.gibsonMarginControl.setValue(InputDecimalSanitizer(e, 3));
  }

  taxInput(e) {
    this.taxMarginControl.setValue(InputDecimalSanitizer(e, 3));
  }

  squareFootageInput(e) {
    this.squareFootageControl.setValue(InputDecimalSanitizer(e, 6, 2));
  }

  getMaterialDollar() {
    const cost = this.qe.getRawMaterialCost();
    const marginCost = cost * this.qe.quote.data.Quote.Quote_MaterialMargin;
    const diff = marginCost - cost;
    // (cost * margin) - cost = diff
    return diff;
  }

  materialDollarInput(e) {
    console.log(e);

    const cost = this.qe.getRawMaterialCost();
    const diff = parseFloat(e.target.value);
    const margin = (diff + cost) / cost;

    if (margin > 999.9999) {
      this.materialMarginControl.setValue(999.9999);
      e.target.value = '999.9999'; // Needs work, should be set to whatever the cost would be at 999.9999
      return;
    }

    // (diff + cost) / cost = margin
    this.materialMarginControl.setValue(this.convertToPercent(margin));
  }

  openDialog() {
    this.upmod.openWasabiUpload(
      'upload',
      `?guid=${this.quote.Quote_guid}`,
      `quotes/${this.quote.Quote_guid}`,
      'atATime'
    );
  }

  finalizeBid() {
    this.loadingPDF = true;
    const obj = { QuoteGuid: this.quote.Quote_guid, finalized: true };

    this.api.postBlob('preview-quote-pdf', obj).subscribe(
      response => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const file = window.URL.createObjectURL(blob);
        window.open(file);
      },
      e => {
        console.log(e);
        this.loadingPDF = false;

        this.snackBar.open('Error saving PDF.', 'Close');
        throw Error(e);
      },
      () => {
        this.loadingPDF = false;
      }
    );
  }
}

@Component({
  selector: 'app-dialog-overview-example-dialog',
  templateUrl: 'QuoteAttachment.html',
  styleUrls: ['./QuoteViewSummaryComponent.css', '../QuotingTheme.scss'],
})
export class QuoteAttachmentDialogComponent implements OnInit {
  fileArray = [];
  fileLoading: boolean;
  add_attachment: boolean;

  loading: boolean;
  allFiles = [];

  constructor(
    public wasabiApi: WasabiApiService,
    public dialogRef: MatDialogRef<QuoteAttachmentDialogComponent>,
    public bm: BaseModalService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.viewAttachments();
  }

  async viewAttachments() {
    //let objs = this.wasabiApi.getQuoteInfo("97");
    this.loading = true;
    const objs = await this.wasabiApi.getQuoteInfo(this.data.guid);
    this.allFiles = objs;
    this.loading = false;

    console.log(objs);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  fileChosen(event) {
    const files = event.target.files;
    for (const index in files) {
      if (files[index] instanceof File) {
        const obj = {
          name: files[index].name,
          data: files[index],
        };

        this.fileArray.push(obj);
        // for now a single item per upload
      }
    }
  }

  fileUpload() {
    this.fileLoading = true;
    const sentFiles = [];

    this.fileArray.forEach(file => {
      sentFiles.push(file.data);
    });

    this.wasabiApi.uploadFile(this.data.guid, 'quote/', sentFiles).subscribe(
      res => {
        // ToDo: something
      },
      err => {
        this.snackBar.open('Error uploading files', 'Close');
        this.fileLoading = false;
      },
      () => {
        // this.resetUpload();
        this.dialogRef.close();
        this.snackBar.open('Uploaded Successfully!', 'Close', {
          duration: 5000,
        });
      }
    );
  }

  resetUpload() {
    this.fileArray = [];
    this.fileLoading = false;
  }

  getFileName(file: string) {
    if (
      file.includes(
        'https://s3.wasabisys.com/gibson-qa/quotes/' + this.data.guid
      )
    ) {
      let name = file.replace(
        'https://s3.wasabisys.com/gibson-qa/quotes/' + this.data.guid + '/',
        ''
      );
      name = name.replace('%20', ' ');
      return name;
    } else {
      return file;
    }
  }
}
