import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { AuthService } from '../_services/auth/auth.service';
import { AlertifyService } from '../_services/alertify/alertify.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  private allowedRoles: any;

  constructor(
    private authService: AuthService,
    private router: Router,
    private alertify: AlertifyService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    this.allowedRoles = route.data.role;
    const allowed: boolean = this.allowedRoles.includes(
      this.authService.decodedToken?.role
    );

    if (allowed || this.allowedRoles[0] === 'all') {
      return true;
    } else {
      this.alertify.error('You shall not pass!!! Unauthorized.');
      this.router.navigate(['/']);
    }
  }
}
