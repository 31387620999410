import { Component, ViewChild, OnInit, TemplateRef } from '@angular/core';
// tslint:disable-next-line:import-blacklist
import * as Rx from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../../../_services/auth/auth.service';
// import { WomsService } from '../../services/woms/woms.service';
// import { LoginService } from '../../services/login/login.service';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
// import { DataModel } from '../../model/data-model';
import { DatePipe } from '@angular/common';
import { concat } from 'rxjs';
import { AlertifyService } from '../../../_services/alertify/alertify.service';
import { WasabiApiService } from '../../../_services/wasabiApi/wasabiApi.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { ExcelService } from '../../../_services/excel/excel.service';
import WOMSInterface from 'src/app/_services/sageApi/interfaces/pullReport/WOMSInterface';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import { FormControl } from '@angular/forms';
import WOMsMaterialInterface from 'src/app/_services/sageApi/interfaces/pullReport/WOMsMaterialInterface';
import PayLineInterface from 'src/app/_services/sageApi/interfaces/pullReport/PayLineInterface';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-woms-mgt',
  templateUrl: './woms-mgt.component.html',
  styleUrls: ['./woms-mgt.component.css'],
})
export class WomsMgtComponent implements OnInit {
  isLoading: boolean | 'error' = false;
  areaManagers: string[] = [];
  SuperList = [];
  supers: string[] = [];

  ticketStatus = [];
  AmgtControl = new FormControl('');
  superControl = new FormControl('');
  ticketStatusControl = new FormControl('');
  ticketJtWtStatusControl = new FormControl('');
  ticketTypeControl = new FormControl('');
  AssigenWarrantyContrl = new FormControl('', []);
  AssigenBackchargeContrl = new FormControl('', []);

  tableData = new MatTableDataSource();
  tableDataFilter: Map<string, string> = new Map<string, string>();

  modalTemplate: BsModalRef;
  dataModel = {
    customer: '',
    address: '',
    workPerformed: '',
    assignedTo: '',
    statusCode: '',
    steps: [],
    wts: [],
    subdivisions: [],
    orders: [],
    ordersLM: [],
    tracking: '',
    loaded: [],
    loadtech: [],
    loadstatuses: [],
    salesorder: '',
    statusCodes: '',
    techid: '',
    statuscomment: '',
    qaServiceCodes: '',
    newtracking: '',
    finaltracking: '',
    leadmanReport: [],
    redFlag: '',
    miscTech: [],
    uninstalledItems: ['loading'],
    materialChanges: [],
    kpiQA: [],
    kpiPunch: [],
    kpiOpenItems: [],
    serviceCodes: [],
    womsMaterial: [],
    womsLabor: [],
    ListLeadman: [],
    listWomsStatus: [],
    womsHistory: [],
    qaItemsForPunch: [],
  };
  index: any = '0';
  isLoadingTech: boolean;
  loadingStatus: string;
  techLoaded: boolean;
  fridaydate: any;
  displayAdditionalChargeLines: boolean;
  totalMatCost: number;
  totalJobCost: number;
  mapData: any = [];
  payLines: any = [];
  currentpayLine: any;
  payLinesToggle = false;
  imgArr: Array<any> = this.sageApi.imageUrlList;
  imgUrl: any[];
  errorMsg = '';
  reasonToCancel = '';
  username: string = this.authService.decodedToken.nameid;
  completeTicketOnly = false;
  showMaterial = false;
  imageUrlList: any[];
  warrantyCodes: Array<any>;
  backchargeCodes: Array<any>;
  punchWarLoadSelect: string[] = [];
  punchBackLoadSelect: string[] = [];
  selectedWarrantyCodes: Array<any> = [];
  selectedBackchargeCodes: Array<any> = [];
  warReasonCode = '';
  userrole = this.authService.decodedToken.role;

  importTemplateInfo = {
    templateName: '',
    template: '',
    step: '',
  };

  @ViewChild('editInterface') editInterface;
  @ViewChild('editInterfaceBC') editInterfaceBC;
  @ViewChild('cancelModal') cancelModal;
  @ViewChild('imageModal') imageModal;
  @ViewChild('editModal') editModal;
  @ViewChild('BackchargeModal') BackchargeModal;

  bcselinTechSearch = '';

  constructor(
    private router: Router,
    private sageApi: SageApiService,
    private alertify: AlertifyService,
    private wasabiApi: WasabiApiService,
    private authService: AuthService,
    private modalService: BsModalService,
    private excelService: ExcelService,
    public screenSize: ScreenSizeService
  ) {
    this.tableData.data = [];
  }

  ngOnInit(): void {
    this.getAMgtSuper();
    this.setAreaMgt();
    this.loadServiceCodes();
    /* Old state stuff */
    const today = new Date();
    const date = today.getDate();
    const day = today.getDay();
    let friday;
    // saterday = 6 goes to next friday
    if (day === 6) {
      friday = today.setDate(date + 6);
    }
    // sunday = 0 goes to next friday
    // tslint:disable-next-line:one-line
    else if (day === 0) {
      friday = today.setDate(date + 5);
    }
    // monday = 1 goes to next friday
    // tslint:disable-next-line:one-line
    else if (day === 1) {
      friday = today.setDate(date + 4);
    }
    // tslint:disable-next-line:one-line
    else {
      friday = today.setDate(date + (12 - day));
    }
    const datePipe = new DatePipe('en-US');
    this.fridaydate = datePipe.transform(friday, 'yyyy-MM-dd');

    this.loadtech();
    this.loadHistory();
  }

  // tslint:disable-next-line:use-life-cycle-interface use-lifecycle-interface
  ngDoCheck() {
    this.materialCostLoop();
  }

  setTableData() {
    const superFiltered = [];
    for (
      let dataIndex = 0;
      dataIndex < this.dataModel.steps.length;
      dataIndex++
    ) {
      const data = this.dataModel.steps[dataIndex];
      if (this.superControl.value == 'All') {
        superFiltered.push({ data, index: dataIndex });
      } else if (this.superControl.value == data.leadman) {
        superFiltered.push({ data, index: dataIndex });
      }
    }
    const statusFiltered = [];
    for (let dataIndex = 0; dataIndex < superFiltered.length; dataIndex++) {
      const data = superFiltered[dataIndex];
      if (this.ticketJtWtStatusControl.value == 'All') {
        statusFiltered.push({ data: data.data, index: data.index });
      } else if (data.data.jtWTStatus == this.ticketJtWtStatusControl.value) {
        statusFiltered.push({ data: data.data, index: data.index });
      }
    }
    const statusAndTypeFiltered = [];
    for (let dataIndex = 0; dataIndex < statusFiltered.length; dataIndex++) {
      const data = statusFiltered[dataIndex];
      if (this.ticketTypeControl.value == 'All') {
        statusAndTypeFiltered.push({ data: data.data, index: data.index });
      } else if (
        data.data.itemCode == 'ZBACKCHARGE' &&
        data.data.currentStatus == this.ticketTypeControl.value
      ) {
        statusAndTypeFiltered.push({ data: data.data, index: data.index });
      } else if (
        data.data.UDF_MGT_LAST_STATUS == 'WAR' &&
        this.ticketTypeControl.value == 'Warranty'
      ) {
        statusAndTypeFiltered.push({ data: data.data, index: data.index });
      }
    }
    this.tableData = new MatTableDataSource(statusAndTypeFiltered);
  }

  setAreaMgt() {
    this.isLoading = true;
    this.sageApi.pullReport('active-supers').subscribe(activeAreaManagers => {
      this.areaManagers = activeAreaManagers;
      this.isLoading = false;
    });
  }

  AmgtControlChange() {
    if (this.userrole == 'super' || this.userrole == 'superdfw') {
      const findSuper = this.SuperList.find(
        sup => sup.superUser === this.username
      );
      if (findSuper !== undefined) {
        const su = this.supers.find(
          sup => sup == findSuper.UDF_LEADMAN.toLocaleUpperCase()
        );
        if (su === undefined) {
          this.supers.push(findSuper.UDF_LEADMAN.toLocaleUpperCase());
        }
        this.superControl.setValue(findSuper.UDF_LEADMAN.toLocaleUpperCase());
      } else {
        this.superControl.setValue('Unregistered');
      }
    } else {
      this.superControl.setValue('');
    }
    if (this.AmgtControl.value != '') {
      if (this.ticketStatusControl.value == '') {
        this.ticketStatusControl.setValue('1');
      }
      if (this.ticketJtWtStatusControl.value == '') {
        this.ticketJtWtStatusControl.setValue('All');
      }
      if (this.ticketTypeControl.value == '') {
        if (this.userrole == 'super' || this.userrole == 'superdfw') {
          this.ticketTypeControl.setValue('PBCK');
        } else {
          this.ticketTypeControl.setValue('All');
        }
      }
      if (this.superControl.value == '') {
        if (this.userrole == 'super' || this.userrole == 'superdfw') {
          const findSuper = this.SuperList.find(
            sup => sup.superUser === this.username
          );
          if (findSuper !== undefined) {
            this.superControl.setValue(
              findSuper.UDF_LEADMAN.toLocaleUpperCase()
            );
          } else {
            this.superControl.setValue('Unregistered');
          }
        } else {
          this.superControl.setValue('All');
        }
      }
    } else {
      this.ticketStatusControl.setValue(null);
      this.ticketJtWtStatusControl.setValue(null);
      this.ticketTypeControl.setValue(null);
      this.superControl.setValue(null);
    }
    this.loadHistory();
  }

  /* OLD STATE */

  openModal(template: TemplateRef<any>) {
    this.loadingStatus = '';
    this.importTemplateInfo = {
      templateName: '',
      template: '',
      step: '',
    };

    this.modalTemplate = this.modalService.show(template);
  }

  actionEdit(index: string) {
    this.reasonToCancel = '';
    this.dataModel.steps[index].newType = '';
    this.payLines = [];
    this.payLinesToggle = false;
    this.index = index.toString();
    // console.log(this.dataModel.steps[index]);
    this.loadingStatus = '';
    this.errorMsg = '';
    this.loadPayLine(index.toString());
  }

  actionClose() {
    this.reasonToCancel = '';
    this.closeModal();
  }

  closeModal() {
    this.modalTemplate.hide();
  }

  saveChanges(index: number) {
    if (this.dataModel.steps[index].UDF_MGT_LAST_STATUS == 'WAR') {
      if (
        this.dataModel.steps[index].UDF_MGT_LAST_COMMENT.replace(/\s/g, '').length === 0 ||
        this.selectedWarrantyCodes.length === 0
      ) {
        this.loadingStatus = 'Failed: Please enter a Warranty Comment && Reason Code';
        this.alertify.error(this.loadingStatus);
        return;
      }
    }

    this.isLoading = true;
    const datepipe = new DatePipe('en-US');
    const type =
      this.dataModel.steps[index].newType === ''
        ? this.dataModel.steps[index].type
        : this.dataModel.steps[index].newType;

    let newStatus = '';
    if (this.dataModel.steps[index].itemCode === 'ZBACKCHARGE') {
      if (this.dataModel.steps[index].currentStatus === 'PBCK') {
        //  newStatus = 'REVBC';
        switch (type) {
          case 'BUILDER':
            this.dataModel.steps[index].newType = type;
            newStatus = 'REVBC'; /*Pending Pricing Review*/
            break;
          case 'GIBSON':
            this.dataModel.steps[index].newType = type;
            newStatus = 'REVBC'; /*Gibson paying*/
            break;
          case 'DISPUTE':
            this.dataModel.steps[index].newType = type;
            newStatus = 'REVBC'; /*Disputing Builder Charge*/
            break;
          case 'CREW':
            this.dataModel.steps[index].newType = type;
            newStatus = 'REVBC'; /*Crew BackCharged*/
            break;
          case 'CANCEL':
            this.dataModel.steps[index].newType = type;
            newStatus = 'REVBC'; /*Cancelled For Various Reasons*/
            break;
          case 'MAN_DEFECT':
            this.dataModel.steps[index].newType = type;
            newStatus = 'REVBC'; /*Manufacturer Defect*/
            break;
          case 'NO TYPE':
            this.dataModel.steps[index].newType = type;
            newStatus = this.dataModel.steps[index].currentStatus; /*Manufacturer Defect*/
            break;
          default:
            this.dataModel.steps[index].newType = '';
            newStatus = this.dataModel.steps[index].currentStatus;
            break;
        }
      } else if (
        this.dataModel.steps[index].currentStatus === 'REVBC' &&
        this.userrole !== 'super' &&
        this.userrole !== 'superdfw'
      ) {
        switch (type) {
          case 'BUILDER':
            newStatus = 'PRCRV'; /*Pending Pricing Review*/
            break;
          case 'GIBSON':
            newStatus = 'LOSS'; /*Gibson paying*/
            break;
          case 'DISPUTE':
            newStatus = 'DISBC'; /*Disputing Builder Charge*/
            break;
          case 'CREW':
            newStatus = 'CREWBC'; /*Crew BackCharged*/
            break;
          case 'CANCEL':
            newStatus = 'CANCEL'; /*Cancelled For Various Reasons*/
            break;
          case 'MAN_DEFECT':
            newStatus = 'MAN_DEFECT'; /*Manufacturer Defect*/
            break;
          case 'NO TYPE':
            newStatus = this.dataModel.steps[index].currentStatus; /*Manufacturer Defect*/
            break;
          default:
            newStatus = this.dataModel.steps[index].currentStatus;
            break;
        }
      }
    } else {
      switch (this.dataModel.steps[index].newType) {
        case 'BUILDER':
          newStatus = 'PRCRV'; /*Pending Pricing Review*/
          break;
        case 'GIBSON':
          newStatus = 'LOSS'; /*Gibson paying*/
          break;
        case 'DISPUTE':
          newStatus = 'DISBC'; /*Disputing Builder Charge*/
          break;
        case 'CREW':
          newStatus = 'CREWBC'; /*Crew BackCharged*/
          break;
        case 'CANCEL':
          newStatus = 'CANCEL'; /*Cancelled For Various Reasons*/
          break;
        case 'MAN_DEFECT':
          newStatus = 'MAN_DEFECT'; /*Manufacturer Defect*/
          break;
        default:
          newStatus = '';
          break;
      }
    }

    let validCrew1 = false;
    let validCrew2 = false;
    let validCrew3 = false;
    this.dataModel.loadtech.forEach(tech => {
      if (tech.techCode === this.dataModel.steps[index].crew1) {
        validCrew1 = true;
      }
      if (tech.techCode === this.dataModel.steps[index].crew2) {
        validCrew2 = true;
      }
      if (tech.techCode === this.dataModel.steps[index].crew3) {
        validCrew3 = true;
      }
    });

    const submitObj = {
      username: this.authService.decodedToken.nameid,
      password: this.authService.decodedToken[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authentication'
      ],
      salesorderno: this.dataModel.steps[index].soNum,
      wtnumber: this.dataModel.steps[index].soWT,
      lineKey: this.dataModel.steps[index].lineKey,
      timetoCom: '',
      vpo: 'N',
      cancelObj: [],
      mgtObj: [],
      crewObj: [],
      subpayObj: [],
      tripObj: [],
      histObj: [],
      warObj: [],
    };

    if (this.selectedWarrantyCodes.length !== 0) {
      const warObj = [
        this.dataModel.steps[index].UDF_MGT_LAST_COMMENT,
        this.selectedWarrantyCodes[0],
      ];
      submitObj.warObj = warObj;
    }

    if (type === 'CREW') {
      if (this.dataModel.steps[index].newAmount === '') {
        alert('Please Enter an Amount to Charge');
        this.isLoading = false;
        return;
      }
      if (this.dataModel.steps[index].womsLabor.length == 0) {
        if (
          this.dataModel.steps[index].crewPercentage1 > 0 &&
          (this.dataModel.steps[index].crew1 === '' || !validCrew1)
        ) {
          if (this.dataModel.steps[index].crew1 === '') {
            alert('Please Enter a Crew 1 to Charge');
          } else {
            alert('Error: Invalid Crew 1 Tech Code');
          }
          this.isLoading = false;
          return;
        }
        if (
          this.dataModel.steps[index].crewPercentage2 > 0 &&
          (this.dataModel.steps[index].crew2 === '' || !validCrew2)
        ) {
          if (this.dataModel.steps[index].crew2 === '') {
            alert('Please Enter a Crew 2 to Charge');
          } else {
            alert('Error: Invalid Crew 2 Tech Code');
          }
          this.isLoading = false;
          return;
        }
        if (
          this.dataModel.steps[index].crewPercentage3 > 0 &&
          (this.dataModel.steps[index].crew3 === '' || !validCrew3)
        ) {
          if (this.dataModel.steps[index].crew3 === '') {
            alert('Please Enter a Crew 3 to Charge');
          } else {
            alert('Error: Invalid Crew 3 Tech Code');
          }
          this.isLoading = false;
          return;
        }
      }
    }

    if (
      this.dataModel.steps[index].newType === 'CANCEL' &&
      this.reasonToCancel === ''
    ) {
      alert(`Please provide a reason to cancel this ticket.`);
      this.isLoading = false;
    }

    const observables = [];
    const amount = this.dataModel.steps[index].newAmount || 0;

    if (this.dataModel.steps[index].newType === `CANCEL`) {
      const cancelObj = [
        this.dataModel.steps[index].newType,
        this.reasonToCancel,
      ];
      submitObj.cancelObj = cancelObj;
    }

    const mgtObj = [
      /*womsStatus: 0*/ newStatus,
      /*womsStatus: 1*/ type,
      /*womsNotes: 2*/ this.dataModel.steps[index].notes || ' ',
      /*builderName: 3*/ this.dataModel.steps[index].builderName,
      /*builderPhone: 4*/ this.dataModel.steps[index].builderPhone,
      /*Reason Code: 5*/ this.selectedBackchargeCodes[0] || '',
    ];
    submitObj.mgtObj = mgtObj;

    if (type === 'CREW' && this.dataModel.steps[index].crew1 !== '') {
      if (
        new Date(
          datepipe.transform(this.dataModel.steps[index].crewPay1.startDate)
        ).getDay() !== 5
      ) {
        alert('Please Enter a Friday Date For First Backcharge Crew');
        this.isLoading = false;
        return;
      }
      this.dataModel.steps[index].crewPercent1 = (
        (parseInt(this.dataModel.steps[index].crewPercentage1) / 100) *
        amount
      ).toString();
      for (
        let i = 0;
        i < this.dataModel.steps[index].crewPay1.payPeriods;
        i++
      ) {
        const d = new Date(this.dataModel.steps[index].crewPay1.startDate);
        d.setDate(d.getDate() + 1 + 7 * i);
        const datePipeA = new DatePipe('en-US');
        const payDate = datePipeA.transform(d, 'yyyy-MM-dd');
        const crewObj = this.crewBuild(
          this.dataModel.steps[index],
          '0',
          payDate
        );
        submitObj.crewObj.push(crewObj);
      }
    }
    if (type === 'CREW' && this.dataModel.steps[index].crew2 !== '') {
      if (
        new Date(
          datepipe.transform(this.dataModel.steps[index].crewPay2.startDate)
        ).getDay() !== 5
      ) {
        alert('Please Enter a Friday Date For Second Backcharge Crew');
        this.isLoading = false;
        return;
      }
      // tslint:disable-next-line:radix
      this.dataModel.steps[index].crewPercent2 = (
        (parseInt(this.dataModel.steps[index].crewPercentage2) / 100) *
        amount
      ).toString();
      for (
        let i = 0;
        i < this.dataModel.steps[index].crewPay2.payPeriods;
        i++
      ) {
        const d = new Date(this.dataModel.steps[index].crewPay2.startDate);
        d.setDate(d.getDate() + 1 + 7 * i);
        const datePipeB = new DatePipe('en-US');
        const payDate = datePipeB.transform(d, 'yyyy-MM-dd');
        const crewObj = this.crewBuild(
          this.dataModel.steps[index],
          '1',
          payDate
        );
        submitObj.crewObj.push(crewObj);
      }
    }
    if (type === 'CREW' && this.dataModel.steps[index].crew3 !== '') {
      if (
        new Date(
          datepipe.transform(this.dataModel.steps[index].crewPay3.startDate)
        ).getDay() !== 5
      ) {
        alert('Please Enter a Friday Date For Third Backcharge Crew');
        this.isLoading = false;
        return;
      }
      this.dataModel.steps[index].crewPercent3 = (
        (parseInt(this.dataModel.steps[index].crewPercentage3) / 100) *
        amount
      ).toString();
      for (
        let i = 0;
        i < this.dataModel.steps[index].crewPay3.payPeriods;
        i++
      ) {
        const d = new Date(this.dataModel.steps[index].crewPay3.startDate);
        d.setDate(d.getDate() + 1 + 7 * i);
        const datePipeC = new DatePipe('en-US');
        const payDate = datePipeC.transform(d, 'yyyy-MM-dd');
        const crewObj = this.crewBuild(
          this.dataModel.steps[index],
          '2',
          payDate
        );
        submitObj.crewObj.push(crewObj);
      }
    }

    if (this.dataModel.steps[index].payTech) {
      const datePipeS = new DatePipe('en-US');
      const payDate = datePipeS.transform(this.fridaydate, 'yyyy-MM-dd');
      const cr1 = this.dataModel.steps[index].prtDetails.crew1;
      const cr2 = this.dataModel.steps[index].prtDetails.crew2;
      const crL = this.dataModel.steps[index].prtDetails.crewLead;
      const crs1 = this.dataModel.steps[index].prtDetails.crew1Split / 100;
      const crs2 = this.dataModel.steps[index].prtDetails.crew2Split / 100;
      const crsL = this.dataModel.steps[index].prtDetails.crewLeadSplit / 100;

      const subpayObj = [
        /*0*/ payDate,
        /*price: 1*/ this.dataModel.steps[index].newPunchPayAmount.toString(),
        /*parentLineKey: 2*/ this.dataModel.steps[index].lineKey.toString(),
        /*prt: 3*/ this.dataModel.steps[index].prtDetails.prt,
        /*crewEmp1: 4*/ cr1.toString(),
        /*crewEmp2: 5*/ cr2.toString(),
        /*crewLead: 6*/ crL.toString(),
        /*prtCrewSplit1: 7*/ crs1.toString(),
        /*prtCrewSplit2: 8*/ crs2.toString(),
        /*prtLeadSplit: 9*/ crsL.toString(),
        /*techCode: 10*/ this.dataModel.steps[index].techCode,
        /*subVendor: 11*/ this.dataModel.steps[index].subVendor,
        /*existingPayline: 12*/ this.dataModel.steps[index].payLineKey,
      ];
      submitObj.subpayObj = subpayObj;
    }

    if (this.dataModel.steps[index].type === 'BUILDER') {
      submitObj.vpo = 'Y';
    }

    if (this.dataModel.steps[index].payTrip) {
      const datePipeT = new DatePipe('en-US');
      const payDate = datePipeT.transform(this.fridaydate, 'yyyy-MM-dd');
      const cr1 = this.dataModel.steps[index].prtDetails.crew1;
      const cr2 = this.dataModel.steps[index].prtDetails.crew2;
      const crL = this.dataModel.steps[index].prtDetails.crewLead;
      const crs1 = this.dataModel.steps[index].prtDetails.crew1Split / 100;
      const crs2 = this.dataModel.steps[index].prtDetails.crew2Split / 100;
      const crsL = this.dataModel.steps[index].prtDetails.crewLeadSplit / 100;

      const tripObj = [
        /*0*/ payDate,
        /*price: 1*/ this.dataModel.steps[index].techTrip.toString(),
        /*parentLineKey: 2*/ this.dataModel.steps[index].lineKey,
        /*prt: 3*/ this.dataModel.steps[index].prtDetails.prt,
        /*crewEmp1: 4*/ cr1.toString(),
        /*crewEmp2: 5*/ cr2.toString(),
        /*crewLead: 6*/ crL.toString(),
        /*prtCrewSplit1: 7*/ crs1.toString(),
        /*prtCrewSplit2: 8*/ crs2.toString(),
        /*prtLeadSplit: 9*/ crsL.toString(),
        /*techCode: 10*/ this.dataModel.steps[index].techCode,
        /*subVendor: 11*/ this.dataModel.steps[index].subVendor,
      ];
      submitObj.tripObj = tripObj;
    }

    const time =
      this.dataModel.steps[index].newTime === ''
        ? this.dataModel.steps[index].workTime
        : this.dataModel.steps[index].newTime;
    submitObj.timetoCom = time.toString();

    const datePipe = new DatePipe('en-US');
    const todaydate = datePipe.transform(new Date(), 'yyyyMMddHHmmss');
    const tmpKey = this.dataModel.steps[index].soNum + todaydate + 'H';

    const histObj = [
      /*webkey: 0*/ tmpKey,
      /*address: 1*/ this.dataModel.steps[index].address,
      /*descriptionOfWork: 2*/ this.dataModel.steps[index].descriptionOfWork ||
        ' ',
      /*technician: 3*/ this.dataModel.steps[index].technician || ' ',
      /*newTime: 4*/ this.dataModel.steps[index].newTime || ' ',
      /*workTime: 5*/ this.dataModel.steps[index].workTime || ' ',
      /*dateScheduled: 6*/ this.dataModel.steps[index].dateScheduled || ' ',
      /*matNeededDate: 7*/ this.dataModel.steps[index].matNeededDate || ' ',
      /*matDescription: 8*/ this.dataModel.steps[index].matDescription || ' ',
      /*notes: 9*/ this.dataModel.steps[index].notes || ' ',
      /*type: 10*/ this.dataModel.steps[index].type || ' ',
      /*builderName: 11*/ this.dataModel.steps[index].builderName || ' ',
      /*builderPhone: 12*/ this.dataModel.steps[index].builderPhone || ' ',
      /*currentStatus: 13*/ this.dataModel.steps[index].currentStatus || ' ',
      /*currentStatusDate: 14*/ this.dataModel.steps[index].currentStatusDate ||
        ' ',
      /*matNeededCheck: 15*/ this.dataModel.steps[index].matNeededCheck || ' ',
      /*newStatus: 16*/ newStatus || ' ',
      /*newType: 17*/ this.dataModel.steps[index].newType || ' ',
      /*serviceCode: 18*/ this.dataModel.steps[index].serviceCode || ' ',
      /*builderCompany: 19*/ this.dataModel.steps[index].builderCompany || ' ',
    ];
    submitObj.histObj = histObj;

    observables.push(this.sageApi.putRequest('WomsAreaMgt', submitObj));

    // console.log('submitobj', submitObj);

    concat(...observables).subscribe(
      e => {},
      err => {
        console.log(err);
        this.alertify.error('Error submitting WT: ' + err.error.Message);
        this.isLoading = false;
        this.loadingStatus =
          'Failed - ' +
          this.errorMsg +
          ' PLEASE SAVE NOTES AND TRY AGAIN LATER';
      },
      () => {
        this.isLoading = false;
        this.loadingStatus = 'Loaded Successfully - Changes Committed to SAGE';
        this.alertify.success(this.loadingStatus);
        this.closeModal();
        this.dataModel.steps[index].updated = 'updated';
      }
    );
  }

  crewBuild(object, crewNum, payDate) {
    const techCode =
      crewNum === '0'
        ? object.crew1
        : crewNum === '1'
        ? object.crew2
        : crewNum === '2'
        ? object.crew3
        : '';
    const price =
      crewNum === '0'
        ? -object.crewPercent1 / object.crewPay1.payPeriods
        : crewNum === '1'
        ? -object.crewPercent2 / object.crewPay2.payPeriods
        : crewNum === '2'
        ? -object.crewPercent3 / object.crewPay3.payPeriods
        : '';
    const prt =
      crewNum === '0'
        ? object.crewPay1.prt
        : crewNum === '1'
        ? object.crewPay2.prt
        : crewNum === '2'
        ? object.crewPay3.prt
        : '';
    const crewLead = prt === 'Y' ? techCode : '';
    const crewSplit = crewLead !== '' ? 1.0 : 0;
    const subVendor =
      crewNum === '0'
        ? object.crewPay1.subVendor
        : crewNum === '1'
        ? object.crewPay2.subVendor
        : crewNum === '2'
        ? object.crewPay3.subVendor
        : '';

    return [
      /*promiseDate: 0*/ payDate,
      /*unitCost: 1*/ price.toString(),
      /*parentLineKey: 2*/ object.lineKey.toString(),
      /*3*/ prt,
      /*4*/ crewLead,
      /*5*/ crewSplit.toString(),
      /*6*/ techCode,
      /*7*/ subVendor,
    ];
  }

  onWarrantyCodeSelected(code: string) {
    this.selectedWarrantyCodes = [];
    this.AssigenWarrantyContrl.setValue(code);
    const foundPunchCode = this.warrantyCodes.find(i => i.serviceTag === code);
    if (foundPunchCode != null && foundPunchCode !== undefined) {
      if (code !== '') {
        this.selectedWarrantyCodes.push(foundPunchCode.code);
        // this.selectedWarrantyCodes.push(code);
      }
    }
  }

  onBackchargeCodeSelected(code: string) {
    this.selectedBackchargeCodes = [];
    this.AssigenBackchargeContrl.setValue(code);
    const foundPunchCode = this.backchargeCodes.find(i => i.serviceTag === code);
    if (foundPunchCode != null && foundPunchCode !== undefined
    ) {
      if (code !== '') {
        this.selectedBackchargeCodes.push(foundPunchCode.code);
        // this.selectedWarrantyCodes.push(code);
      }
    }
  }

  getAMgtSuper() {
    this.SuperList = [];
    this.isLoading = true;
    this.sageApi.pullReport('AM-supers').subscribe(
      (activeSupers: Array<any>) => {
        activeSupers.forEach(supe => {
          if (supe != null) {
            this.SuperList.push(supe);
          }
        });
        // this.SuperList = activeSupers;
      },
      error => {
        console.error(error);
        this.isLoading = false;
      },
      () => {
        if (this.userrole == 'super' || this.userrole == 'superdfw') {
          const findSuper = this.SuperList.find(sup => {
            if (sup !== null) {
              return sup.superUser === this.username;
            }
          });
          if (findSuper !== undefined) {
            this.AmgtControl.setValue(findSuper.UDF_AREA_MANAGER);
            this.AmgtControlChange();
          } else {
            this.AmgtControl.setValue('Unassigned');
            this.AmgtControlChange();
          }
        }

        this.isLoading = false;
        this.loadingStatus = ``;
      }
    );
  }

  loadServiceCodes() {
    // this.serviceCodes = [];
    this.warrantyCodes = [];
    this.backchargeCodes = [];
    this.punchWarLoadSelect = [];
    this.punchBackLoadSelect = [];
    this.isLoading = true;
    this.loadingStatus = `Loading service codes...`;
    const codesList = this.sageApi.pullReport('Punch/ServiceCodes').subscribe(
      (codes: Array<any>) => {
        const i = 0;
        codes.forEach(singleCode => {
          const obj = {
            objectID: i,
            code: singleCode.UDF_CODE,
            desc: singleCode.UDF_DESCRIPTION,
            serviceTag:
              singleCode.UDF_CODE + ' - ' + singleCode.UDF_DESCRIPTION,
            type: singleCode.UDF_TYPE,
          };
          // if (obj.type === 'PUNCH'){
          //   this.serviceCodes.push(obj);
          //   this.punchLoadSelect.push(obj.serviceTag);
          //   i++;
          // }
          // else
          if (obj.type === 'WARRANTY') {
            this.warrantyCodes.push(obj);
            this.punchWarLoadSelect.push(obj.serviceTag);
          } else if (obj.type === 'BACKCHARGE') {
            this.backchargeCodes.push(obj);
            this.punchBackLoadSelect.push(obj.serviceTag);
          }
        });
        // console.log(this.backchargeCodes);
      },
      error => {
        console.error(error);
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
        this.loadingStatus = ``;
      }
    );
  }

  loadHistory() {
    this.isLoading = true;
    this.index = '0';
    this.dataModel.steps = [];
    this.supers = [];
    this.supers.push('All');
    this.supers.push('Unregistered');
    if (this.userrole == 'super' || this.userrole == 'superdfw') {
      const findSuper = this.SuperList.find(
        sup => sup.superUser === this.username
      );
      if (findSuper !== undefined) {
        const su = this.supers.find(
          sup => sup == findSuper.UDF_LEADMAN.toLocaleUpperCase()
        );
        if (su === undefined) {
          this.supers.push(findSuper.UDF_LEADMAN.toLocaleUpperCase());
        }
        this.superControl.setValue(findSuper.UDF_LEADMAN.toLocaleUpperCase());
      } else {
        this.superControl.setValue('Unregistered');
      }
    }
    this.sageApi
      .pullReport(
        `woms-history?id=${this.ticketStatusControl.value}&superName=${this.AmgtControl.value}`
      )
      .subscribe(
        (wts: WOMSInterface[]) => {
          if (wts && Object.keys(wts).length < 1) {
            return;
          }
          if (Array.isArray(wts)) {
            wts.forEach(w => {
              let techNotes = '';
              if (
                w.UDF_TRACKING_COMMENT === undefined ||
                w.UDF_TRACKING_COMMENT === ''
              ) {
                techNotes = 'No tech notes found';
              }

              const obj = {
                soNum: w.SalesOrderNo,
                soWT: w.WTNumber,
                wtNumber: w.SalesOrderNo + ['-'] + w.WTNumber,
                lineKey: w.LineKey,
                address: w.Address,
                technician: w.Technician,
                techCode: w.TechCode,
                descriptionOfWork: w.DescriptionOfWork,
                techComment:
                  techNotes === '' ? w.UDF_TRACKING_COMMENT : techNotes,
                currentStatus: w.Status,
                builderName: w.BuilderName || '',
                builderPhone: w.BuilderPhone || '',
                builderCompany: w.BuilderCompany,
                subdivision: w.Subdivision || '',
                leadman: w.UDF_LEADMAN,
                lmManager: w.LM_MANAGER,
                workTime: w.WorkTime,
                matNeededCheck: w.UDF_MAT_NEEDED_CHECK,
                matNeededDate: w.UDF_MAT_DATE_NEEDED,
                matDescription: w.UDF_MAT_DESCRIPTION,
                dateScheduled: w.PromiseDate,
                type: w.Type,
                newType: '',
                notes: w.TypeComment,
                backChargeComment: w.CommentText,
                backChargeAmount: w.LinePrice,
                newAmount: (w.WorkTime / 60) * 45,
                billPayAmount: w.LinePrice,
                newBillPayAmount: w.LinePrice,
                punchPayAmount: w.LinePrice,
                newPunchPayAmount: w.LinePrice,
                newTime: '',
                crew1: '',
                crew2: '',
                crew3: '',
                crewPercent1: '',
                crewPercent2: '',
                crewPercent3: '',
                crewPercentage1: '100',
                crewPercentage2: '0',
                crewPercentage3: '0',
                crewPay1: {
                  payPeriods: '1',
                  startDate: this.fridaydate,
                  prt: 'N',
                  subVendor: '',
                },
                crewPay2: {
                  payPeriods: '1',
                  startDate: this.fridaydate,
                  prt: 'N',
                  subVendor: '',
                },
                crewPay3: {
                  payPeriods: '1',
                  startDate: this.fridaydate,
                  prt: 'N',
                  subVendor: '',
                },
                updated: '',
                toCrew: w.TOPOUT_CREW,
                trimCrew: w.TRIM_CREW,
                riCrew: w.ROUGHIN_CREW,
                vgCrew: w.VG_CREW,
                diCrew: w.DROPIN_CREW,
                gasCrew: w.GAS_CREW,
                payTech: false,
                techRate: w.PRT === 'Y' ? 20 : 35,
                techAmount: 0,
                currentStatusDate: w.UDF_WOMS_STATUS_DATE,
                serviceCode: (w as any).UDF_SERVICE_CODE, // UDF_SERVICE_CODE Does not exist anymore!
                newStatusDate: '',
                payTrip: false,
                techTrip: w.PRT === 'Y' ? 25 : 35,
                subVendor: w.SubVendor,
                prtDetails: {
                  prt: w.PRT,
                  crew1: w.PRT_CREW_EMP1,
                  crew1Split: w.PRT_CREW_EMP1_SPLIT * 100,
                  crew2: w.PRT_CREW_EMP2,
                  crew2Split: w.PRT_CREW_EMP2_SPLIT * 100,
                  crewLead: w.PRT_CREW_LEAD,
                  crewLeadSplit: w.PRT_CREW_LEAD_SPLIT * 100,
                },
                punchImageKey: w.UDF_PUNCH_IMAGE_KEY,
                helper: w.Helper,
                jtWTStatus: w.StatusCode,
                payLineKey: '',
                itemCode: w.ItemCode,
                UDF_MGT_LAST_COMMENT: w.UDF_MGT_LAST_COMMENT,
                UDF_MGT_LAST_STATUS: w.UDF_MGT_LAST_STATUS,
                DIP: w.DIP,
              };
              this.dataModel.steps.push(obj);

              if (this.supers.length === 0) {
                this.supers.push(w.UDF_LEADMAN);
              } else if (!this.supers.includes(w.UDF_LEADMAN)) {
                this.supers.push(w.UDF_LEADMAN);
              }
            });
            this.supers.sort();

            const newStatuses: string[] = [];

            this.dataModel.steps.forEach(element => {
              if (!newStatuses.includes(element.jtWTStatus)) {
                newStatuses.push(element.jtWTStatus);
              }
            });
            this.ticketStatus = newStatuses;
          }
        },
        err => {
          console.error(err);
        },
        () => {
          this.setTableData();
          this.isLoading = false;
        }
      );
  }

  loadImagesS3(address: string, punchImageKey: string) {
    this.isLoading = true;
    const folder = address + '/scout';
    this.alertify.success('image Downloaded');
    this.isLoading = false;
  }

  onBlowUpImage(imgUrl: any[]) {
    this.imgUrl = imgUrl;
    this.imageModal.show();
  }

  loadPayLine(index) {
    const SalesOrderNo = this.dataModel.steps[index].soNum;
    const wtNumber = this.dataModel.steps[index].soWT;
    const workticket = this.dataModel.steps[index].wtNumber;

    this.currentpayLine = [];
    this.sageApi
      .pullReport('WOMs/Payline?workticket=' + workticket)
      .subscribe(lines => {
        if (Array.isArray(lines)) {
          lines.forEach(l => {
            const obj = {
              itemCode: l.ItemCode,
              LineKey: l.LineKey,
              price: l.UnitCost,
              payDate: l.UDF_PAY_DATE,
              technician: l.UDF_TECHNICIAN_CODE,
            };
            this.dataModel.steps[index].punchPayAmount = obj.price;
            this.dataModel.steps[index].newPunchPayAmount = obj.price;
            this.dataModel.steps[index].payLineKey = obj.LineKey;
            this.currentpayLine.push(obj);
          });
        }
      });
  }

  loadPayLineHist(SalesOrderNo: string) {
    this.payLines = [];
    this.payLinesToggle = !this.payLinesToggle;
    if (!this.payLinesToggle) {
      // used to hide the pay lines
      return;
    }

    this.sageApi.pullReport('PayLines/' + SalesOrderNo).subscribe(lines => {
      if (Array.isArray(lines)) {
        lines.forEach(l => {
          const obj = {
            itemCode: l.ItemCode,
            description: l.ItemCodeDesc,
            comment: l.UDF_WOMS_TYPE_COMMENT,
            price: l.UnitCost,
            payDate: l.UDF_PAY_DATE,
            approvedBy: l.UDF_MGT_APPROVED,
            technician: l.UDF_TECHNICIAN_CODE,
            techName: l.TechName,
            subVendor: l.UDF_SUB_VENDOR,
            prtLead: l.UDF_PRT_CREW_LEAD,
            prtLeadName: l.CrewLeadName,
            prtCrew1: l.UDF_PRT_CREW_EMP1,
            prtCrew2: l.UDF_PRT_CREW_EMP2,
          };
          this.payLines.push(obj);
        });
      }
    });
  }

  loadMapData() {
    this.mapData = [];
    this.sageApi.pullReport('MapPhases').subscribe(
      phases => {
        this.sageApi.pullReport('MapPunch').subscribe(
          punch => {
            if (Array.isArray(phases)) {
              phases.forEach(phase => {
                const obj = {
                  address: phase.ShipToName,
                  subdivision: phase.UDF_SUBDIVISION,
                  city: 'San Antonio',
                  builder: phase.BillToName,
                  phase: phase.ItemCode,
                  daysInProgress: phase.DIP,
                  leadman: phase.AssignedLeadman,
                  technician: phase.techName,
                  status: phase.StatusCode,
                  wt: phase.SalesOrderNo + '-' + phase.WTNumber,
                  scheduleDate: phase.UDF_DATE_SCHEDULED,
                };
                this.mapData.push(obj);
              });
            }

            if (Array.isArray(punch)) {
              punch.forEach(pun => {
                const obj = {
                  address: pun.ShipToName,
                  subdivision: pun.UDF_SUBDIVISION,
                  city: 'San Antonio',
                  builder: pun.BillToName,
                  phase: pun.HdrParentItemCode,
                  daysInProgress: pun.DIP,
                  leadman: pun.UDF_LEADMAN,
                  technician: pun.techName,
                  status: pun.StatusCode,
                  wt: pun.SalesOrderNo + '-' + pun.WTNumber,
                  scheduleDate: pun.PromiseDate,
                };
                this.mapData.push(obj);
              });
            }
          },
          err => {
            // console.log(err, 'Map Punch');
          },
          () => {
            this.excelService.exportAsExcelFile(this.mapData, 'sample');
          }
        );
      },
      err => {
        // console.log(err, 'Map Phases');
      },
      () => {}
    );
  }

  loadHistoryMaterial(index) {
    const [soNum, wtNum] = [
      this.dataModel.steps[index].soNum,
      this.dataModel.steps[index].soWT,
    ];
    this.dataModel.womsMaterial = [];
    this.sageApi
      .pullReport(`womsMaterial?soNum=${soNum}&wtNum=${wtNum}`)
      .subscribe(
        (wts: Array<any>) => {
          if (wts && Object.keys(wts).length < 1) {
            return;
          }
          if (Array.isArray(wts)) {
            wts.forEach(w => {
              const obj = {
                itemCode: w.ItemCode,
                itemDesc: w.ItemDesc,
                itemCost: w.itemCOST,
                quantity: w.QuantityOrdered,
                workTime: w.WorkTime,
              };
              this.dataModel.womsMaterial.push(obj);
            });
          }
        },
        err => {
          console.error(`Error in History Material: ${err}`);
        },
        () => {
          console.warn(`Material history successfully loaded!`);
        }
      );
  }

  loadHistoryLabor(index) {
    const [soNum, wtNum] = [
      this.dataModel.steps[index].soNum,
      this.dataModel.steps[index].soWT,
    ];
    this.dataModel.steps[index].womsLabor = [];
    this.sageApi
      .pullReport(`WOMs/CrewLabor?SalesOrderNo=${soNum}&WtNum=${wtNum}`)
      .subscribe(
        (wts: Array<any>) => {
          if (wts && Object.keys(wts).length < 1) {
            return;
          }
          if (Array.isArray(wts)) {
            wts.forEach(w => {
              const uc = parseFloat(w.unitCost).toFixed(2);
              const obj = {
                salesorderno: w.salesorderno,
                wtnumber: w.wtnumber,
                wtstep: w.wtstep,
                techNo: w.techNo,
                payDate: w.payDate,
                unitCost: uc.toString(),
                techName: w.techName,
                itemCode: w.itemCode,
              };
              this.dataModel.steps[index].womsLabor.push(obj);
            });
            console.log(this.dataModel.womsLabor);
          }
        },
        err => {
          console.error(`Error in History Labor: ${err}`);
        },
        () => {
          console.warn(`Labor history successfully loaded!`);
        }
      );
  }

  materialCostLoop(): void {
    // *Material Variables
    const prices: Array<any> = [];
    const quantaties: Array<any> = [];
    const totalItemCosts: Array<any> = [];
    // *Labor Variable
    let laborTime: number;
    let laborCost: number;
    let jobCost: number;

    // *Loops over items in womsMaterial and pushes item cost, item quantity, and workTime to resepective arrays
    this.dataModel.womsMaterial.forEach((item, index) => {
      prices.push(item.itemCost);
      quantaties.push(item.quantity);
      laborTime = item.workTime;
    });
    // *Maps the prices array, multiplying each price times its equivelent index in the quantity array, then pushes those values to
    // totalItemCosts where they get summed and put into grandTotalPrice variable

    prices.map((num, index) => {
      totalItemCosts.push(num * quantaties[index]);
      // *Checks if only 1 item exists in totalItemCosts array & returns that value if, if more than 1 item then the reduce
      // function executes
      if (totalItemCosts.length > 1) {
        totalItemCosts.reduce((a, b) => {
          return (this.totalMatCost = a + b);
        });
      } else {
        this.totalMatCost = totalItemCosts[0];
      }
      laborCost = (laborTime / 60) * 150;
      jobCost = laborCost + this.totalMatCost;
      this.totalJobCost = jobCost;
    });
  }

  loadtech() {
    this.isLoadingTech = true;
    this.dataModel = {
      customer: '',
      address: '',
      workPerformed: '',
      assignedTo: '',
      statusCode: '',
      steps: [],
      wts: [],
      subdivisions: [],
      orders: [],
      ordersLM: [],
      tracking: '',
      loaded: [],
      loadtech: [],
      loadstatuses: [],
      salesorder: '',
      statusCodes: '',
      techid: '',
      statuscomment: '',
      qaServiceCodes: '',
      newtracking: '',
      finaltracking: '',
      leadmanReport: [],
      redFlag: '',
      miscTech: [],
      uninstalledItems: ['loading'],
      materialChanges: [],
      kpiQA: [],
      kpiPunch: [],
      kpiOpenItems: [],
      serviceCodes: [],
      womsMaterial: [],
      womsLabor: [],
      ListLeadman: [],
      listWomsStatus: [],
      womsHistory: [],
      qaItemsForPunch: [],
    };
    const nameSearch = '';
    const techNames = this.sageApi.pullReport('Punch/GetTechs').subscribe(
      (names: Array<any>) => {
        this.dataModel.loadtech = [];

        if (Array.isArray(names)) {
          names.forEach(name => {
            const obj = {
              fullTechCo: name.TechnicianDeptNo + name.TechnicianNo,
              tech: name.TechnicianNo,
              firstName: name.FirstName,
              lastName: name.LastName,
              deptno: name.TechnicianDeptNo,
              techCode: name.TechnicianDeptNo + name.TechnicianNo,
              subVendor: name.UDF_SUB_VENDOR,
              prt: name.UDF_PRT,
            };
            if (!(obj.prt === 'N' && obj.subVendor === '')) {
              this.dataModel.loadtech.push(obj);
            }
          });
        }
      },
      err => {
        console.log(err);
        this.isLoading = false;
        this.isLoadingTech = false;
        this.loadingStatus = 'Failed to load Techs';
      },
      () => {
        this.techLoaded = true;
        this.isLoadingTech = false;
      }
    );
  }

  onTechSelected(techCode: string, num: string, index: string) {
    const splitTech = techCode.split(' - ');
    const ind = this.dataModel.loadtech.findIndex(
      x => x.fullTechCo.toLocaleLowerCase() === splitTech[0].toLocaleLowerCase()
    );
    if (num === '1') {
      this.dataModel.steps[index].crew1 = splitTech[0];
      this.dataModel.steps[index].crewPay1.prt = this.dataModel.loadtech[
        ind
      ].prt;
      this.dataModel.steps[index].crewPay1.subVendor = this.dataModel.loadtech[
        ind
      ].subVendor;
    } else if (num === '2') {
      this.dataModel.steps[index].crew2 = splitTech[0];
      this.dataModel.steps[index].crewPay2.prt = this.dataModel.loadtech[
        ind
      ].prt;
      this.dataModel.steps[index].crewPay2.subVendor = this.dataModel.loadtech[
        ind
      ].subVendor;
    } else if (num === '3') {
      this.dataModel.steps[index].crew3 = splitTech[0];
      this.dataModel.steps[index].crewPay3.prt = this.dataModel.loadtech[
        ind
      ].prt;
      this.dataModel.steps[index].crewPay3.subVendor = this.dataModel.loadtech[
        ind
      ].subVendor;
    }
  }

  onPRTSelected(techCode: string, num: string, index: string) {
    const splitTech = techCode.split(' - ');
    if (num === '1') {
      this.dataModel.steps[index].prtDetails.Lead = splitTech[0];
    } else if (num === '2') {
      this.dataModel.steps[index].prtDetails.crew1 = splitTech[0];
    } else if (num === '3') {
      this.dataModel.steps[index].prtDetails.crew2 = splitTech[0];
    }
  }

  editClick(ticketIndex: string) {
    this.loadHistoryMaterial(ticketIndex);
    this.loadHistoryLabor(ticketIndex);
    console.log(this.dataModel.steps[ticketIndex]);
    this.actionEdit(ticketIndex.toString());
    if (
      this.dataModel.steps[ticketIndex].currentStatus !== 'REVBC' &&
      this.dataModel.steps[ticketIndex].currentStatus !== 'PBCK' &&
      this.dataModel.steps[ticketIndex].currentStatus !== 'PDISC'
    ) {
      this.openModal(this.editModal);
    } else {
      this.openModal(this.BackchargeModal);
    }
  }

  filterLoadtech(fstr: string): any[] {
    const lcfstr = fstr.toLowerCase();
    if (lcfstr == '') {
      return this.dataModel.loadtech;
    }
    const filteredResults = [];
    for (let i = 0; i < this.dataModel.loadtech.length; i++) {
      const searchableStr = `${this.dataModel.loadtech[i].techCode} - ${this.dataModel.loadtech[i].firstName} ${this.dataModel.loadtech[i].lastName}`.toLowerCase();
      if (searchableStr.indexOf(lcfstr) > -1) {
        filteredResults.push(this.dataModel.loadtech[i]);
      }
    }
    return filteredResults;
  }
}
