import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SageApiService } from '../sageApi/sageApi.service';
import { QuoteEditableService } from '../QuoteEditableService/QuoteEditableService';
import QuoteKitPartEditableInterface from '../QuoteEditableService/interfaces/QuoteKitPartEditableInterface';
import { debounceTime } from 'rxjs/operators';

/*
  All these interfaces will eventually live in their own files, but for now they are here because I am a lazy fuck!
*/

export interface QuoteKitPartFilterServiceFilterReturnInterface {
  newFiltered: string[];
  newFilteredWithKits: string[];
  newFilteredWithKitsWithChildren: string[];
}

type QuoteKitPartFilterServiceFilterReturnType = QuoteKitPartFilterServiceFilterReturnInterface | null;

@Injectable({
  providedIn: 'root',
})
export class QuoteKitPartFilterService {
  /*
   * You may be wondering why there are three diff filters. The idea is that in the future
   * each filter can be applied seperately with a select. For now, the only one that really
   * works is the filteredWithKits filter. If you get the chance please add the ability to
   * switch between filters easily.
   */
  filtered: string[] | null = null; // Just the QuoteKitPart guids with matching names
  filteredWithKits: string[] | null = null; // Also has the parent matching QuoteKit guids
  filteredWithChildren: string[] | null = null; // If the QuoteKit_Name matches, then this includes the children guids

  public updated = new Subject<void>();
  private filterSubject = new Subject<string | null>();

  constructor(public api: SageApiService, private qe: QuoteEditableService) {
    this.filterSubject
      .pipe(debounceTime(750))
      .subscribe((fstr: string | null) => {
        this.filter(fstr);
      });
  }

  filter(fstr: string | null): QuoteKitPartFilterServiceFilterReturnType {
    if (this.qe != null && fstr != null && fstr.trim().length > 0) {
      const fltr = (
        children: QuoteKitPartEditableInterface[],
        includeChildrenOutOfRespec?: boolean
      ) => {
        let newFiltered = [];
        let newFilteredWithKits = [];
        let newFilteredWithKitsWithChildren = [];
        for (const child of children) {
          if (child.data.QuoteKit != null && child?.children != null) {
            let incl = includeChildrenOutOfRespec == true ? true : false;
            if (
              incl ||
              child.data.QuoteKit.QuoteKit.QuoteKit_Name.toLowerCase().includes(
                fstr.toLowerCase()
              )
            ) {
              newFiltered.push(child.data.QuoteKitPart.QuoteKitPart_guid);
              newFilteredWithKits.push(
                child.data.QuoteKitPart.QuoteKitPart_guid
              );
              child.expanded = true;
              newFilteredWithKitsWithChildren.push(
                child.data.QuoteKitPart.QuoteKitPart_guid
              );
              incl = true;
            }

            const toadd = fltr(child.children, incl);
            if (toadd != null) {
              newFiltered = [...newFiltered, ...toadd.newFiltered];
              newFilteredWithKits = [
                ...newFilteredWithKits,
                ...toadd.newFilteredWithKits,
              ];
              if (toadd.newFilteredWithKits.length > 0) {
                newFilteredWithKits.push(
                  child.data.QuoteKitPart.QuoteKitPart_guid
                );
                child.expanded = true;
              }
            }
            newFilteredWithKitsWithChildren = [
              ...newFilteredWithKitsWithChildren,
              ...toadd.newFilteredWithKitsWithChildren,
            ];
          } else if (child.data.QuotePart != null) {
            if (
              includeChildrenOutOfRespec ||
              child.data.QuotePart.QuotePart.QuotePart_Code.toLowerCase().includes(
                fstr.toLowerCase()
              )
            ) {
              newFiltered.push(child.data.QuoteKitPart.QuoteKitPart_guid);
              newFilteredWithKits.push(
                child.data.QuoteKitPart.QuoteKitPart_guid
              );
              newFilteredWithKitsWithChildren.push(
                child.data.QuoteKitPart.QuoteKitPart_guid
              );
            }
          }
        }
        return {
          newFiltered,
          newFilteredWithKits,
          newFilteredWithKitsWithChildren,
        };
      };
      const fltrd = fltr(this.qe.quote.children);
      this.filtered = fltrd.newFiltered;
      this.filteredWithKits = fltrd.newFilteredWithKits;
      this.filteredWithChildren = fltrd.newFilteredWithKitsWithChildren;
      this.updated.next();
      this.qe.updated.next();
      return fltrd;
    }
    this.filtered = null;
    this.filteredWithKits = null;
    this.filteredWithChildren = null;
    this.updated.next();
    return null;
  }

  debouncedFilter(fstr: string | null): void {
    this.filterSubject.next(fstr);
  }
}
