<div cdkDropListGroup>
  <div class="card border-0 jumbotron p-0">
    <ng-container *ngIf="loadingTechs == 'loading'">
      <div class="schtkts-techs-loading">
        <mat-spinner></mat-spinner>
      </div>
    </ng-container>

    <div
      class="card-header"
      *ngIf="
        authService.decodedToken.role !== 'super' &&
        authService.decodedToken.role !== 'superdfw' &&
        loadingTechs == 'loaded'
      "
    >
      <mat-form-field appearance="outline">
        <mat-label>Select Technician</mat-label>
        <mat-select [disabled]="loadingTickets == 'loading'">
          <input
            matInput
            [formControl]="superSearchCtrl"
            (input)="onSuperSearchChange($event.target.value)"
            (keydown)="$event.stopPropagation()"
            placeholder="Search Technician"
            class="schtkts-techs-sel-in"
          />
          <mat-option
            *ngFor="let super of supersList; let superIndex = index"
            (click)="onSuperSelected(super.techCode, superIndex)"
            [value]="super.techName"
            [class.hidden]="isThisOptionHidden(super.techName)"
            class="schtkts-techs-sel-op"
          >
            {{ super.techName.trim() != '' ? super.techName : 'Empty' }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div
      class="card-header"
      *ngIf="authService.decodedToken.role == 'superdfw'"
    >
      <div class="row">
        <div class="col-sm-3">
          <div class="card m-1">
            <div class="btn-group" dropdown>
              <button
                dropdownToggle
                type="button"
                class="btn btn-outline-dark dropdown-toggle"
              >
                {{ currentSuper }} Selected <span class="caret"></span>
              </button>
              <ul *dropdownMenu class="dropdown-menu" role="menu">
                <li
                  role="menuitem"
                  *ngFor="let super of supersList; let supersIndex = index"
                  [ngStyle]="{
                    display:
                      super.name == '' ||
                      !(
                        super.name !== undefined &&
                        super.name.toLocaleLowerCase().includes('dfw')
                      )
                        ? 'none'
                        : ''
                  }"
                >
                  <a
                    class="dropdown-item"
                    (click)="onSuperSelected(super.techCode, supersIndex)"
                  >
                    <span>{{ super.name }}</span
                    ><i
                      *ngIf="super.selected"
                      class="fas fa-check fa-border fa-pull-right"
                    ></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="loadingTickets == 'loading'">
      <div class="schtkts-tickets-loading">
        <mat-spinner></mat-spinner>
      </div>
    </ng-container>

    <ng-container *ngIf="loadingTickets == 'loaded'">
      <div class="card-body border-0">
        <div id="dragDropArea" class="row">
          <div class="container col-12 col-sm-3 pl-0">
            <div>
              <div class="card m-3 bg-primary">
                <div class="card-header">
                  <div class="row">
                    <div class="col-12 text-center text-white">
                      <strong>{{ currentSuper }} Tickets</strong>
                    </div>
                  </div>
                </div>
                <ul
                  class="list-group list-group-flush"
                  cdkDropList
                  cdkDropListOrientation="vertical"
                  [cdkDropListData]="
                    supersList[currentSuperIndex].tickets.unsorted?.punch
                  "
                  (cdkDropListDropped)="
                    drop($event, 'unsorted', currentSuperIndex)
                  "
                >
                  <ng-container
                    *ngFor="
                      let item of supersList[currentSuperIndex].tickets.unsorted
                        ?.punch;
                      let ticketIndex = index;
                      let c = count;
                      let isFirst = first
                    "
                  >
                    <li
                      class="list-group-item col-sm-12 border-0 p-0 text-sm text-center"
                      *ngIf="isFirst"
                    >
                      Total Tickets {{ c }}
                    </li>
                    <li
                      class="list-group-item"
                      cdkDrag
                      [cdkDragDisabled]="[item.disabled]"
                    >
                      <ul class="list-group list-group-horizontal">
                        <li
                          class="list-group-item col-6 border-0 p-0 text-sm font-weight-bold mt-auto"
                        >
                          {{ item.phase | titlecase }}
                          <span
                            class="badge badge-primary badge-pill"
                            *ngIf="!item.disabled"
                            >{{ item.dip }}</span
                          ><span *ngIf="item.disabled && !item.updated"
                            ><i class="fas fa-cog fa-spin"></i
                          ></span>
                        </li>
                        <li
                          class="list-group-item col-6 border-0 p-0 text-sm text-right"
                        >
                          <button
                            mat-icon-button
                            (click)="
                              onEditPunchTicket(
                                currentSuperIndex,
                                'unsorted',
                                ticketIndex
                              )
                            "
                            [disabled]="item.disabled"
                          >
                            <mat-icon>edit</mat-icon>
                          </button>
                        </li>
                      </ul>
                      <ul class="list-group">
                        <li class="list-group-item border-0 p-0 text-sm">
                          {{ item.address }}
                        </li>
                        <li class="list-group-item border-0 p-0 text-sm">
                          {{ item.subdivision | titlecase }}
                        </li>
                        <li class="list-group-item border-0 p-0 text-sm">
                          {{ item.status }}
                        </li>
                      </ul>
                      <ul class="list-group list-group-horizontal-sm">
                        <li
                          class="list-group-item col-sm-12 border-0 p-0 text-sm font-weight-bold"
                        >
                          {{ item.description }}
                        </li>
                      </ul>
                    </li>
                  </ng-container>
                  <li class="list-group-item text-center"></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-3 pl-0">
            <div class="card mt-3 border-dark">
              <div class="card-header border-dark">
                <div class="col-12 text-center pl-0">
                  <a>
                    <i
                      (click)="calculateNextDays('backwards')"
                      class="fas fa-chevron-left fa-border fa-pull-left border-0 schtkts-tickets-hdr-sel"
                    ></i>
                    {{ colOneDate | date: 'EEE MMM, d, y' }}
                  </a>
                </div>
              </div>
              <ul
                class="list-group list-group-flush"
                cdkDropList
                cdkDropListOrientation="vertical"
                [cdkDropListData]="
                  supersList[currentSuperIndex].tickets[colOneDate]?.punch
                "
                (cdkDropListDropped)="
                  drop($event, colOneDate, currentSuperIndex)
                "
              >
                <ng-container
                  *ngFor="
                    let item of supersList[currentSuperIndex].tickets[
                      colOneDate
                    ]?.punch;
                    let ticketIndex = index;
                    let c = count;
                    let isFirst = first
                  "
                >
                  <li
                    class="list-group-item col-sm-12 border-0 p-0 text-sm text-center"
                    *ngIf="isFirst"
                  >
                    <strong>{{ c }}</strong> Tickets
                  </li>
                  <li
                    class="list-group-item"
                    cdkDrag
                    [cdkDragDisabled]="[item.disabled]"
                  >
                    <ul class="list-group list-group-horizontal">
                      <li
                        class="list-group-item col-6 border-0 p-0 text-sm font-weight-bold mt-auto"
                      >
                        #{{ item.superOrder + 1 }} {{ item.phase | titlecase }}
                        <span
                          class="badge badge-primary badge-pill"
                          *ngIf="!item.disabled"
                          >{{ item.dip }}</span
                        ><span *ngIf="item.disabled && !item.updated"
                          ><i class="fas fa-cog fa-spin"></i
                        ></span>
                      </li>
                      <li
                        class="list-group-item col-6 border-0 p-0 text-sm text-right"
                      >
                        <button
                          mat-icon-button
                          (click)="
                            onEditPunchTicket(
                              currentSuperIndex,
                              colOneDate,
                              ticketIndex
                            )
                          "
                          [disabled]="item.disabled"
                        >
                          <mat-icon>edit</mat-icon>
                        </button>
                      </li>
                    </ul>
                    <ul class="list-group">
                      <li class="list-group-item border-0 p-0 text-sm">
                        {{ item.address }}
                      </li>
                      <li class="list-group-item border-0 p-0 text-sm">
                        {{ item.subdivision | titlecase }}
                      </li>
                      <li class="list-group-item border-0 p-0 text-sm">
                        {{ item.status }}
                      </li>
                    </ul>
                    <ul class="list-group list-group-horizontal-sm">
                      <li
                        class="list-group-item col-sm-12 border-0 p-0 text-sm font-weight-bold"
                      >
                        {{ item.description }}
                      </li>
                    </ul>
                  </li>
                </ng-container>
                <li class="list-group-item text-center"></li>
              </ul>
            </div>
          </div>
          <div class="col-12 col-sm-3 pl-0">
            <div class="card mt-3 border-dark">
              <div class="card-header border-dark">
                <div class="col-12 text-center">
                  {{ colTwoDate | date: 'EEE MMM, d, y' }}
                </div>
              </div>
              <ul
                class="list-group list-group-flush"
                cdkDropList
                cdkDropListOrientation="vertical"
                [cdkDropListData]="
                  supersList[currentSuperIndex].tickets[colTwoDate]?.punch
                "
                (cdkDropListDropped)="
                  drop($event, colTwoDate, currentSuperIndex)
                "
              >
                <ng-container
                  *ngFor="
                    let item of supersList[currentSuperIndex].tickets[
                      colTwoDate
                    ]?.punch;
                    let ticketIndex = index;
                    let c = count;
                    let isFirst = first
                  "
                >
                  <li
                    class="list-group-item col-sm-12 border-0 p-0 text-sm text-center"
                    *ngIf="isFirst"
                  >
                    <strong>{{ c }}</strong> Tickets
                  </li>
                  <li
                    class="list-group-item"
                    cdkDrag
                    [cdkDragDisabled]="[item.disabled]"
                  >
                    <ul class="list-group list-group-horizontal">
                      <li
                        class="list-group-item col-6 border-0 p-0 text-sm font-weight-bold mt-auto"
                      >
                        #{{ item.superOrder + 1 }} {{ item.phase | titlecase }}
                        <span
                          class="badge badge-primary badge-pill"
                          *ngIf="!item.disabled"
                          >{{ item.dip }}</span
                        ><span *ngIf="item.disabled && !item.updated"
                          ><i class="fas fa-cog fa-spin"></i
                        ></span>
                      </li>
                      <li
                        class="list-group-item col-6 border-0 p-0 text-sm text-right"
                      >
                        <button
                          mat-icon-button
                          (click)="
                            onEditPunchTicket(
                              currentSuperIndex,
                              colTwoDate,
                              ticketIndex
                            )
                          "
                          [disabled]="item.disabled"
                        >
                          <mat-icon>edit</mat-icon>
                        </button>
                      </li>
                    </ul>
                    <ul class="list-group">
                      <li class="list-group-item border-0 p-0 text-sm">
                        {{ item.address }}
                      </li>
                      <li class="list-group-item border-0 p-0 text-sm">
                        {{ item.subdivision | titlecase }}
                      </li>
                      <li class="list-group-item border-0 p-0 text-sm">
                        {{ item.status }}
                      </li>
                    </ul>
                    <ul class="list-group list-group-horizontal-sm">
                      <li
                        class="list-group-item col-sm-12 border-0 p-0 text-sm font-weight-bold"
                      >
                        {{ item.description }}
                      </li>
                    </ul>
                  </li>
                </ng-container>
                <li class="list-group-item text-center"></li>
              </ul>
            </div>
          </div>
          <div class="col-12 col-sm-3 pl-0">
            <div class="card mt-3 border-dark">
              <div class="card-header border-dark">
                <div class="col-12 text-center pr-0">
                  <a>
                    {{ colThreeDate | date: 'EEE MMM, d, y' }}
                    <i
                      (click)="calculateNextDays('forward')"
                      class="fas fa-chevron-right fa-border fa-pull-right border-0 schtkts-tickets-hdr-sel"
                    ></i>
                  </a>
                </div>
              </div>
              <ul
                class="list-group list-group-flush"
                cdkDropList
                cdkDropListOrientation="vertical"
                [cdkDropListData]="
                  supersList[currentSuperIndex].tickets[colThreeDate]?.punch
                "
                (cdkDropListDropped)="
                  drop($event, colThreeDate, currentSuperIndex)
                "
              >
                <ng-container
                  *ngFor="
                    let item of supersList[currentSuperIndex].tickets[
                      colThreeDate
                    ]?.punch;
                    let ticketIndex = index;
                    let c = count;
                    let isFirst = first
                  "
                >
                  <li
                    class="list-group-item col-sm-12 border-0 p-0 text-sm text-center"
                    *ngIf="isFirst"
                  >
                    <strong>{{ c }}</strong> Tickets
                  </li>
                  <li
                    class="list-group-item"
                    cdkDrag
                    [cdkDragDisabled]="[item.disabled]"
                  >
                    <ul class="list-group list-group-horizontal">
                      <li
                        class="list-group-item col-6 border-0 p-0 text-sm font-weight-bold mt-auto"
                      >
                        #{{ item.superOrder + 1 }} {{ item.phase | titlecase }}
                        <span
                          class="badge badge-primary badge-pill"
                          *ngIf="!item.disabled"
                          >{{ item.dip }}</span
                        ><span *ngIf="item.disabled && !item.updated"
                          ><i class="fas fa-cog fa-spin"></i
                        ></span>
                      </li>
                      <li
                        class="list-group-item col-6 border-0 p-0 text-sm text-right"
                      >
                        <button
                          mat-icon-button
                          (click)="
                            onEditPunchTicket(
                              currentSuperIndex,
                              colThreeDate,
                              ticketIndex
                            )
                          "
                          [disabled]="item.disabled"
                        >
                          <mat-icon>edit</mat-icon>
                        </button>
                      </li>
                    </ul>
                    <ul class="list-group">
                      <li class="list-group-item border-0 p-0 text-sm">
                        {{ item.address }}
                      </li>
                      <li class="list-group-item border-0 p-0 text-sm">
                        {{ item.subdivision | titlecase }}
                      </li>
                      <li class="list-group-item border-0 p-0 text-sm">
                        {{ item.status }}
                      </li>
                    </ul>
                    <ul class="list-group list-group-horizontal-sm">
                      <li
                        class="list-group-item col-sm-12 border-0 p-0 text-sm font-weight-bold"
                      >
                        {{ item.description }}
                      </li>
                    </ul>
                  </li>
                </ng-container>
                <li class="list-group-item text-center"></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
