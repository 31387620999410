<div class="container pb-5 pnchatch">
  <div class="card my-5 shadow-lg pnchatch-ctn">
    <div class="card-body pnchatch-ctn-card">
      <div class="pnchatch-ctn-card-ctrls">
        <mat-form-field appearance="outline">
          <mat-label>Filter</mat-label>
          <input
            matInput
            [(ngModel)]="filter"
            (keyup)="applyFilter($event)"
            placeholder="Gas"
            #input
          />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Assignable</mat-label>
          <mat-select (selectionChange)="onSelectAssignable($event)">
            <mat-option
              *ngFor="let filter of filterAssignable"
              [value]="filter"
            >
              {{ filter }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        class="mat-elevation-z8 pnchatch-ctn-card-tbl"
      >
        <ng-container matColumnDef="TechKey">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>TechKey</th>
          <td mat-cell *matCellDef="let element">{{ element.TechKey }}</td>
        </ng-container>

        <ng-container matColumnDef="Name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let element">{{ element.Name }}</td>
        </ng-container>

        <ng-container matColumnDef="Assignable">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Assignable</th>
          <td mat-cell *matCellDef="let element">{{ element.Assignable }}</td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="pnchatch-actn-hdr">
            Action
          </th>
          <td mat-cell *matCellDef="let element" class="pnchatch-actn-col">
            <button
              mat-button
              mat-flat-button
              class="bg-primary text-light"
              (click)="openDialog(element.TechKey)"
            >
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator
        [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons
        aria-label="Select page of periodic elements"
      >
      </mat-paginator>
    </div>
  </div>
</div>
