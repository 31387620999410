import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { forkJoin, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { WasabiApiService } from 'src/app/_services/wasabiApi/wasabiApi.service';

interface DialogData {
  title: string;
  upload: string;
  download: string;
  uploadType: 'all' | 'atATime';
}

@Component({
  selector: 'app-upload-modal',
  templateUrl: './upload-modal.component.html',
  styleUrls: ['./upload-modal.component.css'],
})
export class UploadModalComponent {
  fileArray = [];
  fileLoading: boolean;
  add_attachment: boolean;

  loading: boolean;
  allFiles = [];

  constructor(
    public dialogRef: MatDialogRef<UploadModalComponent>,
    public wasabi: WasabiApiService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    document.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        e.stopPropagation();
        e.stopImmediatePropagation();
      }
    });
  }

  ngOnInit() {
    this.viewAttachments();
  }

  ok() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }

  getFileName(file: string) {
    decodeURIComponent(file);
    if (file.includes(this.data.upload)) {
      let name = file.replace(this.data.upload, '');
      name = decodeURIComponent(name);
      return name;
    } else {
      return file;
    }
  }

  async viewAttachments() {
    this.loading = true;
    const objs = await this.wasabi.getFiles(this.data.download);
    this.allFiles = objs;
    this.loading = false;
  }

  fileUpload() {
    this.fileLoading = true;
    const sentFiles = [];

    this.fileArray.forEach(file => {
      sentFiles.push(file.data);
    });

    if (this.data.uploadType == undefined || this.data.uploadType === 'all') {
      this.wasabi.upload(this.data.upload, sentFiles).subscribe(
        res => {
          this.dialogRef.close();
          this.snackBar.open('Uploaded Successfully!', 'Close', {
            duration: 5000,
          });
        },
        err => {
          this.snackBar.open('Error uploading files', 'Close');
          this.fileLoading = false;
        }
      );
    } else if (this.data.uploadType === 'atATime') {
      // Run that for this.wasabi.upload for each file
      const obs = [];
      for (const file of sentFiles) {
        obs.push(
          this.wasabi.upload(this.data.upload, [file]).pipe(shareReplay())
        );
      }
      // now see if any of them failed, and if so, show an error
      let failed = false;
      for (const ob of obs) {
        ob.subscribe(
          res => {
            // ToDo: Nothing, we just want the error to be caught
          },
          err => {
            this.fileLoading = false;
            failed = true;
          }
        );
      }
      // Now listen to all the observables and show the error message when they all complete
      forkJoin(obs).subscribe(() => {
        if (failed) {
          this.snackBar.open('Error uploading files', 'Close');
          this.fileLoading = false;
        } else {
          this.dialogRef.close();
          this.snackBar.open('Uploaded Successfully!', 'Close', {
            duration: 5000,
          });
        }
      });
    }
  }

  fileChosen(event) {
    const files = event.target.files;
    for (const index in files) {
      if (files[index] instanceof File) {
        const obj = {
          name: files[index].name,
          data: files[index],
        };

        this.fileArray.push(obj);
        // for now a single item per upload
      }
    }
  }

  uriToFileName(uri: string) {
    return decodeURIComponent(uri.substring(uri.lastIndexOf('/') + 1));
  }

  removeFileFromToUpload(fileIndex: number) {
    this.fileArray.splice(fileIndex, 1);
  }
}
