<div
  [class]="'botsheet-bg ' + (open ? 'active' : '')"
  (click)="closeSheet()"
></div>

<mat-card
  swipeable
  (swiped)="cardSwiped($event)"
  [ngClass]="paperClass"
  [class]="'botsheet ' + (open ? 'active' : '')"
>
  <ng-content></ng-content>
</mat-card>
