<app-data-table
  [rawData]="allParts"
  [tableColumns]="
    [
      {col: 'PartCode', value: 'PartCode', title: 'PartCode', size: 'md'},
      {col: 'Quantity', value: 'Quantity', title: 'Quantity', size: 'sm'},
      {col: 'IndividualQuantity', value: 'IndividualQuantity', title: 'IndivQty', size: 'sm', includeColumnByDefault: false},
      {col: 'Cost', value: normalizeMoney, title: 'Cost', size: 'sm'},
      {col: 'TotalCost', value: normalizeMoney, title: 'TotalCost', size: 'sm'},
      {col: 'Phase', value: 'Phase', title: 'Phase', size: 'md'},
      {col: 'Kit', value: 'Kit', title: 'Kit', size: 'lg'},
      {col: 'KitParents', value: 'KitParents', title: 'KitParents', size: 'xl', includeColumnByDefault: false},
      {col: 'Desc', value: 'Desc', title: 'Desc', size: 'lg', includeColumnByDefault: false},
      {col: 'Tags', value: 'Tags', title: 'Tags', size: 'md'},
      {col: 'Location', value: 'Location', title: 'Location', size: 'md'},
    ]"
  [inColumns]="
    [
      {col: 'PartCode',  title: 'PartCode'},
      {col: 'Kit', title: 'Kit'},
      {col: 'Location', title: 'Location'},
    ]"
  [mselColumns]="
    [
      {col: 'Phase', title: 'Phase'},
      {col: 'Tags', title: 'Tags'},
    ]"
  [includeColumnSelector]="true"
  [searchOnChange]="true"
  [accordianControls]="true"
  [includePdfDownload]="true"
  accordianScreenSize="smd"
  title="Parts"
  [pdfTitle]="pdfTitle"
  (filterChanged)="partsFiltered($event)"
>
  <ng-container table-footer>
    <div class="qdslcr-parts-ftr">
      <span class="qdslcr-parts-ftr-cnt qdslcr-parts-ftr-itm">
        <span class="qdslcr-parts-ftr-itm-ttl">Count:&nbsp;</span>
        <span class="qdslcr-parts-ftr-itm-ctnt">{{ filteredPartsCount }}</span>
      </span>
      <span
        matTooltip="Cost before taxes and margin - raw cost of all parts"
        class="qdslcr-parts-ftr-cst qdslcr-parts-ftr-itm"
      >
        <span class="qdslcr-parts-ftr-itm-ttl">Raw Cost:&nbsp;</span>
        <span class="qdslcr-parts-ftr-itm-ctnt">
          {{ filteredRawPartsCost | currency }}
        </span>
      </span>
      <span class="qdslcr-parts-ftr-cst qdslcr-parts-ftr-itm">
        <span class="qdslcr-parts-ftr-itm-ttl">Final Cost:&nbsp;</span>
        <span class="qdslcr-parts-ftr-itm-ctnt">
          {{ filteredPartsCost | currency }}
        </span>
      </span>
    </div>
  </ng-container>
</app-data-table>

<!-- 
finalQuantity: number; // The total number of parts in the kit
finalCost: number; // The cost of the kit with all its parts * the quantity
cost: number; // The cost of the kit with all its parts
parents: string[]; // The parent kits of this kit
QuoteKit_Name: string;
QuoteKit_Desc: string;
QuoteKit_BuildLocation: string; 
-->

<app-data-table
  [rawData]="kitInfo"
  [tableColumns]="
    [
      {col: 'QuoteKit_Name', value: 'QuoteKit_Name', title: 'Kit Name', size: 'md'},
      {col: 'quantity', value: 'quantity', title: 'Base Qty', size: 'sm'},
      {col: 'finalQuantity', value: 'finalQuantity', title: 'Total Qty', size: 'sm'},
      {col: 'cost', value: normalizeMoney, title: 'Base Cost', size: 'sm'},
      {col: 'finalCost', value: normalizeMoney, title: 'Cost', size: 'sm'},
      {col: 'QuoteKit_BuildLocation', value: 'QuoteKit_BuildLocation', title: 'Location', size: 'md'},
      {col: 'parents', value: 'parents', title: 'Parents', size: 'xl'},
      {col: 'QuoteKit_Desc', value: 'QuoteKit_Desc', title: 'Description', size: 'lg', includeColumnByDefault: false},
    ]"
  [inColumns]="
    [
      {col: 'QuoteKit_Name',  title: 'Kit Name'},
      {col: 'QuoteKit_BuildLocation', title: 'Location'},
      {col: 'QuoteKit_Desc', title: 'Description'},
      {col: 'parents', title: 'Parents'},
    ]"
  [includeColumnSelector]="true"
  [searchOnChange]="true"
  [accordianControls]="true"
  [includePdfDownload]="true"
  accordianScreenSize="smd"
  title="Kits"
  [pdfTitle]="pdfTitle"
></app-data-table>
