import {
  AfterViewInit,
  Component,
  Inject,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AlertifyService } from '../../../_services/alertify/alertify.service';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import { AuthService } from '../../../_services/auth/auth.service';
import { concat } from 'rxjs';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';

export interface DialogData {
  techDeptNo: '';
  techNo: '';
  username: '';
}
export interface TechData {
  Name: string;
  TechKey: string;
  Assignable: string;
}

@Component({
  selector: 'app-punch-assignable-techs',
  templateUrl: './punch-assignable-techs.component.html',
  styleUrls: ['./punch-assignable-techs.component.css'],
})
export class PunchAssignableTechsComponent implements OnInit, AfterViewInit {
  filter = '';

  techListLoading = false;
  techSearch = '';
  techList: {
    firstName: string;
    lastName: string;
    techDeptNo: string;
    techNo: string;
    assignable: boolean;
    updating: boolean;
  }[] = [];
  dataSource: MatTableDataSource<TechData> | null;
  assignableStatusList = [];
  assignableStatusSelected = [];
  displayedColumns: string[] = ['TechKey', 'Name', 'Assignable', 'action'];
  loaded = false;
  filterAssignable: string[] = ['Yes', 'No', 'None'];
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private alertify: AlertifyService,
    private sageApi: SageApiService,
    private authService: AuthService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.loadTechList();
  }
  ngAfterViewInit() {
    if (this.loaded == true) {
      this.dataSource.sort = this.sort;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onSelectAssignable(e: any) {
    if (e.value == 'None') {
      e.value = '';
    }
    const filterValue = e.value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  loadTechList() {
    this.techList = [];
    this.techListLoading = true;
    this.sageApi.pullReport('AssignableForPunch').subscribe(
      (techs: Array<any>) => {
        if (Array.isArray(techs)) {
          techs.forEach(tech => {
            const obj = {
              firstName: tech.FirstName,
              lastName: tech.LastName,
              techDeptNo: tech.TechnicianDeptNo,
              techNo: tech.TechnicianNo,
              assignable: tech.UDF_ASSIGNABLE_PUNCH === 'Y' ? true : false,
              updating: false,
            };
            this.techList.push(obj);

            if (
              !this.assignableStatusList.some(
                status =>
                  status.name === (obj.assignable ? 'Enabled' : 'Disabled')
              )
            ) {
              this.assignableStatusList.push({
                name: obj.assignable ? 'Enabled' : 'Disabled',
                selected: false,
              });
            }
          });
        }
      },
      err => {
        console.log(err);
        this.techListLoading = false;
        this.alertify.error(err.message);
      },
      () => {
        this.techListLoading = false;
        this.assignToDataTable();
      }
    );
  }

  assignToDataTable() {
    const data: TechData[] = [];
    this.techList.forEach(element => {
      const assignable = element.assignable == true ? 'Yes' : 'No';
      const idea: TechData = {
        Name: element.firstName + ' ' + element.lastName,
        TechKey: element.techDeptNo + element.techNo,
        Assignable: assignable,
      };
      data.push(idea);
    });
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.loaded = true;
  }

  openDialog(information) {
    const obj = this.techList.find(x => x.techDeptNo + x.techNo == information);
    const dialogRef = this.dialog.open(EditTechDialog, {
      data: {
        techDeptNo: obj.techDeptNo,
        techNo: obj.techNo,
        username: this.authService.decodedToken?.nameid.toLocaleLowerCase(),
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == undefined) {
        //
      } else if (result == 'Success') {
        this.alertify.success('Success editing tech!');
        this.refreshData();
      } else {
        this.alertify.warning('Error editing tech.');
        this.refreshData();
      }
    });
  }
  refreshData() {
    this.loadTechList();
    this.filter = '';
  }
}
@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'tech-edit.html',
  styleUrls: ['./punch-assignable-techs.component.css'],
})
export class EditTechDialog {
  sending = false;
  techInfo = {
    assignablePunch: false,
    FirstName: '',
    LastName: '',
    SubVendor: '',
    ScheduleGroup: '',
    email: '',
    currentStatus: '',
    defaultWarehouse: '',
    PRT: false,
    PRTCrewLead: '',
    PRTCrewLeadSplit: 0,
    PRTCrew1: '',
    PRTCrew1Split: 0,
    PRTCrew2: '',
    PRTCrew2Split: 0,
    techDeptNo: '',
    techNo: '',
    username: this.data.username,
  };

  defaultWarehouse = ['001', '005', ''];
  toolTip = '';
  scheduleGroup = [];
  vendorInformation = [];
  Super = '';
  city = '';
  subdivison = '';
  gas = false;
  // cityCrew: boolean = false;
  cityInspection = false;
  tnrcc = false;
  techInformation: DialogData;
  techList = [];
  statusList = [];
  ableToSubmit: boolean;
  constructor(
    public dialogRef: MatDialogRef<EditTechDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private snackBar: MatSnackBar,
    private sageApi: SageApiService
  ) {
    this.getData();
    this.getScheduleGroup();
    this.getVendor();
    this.getStatus();
    this.validators();
    this.getAllTechs();
  }

  getAllTechs() {
    this.sageApi.pullReport('AssignableForPunch/AllTechs').subscribe(res => {
      if (Array.isArray(res)) {
        res.forEach(element => {
          const obj = {
            techInfo: element.UDF_EMPLOYEE_CODE,
            Name: element.UDF_EMPLOYEE_NAME,
          };

          this.techList.push(obj);
        });
      }
    });
  }

  selectSplit(event, position) {
    if (position == 'PRTCrewLead') {
      this.techInfo.PRTCrewLead = event.item.techInfo;
    } else if (position == 'PRTCrew1') {
      this.techInfo.PRTCrew1 = event.item.techInfo;
    } else if (position == 'PRTCrew2') {
      this.techInfo.PRTCrew2 = event.item.techInfo;
    }
  }

  getStatus() {
    this.sageApi.pullReport('AssignableForPunch/TechStatus').subscribe(res => {
      if (Array.isArray(res)) {
        res.forEach(element => {
          this.statusList.push(element.StatusCode);
        });
      }
    });
  }

  validators() {
    let refuseSubmit = true;
    if (this.statusList.indexOf(this.techInfo.currentStatus) != -1) {
      if (this.scheduleGroup.indexOf(this.techInfo.ScheduleGroup) != -1) {
        if (
          this.defaultWarehouse.indexOf(this.techInfo.defaultWarehouse) != -1
        ) {
          if (
            this.vendorInformation.findIndex(
              x => x.VendorNo == this.techInfo.SubVendor
            ) != -1 ||
            this.techInfo.SubVendor == ''
          ) {
            if (this.techInfo.PRT == true) {
              const combined =
                this.techInfo.PRTCrew1Split +
                this.techInfo.PRTCrew2Split +
                this.techInfo.PRTCrewLeadSplit;

              if (combined == 1 || combined == 0) {
                refuseSubmit = false;
              }
              this.toolTip = 'Splits must equal 1 or 0.';
            } else {
              refuseSubmit = false;
            }
          } else {
            // this.toolTip = `${this.techInfo.SubVendor} is not a part of our current subvendors.`;
            this.toolTip = `Please select a different subvendor.`;
            return refuseSubmit;
          }
        }
        this.toolTip = 'Default Warehouse must be blank, 001, or 005.';
      }
      this.toolTip =
        this.techInfo.ScheduleGroup + ' is not part of the schedule group.';
    }

    this.toolTip =
      this.techInfo.currentStatus + ' is not part of the current status list.';
    if (this.scheduleGroup.indexOf(this.techInfo.ScheduleGroup) == -1) {
      this.toolTip =
        this.techInfo.ScheduleGroup + ' is not part of the schedule group.';
    }
    if (this.defaultWarehouse.indexOf(this.techInfo.defaultWarehouse) == -1) {
      this.toolTip = 'Default Warehouse must be blank, 001, or 005.';
    }
    if (this.techInfo.PRT == true) {
      const combined =
        this.techInfo.PRTCrew1Split +
        this.techInfo.PRTCrew2Split +
        this.techInfo.PRTCrewLeadSplit;
      if (combined != 1 && combined != 0) {
        this.toolTip = 'Splits must equal 1 or 0.';
      }
    }
    if (refuseSubmit == false) {
      this.toolTip = '';
    }

    return refuseSubmit;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'Close', {
      duration: 5000,
    });
  }

  getData() {
    this.techInformation = this.data;
    this.sageApi
      .pullReport(
        'AssignableForPunch?techDepartNo=' +
          this.techInformation.techDeptNo +
          '&techNo=' +
          this.techInformation.techNo
      )
      .subscribe(res => {
        this.techInfo.assignablePunch =
          res.UDF_ASSIGNABLE_PUNCH == 'N' ? false : true;
        this.techInfo.FirstName = res.FirstName;
        this.techInfo.LastName = res.LastName;
        this.techInfo.SubVendor = res.UDF_SUB_VENDOR;
        this.techInfo.ScheduleGroup = res.ScheduleGroup;
        this.techInfo.email = res.EMailAddress;
        this.techInfo.currentStatus = res.CurrentStatus;
        this.techInfo.defaultWarehouse = res.DefaultWarehouse;
        this.techInfo.PRT = res.UDF_PRT == 'N' ? false : true;
        this.techInfo.PRTCrewLead = res.UDF_PRT_CREW_LEAD;
        this.techInfo.PRTCrewLeadSplit = res.UDF_PRT_CREW_LEAD_SPLIT;
        this.techInfo.PRTCrew1 = res.UDF_PRT_CREW_EMP1;
        this.techInfo.PRTCrew1Split = res.UDF_PRT_CREW_EMP1_SPLIT;
        this.techInfo.PRTCrew2 = res.UDF_PRT_CREW_EMP2;
        this.techInfo.PRTCrew2Split = res.UDF_PRT_CREW_EMP2_SPLIT;
        this.techInfo.techDeptNo = res.TechnicianDeptNo;
        this.techInfo.techNo = res.TechnicianNo;
      });
  }

  getScheduleGroup() {
    this.sageApi
      .pullReport('AssignableForPunch/ScheduleGroup')
      .subscribe(res => {
        if (Array.isArray(res)) {
          res.forEach(element => {
            this.scheduleGroup.push(element.ScheduleGroupCode);
          });
        }
      });
  }
  getVendor() {
    this.sageApi.pullReport('AssignableForPunch/Vendor').subscribe(res => {
      console.log('AssignableForPunch/Vendor');
      console.log(res);
      if (Array.isArray(res)) {
        res.forEach(element => {
          const obj = {
            Name: element.VendorName,
            VendorNo: element.VendorNo,
          };
          this.vendorInformation.push(obj);
        });
      }
    });
  }
  selectVendor(event) {
    this.techInfo.SubVendor = event.item.VendorNo;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  checkData() {
    this.sending = true;
    this.sageApi
      .putRequest('AssignableForPunch/UpdateInfo', this.techInfo)
      .subscribe(
        response => {
          this.dialogRef.close(response);
        },
        err => {
          console.log(err);
          this.dialogRef.close(err);
        }
      );
  }

  getVendorNumbers(): string[] {
    return this.vendorInformation.map(x => x.VendorNo);
  }

  vendorNumberToVendorInformation(vendorNumber: string) {
    return this.vendorInformation.find(x => x.VendorNo == vendorNumber);
  }

  techinfoSubvendorChange(event) {
    this.techInfo.SubVendor = event.value ? event.value : '';
  }
}
