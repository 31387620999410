import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import QuoteDataEditableInterface from '../../../_services/QuoteEditableService/interfaces/QuoteDataEditableInterface';
import QuoteKitPartEditableInterface, {
  QuoteKitPartEditableDataInterface,
} from '../../../_services/QuoteEditableService/interfaces/QuoteKitPartEditableInterface';
import { MatDialog } from '@angular/material/dialog';
import QuotePartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuotePartDtoInterface';
import { QuoteKitPartRowOnSaveType } from '../QuoteKitPartRowComponent/QuoteKitPartRowComponent';
import QuoteNoteDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteNoteDtoInterface';
import QuoteBillingTermDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteBillingTermDtoInterface';
import { QuoteEditableService } from 'src/app/_services/QuoteEditableService/QuoteEditableService';
import { QuoteKitPartFilterService } from 'src/app/_services/QuoteKitPartFilterService/QuoteKitPartFilterService';

@Component({
  selector: 'app-quoteview',
  templateUrl: './QuoteViewComponent.html',
  styleUrls: ['./QuoteViewComponent.css', '../QuotingTheme.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteViewComponent implements OnInit {
  quoteGuid = this.activatedRoute.snapshot.params.quoteGuid;
  appbarSaving = false;

  loadingPDF = false;

  loading: string[] = [];

  breadcrumb = 0; // Kind of like the pages of the breadcrumb

  sidepanelOpen = false;

  addSheetOpen = false;
  addBotSheetTarget: {
    parentName: string;
    parentGuid?: string;
    data: QuoteKitPartEditableInterface | QuoteDataEditableInterface | null;
  } | null = null;

  customKitSheetOpen = false;
  customKitTarget:
    | QuoteKitPartEditableInterface
    | QuoteDataEditableInterface
    | null = null;

  customPartSheetOpen = false;
  customPartTarget:
    | QuoteKitPartEditableInterface
    | QuoteDataEditableInterface
    | null = null;

  editPartBotSheetOpen = false;
  isDeleteModalOpen = false;

  constructor(
    public qe: QuoteEditableService,
    public api: SageApiService,
    public screenSize: ScreenSizeService,
    public activatedRoute: ActivatedRoute,
    public cdr: ChangeDetectorRef,
    public qkpf: QuoteKitPartFilterService,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.activatedRoute.snapshot.queryParams;

    // Add keydown listener to document that listens for the delete key to delete selected QuoteKitParts, or escape to unselect all
    document.addEventListener('keydown', e => {
      if (this.breadcrumb == 0) {
        if (
          e.key == 'Delete' &&
          this.editPartBotSheetOpen == false &&
          this.isDeleteModalOpen == false &&
          this.customKitSheetOpen == false &&
          this.customPartSheetOpen == false &&
          this.addSheetOpen == false
        ) {
          if (this.qe.getSelectedChildren().length > 0) {
            this.loadingUntilComplete(this.qe.destroySelectedChildren());
          }
        }
        if (
          e.key == 'Escape' &&
          this.editPartBotSheetOpen == false &&
          this.isDeleteModalOpen == false &&
          this.customKitSheetOpen == false &&
          this.customPartSheetOpen == false &&
          this.addSheetOpen == false
        ) {
          this.qe.deselectChildren();
        }
        if (
          e.key.toLowerCase() == 'e' &&
          this.editPartBotSheetOpen == false &&
          this.isDeleteModalOpen == false &&
          this.customKitSheetOpen == false &&
          this.customPartSheetOpen == false &&
          this.addSheetOpen == false &&
          this.qe.getSelectedChildren().length == 1
        ) {
          this.openEditPartBotSheet();
          this.cdr.detectChanges();
        }
      }
    });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(map => {
      this.breadcrumb = parseInt(map.tab);
      return map;
    });

    this.qe.initQuote({ quoteGuid: this.quoteGuid });

    this.rerenderOnObservable(this.qe.updated);
  }

  rerenderOnObservable(observable: Observable<unknown>): Observable<unknown> {
    observable.subscribe(() => {
      this.cdr.detectChanges();
    });
    return observable;
  }

  tm() {
    return new Date().toISOString();
  }

  loadingUntilComplete(observable: Observable<unknown>): Observable<unknown> {
    const guid = `${Math.random()
      .toString(36)
      .substring(7)}-${new Date().getTime()}`;
    observable.subscribe({
      next: rt => {
        if (rt == 'loading') {
          this.loading.push(guid);
          this.cdr.detectChanges();
        }
      },
      error: () => {
        /* TODO: Handle error */
      },
      complete: () => {
        const loadingIndex = this.loading.indexOf(guid);
        if (loadingIndex > -1) {
          this.loading.splice(loadingIndex, 1);
        }
        this.cdr.detectChanges();
      },
    });
    return observable;
  }

  isLoading(): boolean {
    return this.loading.length > 0;
  }

  appbarSavingUntilComplete(
    observable: Observable<unknown>
  ): Observable<unknown> {
    this.appbarSaving = true;
    observable.subscribe(
      () => {
        this.appbarSaving = false;
        this.cdr.detectChanges();
      },
      () => {
        this.appbarSaving = false;
        this.cdr.detectChanges();
      }
    );
    return observable;
  }

  rowSaved(saved: QuoteKitPartRowOnSaveType): void {
    if ('QuotePart_Cost' in saved) {
      // Go through all the QuoteParts in data.children and their children, and change the QuotePart_Cost to saved.QuotePart_Cost
      const changeCost = (children: QuoteKitPartEditableInterface[]) => {
        for (const qkp of children) {
          if (qkp.data.QuotePart) {
            if (
              qkp.data.QuotePart.QuotePart.QuotePart_guid ==
              saved.QuotePart_guid
            ) {
              qkp.data.QuotePart.QuotePart = saved;
              qkp.costControl.setValue(saved.QuotePart_Cost);
            }
          }
          if (qkp.data.QuoteKit) {
            changeCost(qkp.children);
          }
        }
      };
      changeCost(this.qe.quote.children);
    }
    // this.shouldUpdate.next();
  }

  openAddPartsSheet(target?: QuoteKitPartEditableInterface): void {
    if (target) {
      this.addBotSheetTarget = {
        parentGuid: target.data.QuoteKitPart.QuoteKitPart_guid,
        parentName:
          target.data?.QuoteKit?.QuoteKit?.QuoteKit_Name ||
          target.data?.QuotePart?.QuotePart?.QuotePart_Code,
        data: target,
      };
    } else {
      this.addBotSheetTarget = {
        parentName: this.qe.quote.data.Quote.Quote_Name,
        data: this.qe.quote,
      };
    }
    this.addSheetOpen = true;
  }

  closeAddPartsSheet(): void {
    this.addSheetOpen = false;
  }

  saveQuoteKitPartChanges(): void {
    /* TODO: Read the fn name */
  }

  openEditPartBotSheet(): void {
    this.editPartBotSheetOpen = true;
  }

  openAddCustomKitSheet(
    newTarget: QuoteKitPartEditableInterface | QuoteDataEditableInterface
  ): void {
    this.customKitSheetOpen = true;
    this.customKitTarget = newTarget;
  }

  closeCustomKitSheet(): void {
    this.customKitSheetOpen = false;
    this.customKitTarget = null;
  }

  openAddCustomPartSheet(
    newTarget: QuoteKitPartEditableInterface | QuoteDataEditableInterface
  ): void {
    this.customPartSheetOpen = true;
    this.customPartTarget = newTarget;
  }

  closeCustomPartSheet() {
    this.customPartSheetOpen = false;
    this.customPartTarget = null;
  }

  navigateToPDF(): void {
    this.loadingPDF = true;

    const quotePreviewPage =
      '/quoting/preview-quote-pdf/' + this.qe.quote.data.Quote.Quote_guid;
    const obj = { QuoteGuid: this.quoteGuid, finalized: false };
    this.api.postBlob('preview-quote-pdf', obj).subscribe(
      response => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const file = window.URL.createObjectURL(blob);
        this.loadingPDF = false;
        window.open(file);
      },
      e => {
        console.log(e.error);
        this.snackBar.open('Error opening quote PDF.', 'Close');
        this.loadingPDF = false;
        this.cdr.detectChanges();

        throw Error(e);
      },
      () => {
        this.loadingPDF = false;
        console.log(this.loadingPDF);
        this.cdr.detectChanges();
      }
    );
  }

  changeTab(newTab: number): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { tab: newTab },
      queryParamsHandling: 'merge',
    });
  }

  trackByKitPartGuid(index: number, item: QuotePartDtoInterface): string {
    return item.QuotePart_guid;
  }

  trackByQuoteNoteGuid(index: number, item: QuoteNoteDtoInterface): string {
    return item.QuoteNote_guid;
  }

  trackByQuoteBillingTermGuid(
    index: number,
    item: QuoteBillingTermDtoInterface
  ): string {
    return item.QuoteBillingTerm_guid;
  }

  trackByField<ObjInterface>(
    field: keyof ObjInterface
  ): (index: number, item: ObjInterface) => ObjInterface[keyof ObjInterface] {
    return (_index: number, item: ObjInterface) => item[field];
  }

  saveFromKoqAppBar(toSave: {
    title: string;
    region: string;
    phase: string;
    description: string;
  }): void {
    this.appbarSavingUntilComplete(
      this.qe.changeQuote({
        quoteChanges: {
          Quote_Name: toSave.title,
          Quote_Region: toSave.region,
          Quote_Status: toSave.phase,
          Quote_Desc: toSave.description,
        },
      })
    );
  }
}
