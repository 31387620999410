import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ListObjectsV2Command, S3Client } from '@aws-sdk/client-s3';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';

@Injectable({
  providedIn: 'root'
})
export class WasabiApiService {
  apiUrl = this.sage.apiUrl;

  imgUrlList: Array<string> = [];

  constructor(private httpClient: HttpClient, private sage: SageApiService) { }

  async getImages(address: string) {
    const { S3Client, ListObjectsCommand } = require('@aws-sdk/client-s3');
    const bucketParams = {
      Bucket: 'gibson-qa',
      Prefix: `uploads/${address}`
    };
    const s3 = new S3Client({
      region: 'us-east-1',
      endpoint: 'https://s3.wasabisys.com',
      version: 'latest',
      credentials: {
        accessKeyId: 'XBCEO6TZ5X4188GVG4ZT',
        secretAccessKey: 'EHw4C4Re1OOCYZNdrL8yrqpmcO0ZuiQnfJ3zrl2z'
      }
    });

    // Destination: address

    try {
      this.imgUrlList = [];
      const bucketInfo = await s3.send(new ListObjectsCommand(bucketParams));
      const imgBaseUrl = 'https://s3.wasabisys.com/gibson-qa/';
      for (const i in bucketInfo.Contents) {
        // if (bucketInfo.Contents[i].Key.slice(bucketInfo.Contents[i].Key.length - 4) !== '.pdf') {
          this.imgUrlList.push(`${imgBaseUrl}${bucketInfo.Contents[i].Key}`);
        // }
      }
      return this.imgUrlList;
    } catch (error) {
      console.log('Error in S3 getImages function: ', error);
    }
  }

  getImagesObservable(address: string) {
    const { S3Client, ListObjectsCommand } = require('@aws-sdk/client-s3');
    const bucketParams = {
      Bucket: 'gibson-qa',
      Prefix: `uploads/${address}`
    };
    const s3 = new S3Client({
      region: 'us-east-1',
      endpoint: 'https://s3.wasabisys.com',
      version: 'latest',
      credentials: {
        accessKeyId: 'XBCEO6TZ5X4188GVG4ZT',
        secretAccessKey: 'EHw4C4Re1OOCYZNdrL8yrqpmcO0ZuiQnfJ3zrl2z'
      }
    });

    const prom: Promise<string[]> = new Promise((resolveProm, rejectProm)=>{
      const imgUrlList: string[] = [];
      s3.send(new ListObjectsCommand(bucketParams)).then((bucketInfo)=>{
        const imgBaseUrl = 'https://s3.wasabisys.com/gibson-qa/';
        for (const i in bucketInfo.Contents) {
          // if (bucketInfo.Contents[i].Key.slice(bucketInfo.Contents[i].Key.length - 4) !== '.pdf') {
            imgUrlList.push(`${imgBaseUrl}${bucketInfo.Contents[i].Key}`);
          // }
        }
        resolveProm(imgUrlList);
      })
      .catch((err)=>{
        rejectProm();
        console.log('Error in S3 getImages function: ', err)
      });
    });
    return fromPromise(prom);
  }

  uploadImage(address: string, sentImages: any) {
    const url = this.apiUrl + `wasabi/images?address=${address}`;
    const body = {
      images: sentImages
    };
    const reportAuth = localStorage.getItem('token');
    const headers = new HttpHeaders().set('JWTAuthorization', `Bearer ${reportAuth}`);
    const formData = new FormData();

    sentImages.forEach((file, index) => {
      formData.append('file' + index, file.data, file.name);
    });
    console.log(sentImages)

    console.log(formData)
    return this.httpClient.post(url, formData, {headers});
    // const url = 'https://proxy.gibsonplumbing.com/sdata/sageapi/sageapi/api/wasabi?address=' + address + '&num=' + wtNumber;
    // const reportAuth = localStorage.getItem('token');
    // const headers = new HttpHeaders().set('JWTAuthorization', `Bearer ${reportAuth}`);
    // const formData = new FormData();
    //
    // sentImages.forEach((file, index) => {
    //   formData.append('image' + wtNumber + '-' + index, file);
    // });
    //
    // return this.httpClient.post(url, formData, { headers });
  }

  uploadFile(address: string, filepath: string, sentfiles: any) {
    console.log(sentfiles)
    let url = '';
    if (filepath === 'scouts'){
      // old ----- url = 'https://proxy.gibsonplumbing.com/sdata/sageapi/sageapi/api/wasabi/scouts?address=' + address;
      // url = 'https://proxy.gibsonplumbing.com/API_Apps/dev_robert/SageApi/api/wasabi/scouts?address=' + address;
      url = this.apiUrl + 'wasabi/scouts?address=' + address; // main api
      console.log('alpha');
    }else if (filepath == 'quote/') {
      url = this.apiUrl +'wasabi?guid=' + address; // main api
    }else {
      // old ----- url = 'https://proxy.gibsonplumbing.com/sdata/sageapi/sageapi/api/wasabi?address=' + address;
      // url = 'https://proxy.gibsonplumbing.com/API_Apps/dev_robert/SageApi/api/wasabi?address=' + address;
      url = this.apiUrl +'wasabi?address=' + address; // main api
      console.log('omega');
    }
    const reportAuth = localStorage.getItem('token');
    const headers = new HttpHeaders().set('JWTAuthorization', `Bearer ${reportAuth}`);
    const formData = new FormData();

    sentfiles.forEach((file, index) => {
      formData.append('file' + index, file);
    });

    return this.httpClient.post(url, formData, { headers });

  }


  async getQuoteInfo(address: string) {
    const { S3Client, ListObjectsCommand } = require('@aws-sdk/client-s3');
    const bucketParams = {
      Bucket: 'gibson-qa',
      Prefix: `quotes/${address}`
    };
    const s3 = new S3Client({
      region: 'us-east-1',
      endpoint: 'https://s3.wasabisys.com',
      version: 'latest',
      credentials: {
        accessKeyId: 'XBCEO6TZ5X4188GVG4ZT',
        secretAccessKey: 'EHw4C4Re1OOCYZNdrL8yrqpmcO0ZuiQnfJ3zrl2z'
      }
    });

    // Destination: address

    try {
      this.imgUrlList = [];
      const bucketInfo = await s3.send(new ListObjectsCommand(bucketParams));
      const imgBaseUrl = 'https://s3.wasabisys.com/gibson-qa/';
      for (const i in bucketInfo.Contents) {
        let fileName = (bucketInfo.Contents[i].Key)
        // if (bucketInfo.Contents[i].Key.slice(bucketInfo.Contents[i].Key.length - 4) !== '.pdf') {

        if(fileName.includes(" ")){
          fileName = fileName.replaceAll(" ", "%20");
        }
          this.imgUrlList.push(`${imgBaseUrl}${fileName}`);
        // }
      }
      return this.imgUrlList;
    } catch (error) {
      console.log('Error in S3 getImages function: ', error);
    }
  }
}
