import { EventEmitter, Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

const screenSizes = ['xs', 'sm', 'md', 'lg', 'xl'] as const;
const screenSizesAndDown = ['xsd', 'smd', 'mdd', 'lgd', 'xld'] as const;
const screenSizesAndUp = ['xsu', 'smu', 'mdu', 'lgu', 'xlu'] as const;

@Injectable({
  providedIn: 'root',
})
export class ScreenSizeService {
  screenSize: typeof screenSizes[number] = 'sm';
  screenSizeAndDown: typeof screenSizesAndDown[number][] = ['smd'];
  screenSizeAndUp: typeof screenSizesAndUp[number][] = ['smu'];
  screenSizeChange: EventEmitter<
    typeof screenSizes[number]
  > = new EventEmitter();

  constructor(breakpointObserver: BreakpointObserver) {
    breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .subscribe(result => {
        const values = Object.values(result.breakpoints);
        this.screenSizeAndDown = [];
        this.screenSizeAndUp = [];
        for (let i = 0; i < values.length; i++) {
          if (values[i]) {
            this.screenSize = screenSizes[i];
            for (let idown = i; idown < values.length; idown++) {
              this.screenSizeAndDown.push(screenSizesAndDown[idown]);
            }
            for (let iup = i; iup > 0; iup--) {
              this.screenSizeAndUp.push(screenSizesAndUp[iup]);
            }
          }
        }
        this.screenSizeChange.emit(this.screenSize);
      });
  }

  getClasses(classPrefix?: string): string {
    let newClasses: string[] = [
      this.screenSize,
      ...this.screenSizeAndDown,
      ...this.screenSizeAndUp,
    ];
    if (classPrefix != undefined) {
      newClasses = newClasses.map(ncs => `${classPrefix}${ncs}`);
    }
    return newClasses.join(' ');
  }

  getClassesArray(classPrefix?: string): string[] {
    let newClasses: string[] = [
      this.screenSize,
      ...this.screenSizeAndDown,
      ...this.screenSizeAndUp,
    ];
    if (classPrefix != undefined) {
      newClasses = newClasses.map(ncs => `${classPrefix}${ncs}`);
    }
    return newClasses;
  }
}
