<div class="snowflakes" aria-hidden="true" *ngIf="authService.holidayTheme">
  <div class="snowflake">
    <!-- ❅ -->
    <i class="fas fa-heart"></i>
  </div>
  <div class="snowflake">
    <!-- ❆ -->
    <i class="fas fa-grati-pay"></i>
  </div>
  <div class="snowflake">
    <!-- ❅ -->
    <i class="fas fa-grin-hearts"></i>
  </div>
  <div class="snowflake">
    <!-- ❆ -->
    <i class="fas fa-glass-cheers"></i>
  </div>
  <div class="snowflake">
    <!-- ❅ -->
    <i class="fas fa-dove"></i>
  </div>
  <div class="snowflake">
    <!-- ❆ -->
    <i class="fas fa-gift"></i>
  </div>
  <div class="snowflake">
    <!-- ❅ -->
    <i class="fas fa-heart"></i>
  </div>
  <div class="snowflake">
    <!-- ❆ -->
    <i class="fas fa-grin-hearts"></i>
  </div>
  <div class="snowflake">
    <!-- ❅ -->
    <i class="fas fa-glass-cheers"></i>
  </div>
  <div class="snowflake">
    <!-- ❆ -->
    <i class="fas fa-dove"></i>
  </div>
  <div class="snowflake">
    <!-- ❅ -->
    <i class="fas fa-grati-pay"></i>
  </div>
  <div class="snowflake">
    <!-- ❆ -->
    <i class="fas fa-gift"></i>
  </div>
</div>
<mat-sidenav-container
  class="app-sidenav"
  [ngClass]="authService.holidayTheme ? 'holiday-background' : ''"
>
  <mat-sidenav
    #sidenav
    mode="side"
    [fixedInViewport]="true"
    [(opened)]="navOpened"
  >
    <div class="bg-secondary" id="sidebar-wrapper">
      <div class="navbar navbar-brand bg-light text-primary mr-0 pt-2">
        <strong>Gibson Plumbing</strong>
        <a class="navbar-brand text-dark p-1 m-0" (click)="navToggle()">
          <i class="fas fa-sign-out-alt fa-flip-horizontal"></i>
        </a>
        <!-- <button class="btn" (click)="navToggle()">
          <span class="text-dark">
            <i class="fas fa-sign-out-alt fa-flip-horizontal"></i>
          </span>
        </button> -->
      </div>
      <div class="list-group list-group-flush">
        <a
          routerLink=""
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          class="list-group-item list-group-item-action py-1"
        >
          Home Page
        </a>

        <ng-container *ngIf="navShow('am')">
          <div class="border-bottom text-center bg-dark text-light">
            Area Managers
          </div>
          <a
            routerLink="/AMDashboard"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Reassignments
          </a>
          <a
            routerLink="/Woms/Management/Queue"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            WOMs Management Queue
          </a>
          <a
            routerLink="/Builder/ChangeOrder"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Change Orders
          </a>
        </ng-container>

        <ng-container *ngIf="navShow('super')">
          <div class="border-bottom text-center bg-dark text-light">Supers</div>
          <a
            routerLink="/SuperSchedule"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Super Schedule
          </a>
          <a
            routerLink="/PhasePay"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Phase Pay
          </a>
          <!-- Removed with User Story 172
  <a routerLink="/PhasePay/History" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="navToggle()" class="list-group-item list-group-item-action py-1"> Phase Pay History </a> -->
          <a
            routerLink="/supers/Workticket"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            QA Workticket</a
          >
          <a
            routerLink="/supers/super-reports"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            QA Reports</a
          >
          <a
            routerLink="/supers/Punch"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Punch
          </a>
          <!--          <a href="https://qa.gibsonplumbing.com/status" routerLinkActive="active" class="list-group-item list-group-item-action py-1" *ngIf="navShow('internal')"> QA Reports </a>-->
          <a
            routerLink="/supers/house360"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            House 360
          </a>
          <a
            routerLink="/supers/address-files"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Address Files
          </a>
          <a
            routerLink="/Woms/Management/Queue"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            WOMs Management Queue
          </a>
        </ng-container>

        <ng-container *ngIf="navShow('warrantyCoordinator')">
          <div class="border-bottom text-center bg-dark text-light">
            Warranty Coordinator
          </div>
          <a
            routerLink="/supers/Workticket"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            QA Workticket</a
          >
          <a
            routerLink="/supers/address-files"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Address Files
          </a>
          <a
            routerLink="/SuperSchedule"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Super Schedule
          </a>
          <a
            routerLink="/Schedule/HouseCreate"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            House Create
          </a>
          <a
            routerLink="/Schedule/Rework/Queue"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Rework Queue
          </a>
          <a
            routerLink="/Schedule/Customer/Assignment"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Confirmation Assignments
          </a>
          <a
            routerLink="/Schedule/Assign/Schedule"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Assign Schedule
          </a>
          <a
            routerLink="/supers/Punch"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Punch
          </a>
          <a
            routerLink="Builder/House/Edit"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            House Edit
          </a>
          <a
          routerLink="SalesOrderLookup"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="navToggle()"
          class="list-group-item list-group-item-action py-1"
        >
          Sales Order Lookup
        </a>
        </ng-container>

        <ng-container *ngIf="navShow('tech')">
          <div class="border-bottom text-center bg-dark text-light">Techs</div>
          <a
            routerLink="/techtodo"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Todo List
          </a>
          <a
            routerLink="/supers/address-files"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Address Files
          </a>
        </ng-container>

        <ng-container *ngIf="navShow('techWar')">
          <div class="border-bottom text-center bg-dark text-light">
            Warranty Techs
          </div>
          <a
            routerLink="/techtodo"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Todo List
          </a>
          <a
            routerLink="/supers/Workticket"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            QA Workticket</a
          >
          <a
            routerLink="/supers/address-files"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Address Files
          </a>
        </ng-container>

        <ng-container *ngIf="navShow('schedule')">
          <div class="border-bottom text-center bg-dark text-light">
            Scheduling
          </div>
          <a
            routerLink="/Schedule/HouseCreate"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            House Create
          </a>
          <a
            routerLink="/Schedule/Rework/Queue"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Rework Queue
          </a>
          <!--          <a routerLink="/Schedule/Confirmation/Queue" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="navToggle()" class="list-group-item list-group-item-action py-1"> Schedule Confirmations </a>-->
          <a
            routerLink="/Schedule/Customer/Assignment"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Confirmation Assignments
          </a>
          <a
            routerLink="/Schedule/Assign/Schedule"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Assign Schedule
          </a>
          <a
            routerLink="/Schedule/ClearDate"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Clear Date
          </a>
          <!--          <a routerLink="/supers/Workticket" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="navToggle()" class="list-group-item list-group-item-action py-1"> QA Workticket</a>-->
          <!--          <a routerLink="/supers/super-reports" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="navToggle()" class="list-group-item list-group-item-action py-1"> QA Reports</a>-->
          <a
            routerLink="/supers/Punch"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Punch
          </a>
          <a
            routerLink="Builder/House/Edit"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            House Edit
          </a>
          <a
          routerLink="SalesOrderLookup"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="navToggle()"
          class="list-group-item list-group-item-action py-1"
        >
          Sales Order Lookup
        </a>
          <a
            routerLink="/supers/address-files"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Address Files
          </a>
        </ng-container>

        <ng-container *ngIf="navShow('scheduleadmin')">
          <div class="border-bottom text-center bg-dark text-light">
            Scheduling Manager
          </div>
          <a
            routerLink="/Schedule/Rework/Queue"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Rework Queue
          </a>
          <a
            routerLink="/Schedule/Confirmation/Queue"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Schedule Confirmations
          </a>
          <a
            routerLink="/Schedule/Customer/Assignment"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Confirmation Assignments
          </a>
          <a
            routerLink="/Schedule/Assign/Schedule"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Assign Schedule
          </a>
          <a
            routerLink="/PhasePay"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Phase Pay
          </a>
          <a
            routerLink="/supers/Workticket"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            QA Workticket</a
          >
          <a
            routerLink="/supers/super-reports"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            QA Reports</a
          >
          <a
            routerLink="/supers/Punch"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Punch
          </a>
          <a
            routerLink="/Woms/Management/Queue"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            WOMs Management Queue
          </a>
          <a
            routerLink="SubdivisionEdit"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Subdivision Edit
          </a>
          <a
            routerLink="Builder/House/Edit"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            House Edit
          </a>
          <a
          routerLink="SalesOrderLookup"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="navToggle()"
          class="list-group-item list-group-item-action py-1"
        >
          Sales Order Lookup
        </a>
        </ng-container>

        <ng-container *ngIf="navShow('inventory')">
          <div class="border-bottom text-center bg-dark text-light">
            Inventory
          </div>

          <!--          <a routerLink="/Inventory/OrderCloseout" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="navToggle()" class="list-group-item list-group-item-action py-1"> Order Closeout </a>-->
          <!--          <a routerLink="/Inventory/QRScanner" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="navToggle()" class="list-group-item list-group-item-action py-1"> QR Scanner </a>-->
          <a
            routerLink="/supers/Punch"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Punch
          </a>
        </ng-container>

        <ng-container *ngIf="navShow('Warehouse')">
          <div class="border-bottom text-center bg-dark text-light">
            Warehouse
          </div>
          <!--          <a routerLink="/Inventory/UnshippedItems" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="navToggle()" class="list-group-item list-group-item-action py-1"> Unshipped Items </a>-->
          <a
            routerLink="/Inventory/QRScanner"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Over the Counter/QR Scanner
          </a>
        </ng-container>

        <ng-container *ngIf="navShow('material')">
          <div class="border-bottom text-center bg-dark text-light">
            Material
          </div>
          <a
            routerLink="/WorkTicketEdit"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
            >WT Edit</a
          >
          <a
            routerLink="/Material/Receiving"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
            >Receiving</a
          >
          <a
            routerLink="/WarehouseTransfer"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
            >Warehouse Transfer</a
          >
          <a
            routerLink="/MaterialRequisition"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
            >Material Requisition</a
          >
          <a
          routerLink="SalesOrderLookup"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="navToggle()"
          class="list-group-item list-group-item-action py-1"
        >
          Sales Order Lookup
          </a>
        </ng-container>

        <ng-container *ngIf="navShow('service')">
          <div class="border-bottom text-center bg-dark text-light">
            Service
          </div>
          <a
            routerLink="/Service/MaterialTransfer"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
            >Material Transfer</a
          >
          <a
            routerLink="/Service/MaterialHistory"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
            >Material History</a
          >
          <a
            routerLink="/Service/MaterialScheduled"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
            >Material Scheduled</a
          >
          <a
            routerLink="Builder/House/Edit"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            House Edit
          </a>
          <a
          routerLink="SalesOrderLookup"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="navToggle()"
          class="list-group-item list-group-item-action py-1"
        >
          Sales Order Lookup
        </a>
          <a
            routerLink="/supers/Punch"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Punch
          </a>
          <a
            routerLink="Service/TruckManager"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
            >Truck Manager</a
          >
          <a
            routerLink="Service/Batching"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
            >Batch</a
          >
        </ng-container>

        <ng-container *ngIf="navShow('serviceMaterial')">
          <div class="border-bottom text-center bg-dark text-light">
            Service Material
          </div>
          <a
            routerLink="/Service/MaterialIssue"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
            >Material Warehouse Issue</a
          >
          <a
            routerLink="/Service/MaterialOTC"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
            >Service OTC</a
          >
          <a
            routerLink="/Service/MaterialReturn"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
            >Material Return</a
          >
          <a
            routerLink="/Service/MaterialScheduled"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
            >Material Scheduled</a
          >
          <a
            routerLink="Service/TruckManager"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
            >Truck Manager</a
          >
        </ng-container>

        <ng-container *ngIf="navShow('accounting')">
          <div class="border-bottom text-center bg-dark text-light">
            Accounting
          </div>
          <!-- <a routerLink="/Accounting/Receipt/Invoice/Approval" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="navToggle()" class="list-group-item list-group-item-action py-1">Receipt of Invoice Approval</a> -->
          <a
            routerLink="/Accounting/Receipt/Invoice"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
            >Receipt of Invoice Pending</a
          >
          <a
            routerLink="/Accounting/Receipt/Goods/Approval"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
            >Receipt of Goods Approval</a
          >
          <a
            routerLink="/PhasePay"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Phase Pay
          </a>
          <!-- Removed with User Story 172
          <a routerLink="/PhasePay/History" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="navToggle()" class="list-group-item list-group-item-action py-1"> Phase Pay History </a> -->
          <a
            routerLink="/Woms/Accounting/Queue"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            WOMs Accounting Queue
          </a>
          <a
            routerLink="/Woms/Pricing/Queue"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            WOMs Pricing Queue
          </a>

          <a
            routerLink="/supers/super-reports"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            QA Reports</a
          >
          <a
          routerLink="SalesOrderLookup"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="navToggle()"
          class="list-group-item list-group-item-action py-1"
        >
          Sales Order Lookup
          </a>
        </ng-container>

        <ng-container *ngIf="navShow('builder')">
          <div class="border-bottom text-center bg-dark text-light">
            Builders
          </div>
          <a
            routerLink="Builder/House/Edit"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            House Edit
          </a>
          <a
            routerLink="Builder/Template/Edit"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Template Editor
          </a>
          <a
            routerLink="/Schedule/HouseCreate"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            House Create
          </a>
          <a
            routerLink="/supers/address-files"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Address Files
          </a>
          <a
            routerLink="/Builder/ChangeOrder"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Change Orders
          </a>
          <a
          routerLink="SalesOrderLookup"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="navToggle()"
          class="list-group-item list-group-item-action py-1"
        >
          Sales Order Lookup
        </a>
        </ng-container>

        <ng-container *ngIf="navShow('purchasing')">
          <div class="border-bottom text-center bg-dark text-light">
            Purchasing
          </div>
          <a
            routerLink="/Service/MaterialScheduled"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
            >Service Material Scheduled</a
          >
          <a
            routerLink="Builder/House/Edit"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            House Edit
          </a>
          <a
          routerLink="SalesOrderLookup"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="navToggle()"
          class="list-group-item list-group-item-action py-1"
        >
          Sales Order Lookup
          </a>
        </ng-container>

        <ng-container *ngIf="navShow('quoting')">
          <div class="border-bottom text-center bg-dark text-light">
            Quoting
          </div>
          <a
            routerLink="quoting/dashboard"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
            >Dashboard</a
          >
          <a
            routerLink="quoting/quotes"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
            >Quotes</a
          >
          <!-- Disabled for the time being until we can revist after v1 -->
          <a
            routerLink="quoting/questions"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
            >Questions</a
          >
          <a
            routerLink="quoting/kits"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
            >Kits</a
          >
          <a
            routerLink="quoting/parts"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
            >Parts</a
          >
        </ng-container>

        <ng-container *ngIf="navShow('superadmin')">
          <div class="border-bottom text-center bg-dark text-light">
            Decommissioning
          </div>
          <a
            routerLink="/Inventory/UnshippedItems"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="navToggle()"
            class="list-group-item list-group-item-action py-1"
          >
            Unshipped Items
          </a>
        </ng-container>

        <div [style]="{ minHeight: '25px' }"></div>
      </div>
    </div>
  </mat-sidenav>

  <mat-sidenav-content id="sidebar-content-wrapper">
    <nav
      class="navbar rounded sticky-top"
      [ngClass]="
        authService.holidayTheme
          ? 'holiday-theme navbar-light'
          : 'bg-dark navbar-dark'
      "
    >
      <!-- Navbar content -->
      <a
        class="navbar-brand"
        (click)="navToggle()"
        [ngClass]="
          authService.holidayTheme
            ? 'text-white p-1 rounded bg-primary'
            : ' p-1 text-white'
        "
      >
        <i class="fas fa-bars"></i>
      </a>
      <div *ngIf="loggedIn()" class="dropdown show" dropdown>
        <a
          class="dropdown-toggle"
          dropdownToggle
          [ngClass]="
            authService.holidayTheme
              ? 'text-white p-1 rounded bg-primary'
              : 'text-white'
          "
        >
          Welcome {{ authService.decodedToken?.unique_name | titlecase }}
        </a>
        <div class="dropdown-menu dropdown-menu-right mt-3" *dropdownMenu>
          <a
            class="dropdown-item"
            (click)="toggleHolidayTheme()"
            routerLinkActive="active"
            *ngIf="navShow('internal')"
          >
            <i class="fas fa-heart"></i>
            {{ authService.holidayTheme ? 'Back to normal' : 'Love Birds' }}
            <div class="dropdown-divider mb-0"></div>
            <!-- <i class="fas fa-snowflake"></i> {{ authService.holidayTheme ? 'Back to normal' : 'Let it snow' }}
            <div class="dropdown-divider mb-0"></div> -->
          </a>
          <a
            class="dropdown-item"
            href="/User/Password/Reset"
            routerLinkActive="active"
            *ngIf="navShow('internal')"
          >
            <i class="fas fa-unlock-alt"></i> Update Password
            <div class="dropdown-divider mb-0"></div>
          </a>
          <a
            class="dropdown-item"
            routerLink="/upload/plans"
            routerLinkActive="active"
            *ngIf="navShow('internal')"
          >
            <i class="fas fa-cloud-upload-alt"></i> Uploads
            <div class="dropdown-divider mb-0"></div>
          </a>
          <a
            class="dropdown-item"
            routerLink="/Settings"
            routerLinkActive="active"
            *ngIf="navShow('internal')"
          >
            <i class="fas fa-cog"></i> Settings
            <div class="dropdown-divider mb-0"></div>
          </a>
          <a
            class="dropdown-item"
            routerLink="/EditUser"
            routerLinkActive="active"
            *ngIf="navShow('internal')"
          >
            <i class="fas fa-users"></i> Edit User
            <div class="dropdown-divider mb-0"></div>
          </a>
          <a class="dropdown-item" (click)="logout()">
            <i class="fas fa-door-open"></i> Log Off
          </a>
        </div>
      </div>

      <form
        *ngIf="!loggedIn()"
        #loginForm="ngForm"
        class="form-inline my-2 my-lg-0"
        (ngSubmit)="login()"
      >
        <input
          class="form-control mr-sm-2"
          type="text"
          name="username"
          placeholder="Username"
          required
          [(ngModel)]="model.username"
        />
        <input
          class="form-control mr-sm-2"
          type="password"
          name="password"
          placeholder="Password"
          required
          [(ngModel)]="model.password"
        />
        <button
          [disabled]="!loginForm.valid || loginLoading"
          class="btn btn-success my-2 my-sm-0"
          type="submit"
        >
          <span *ngIf="!loginLoading"> Login </span>
          <span *ngIf="loginLoading">
            <i class="fas fa-sync fa-spin"></i>
          </span>
        </button>
      </form>
    </nav>
    <div (click)="navClose()">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
