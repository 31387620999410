<div mat-dialog-content class="upmod-ctnt">
  <label for="requestAttachments" class="upmod-ctnt-ttl">
    <h4>{{ this.data.title }}</h4>
    <mat-spinner *ngIf="loading" diameter="25"></mat-spinner>
  </label>
  <div class="upmod-ctnt-fp">
    <input
      #fileInput
      (change)="fileChosen($event)"
      type="file"
      multiple
      class="upmod-ctnt-fp-in"
    />
    <button
      [disabled]="fileLoading || loading"
      (click)="fileInput.click()"
      mat-mini-fab
      color="primary"
    >
      <mat-icon>attach_file</mat-icon>
    </button>
  </div>
  <div class="upmod-ctnt-fp-files">
    <div
      class="upmod-ctnt-fp-files-itm"
      *ngFor="let file of fileArray; let fileIndex = index"
    >
      <p class="upmod-ctnt-fp-files-itm-ctnt">{{ file.name }}</p>
      <button
        *ngIf="!fileLoading && !loading"
        (click)="removeFileFromToUpload(file)"
        class="upmod-ctnt-fp-files-itm-rm"
      >
        <mat-icon class="upmod-ctnt-fp-files-itm-rm-icn">close</mat-icon>
      </button>
    </div>
  </div>
</div>

<div mat-dialog-actions class="upmod-actns">
  <button
    [disabled]="fileLoading || loading"
    mat-button
    color="warn"
    mat-flat-button
    (click)="cancel()"
  >
    Cancel
  </button>
  <button
    [disabled]="(fileLoading || loading) && fileArray.length > 0"
    mat-button
    color="primary"
    mat-flat-button
    (click)="fileUpload()"
  >
    Upload
  </button>
</div>

<div class="files" *ngIf="!loading" class="upmod-files">
  <div *ngFor="let file of allFiles" class="upmod-files-itm">
    <a [href]="file" target="_blank" class="upmod-files-itm-a">
      <i class="fas fa-file-alt fa-2x"></i>
      <span class="upmod-files-itm-a-txt">
        {{ uriToFileName(file) }}
      </span>
    </a>
  </div>
</div>
