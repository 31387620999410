<div [class]="screenSize.getClasses() + ' qqeditview quoting-theme'">
  <div class="qqeditview-header">
    <h2 class="qqeditview-header-title">
      Quote Questions
      <mat-spinner
        *ngIf="loading"
        [diameter]="30"
        class="qqeditview-header-title-spinner"
      ></mat-spinner>
    </h2>
    <h5 class="qqeditview-header-subtitle">
      Questions asked before creating a quote that adds parts/kits to newly
      created quotes prior to editing.
    </h5>
  </div>

  <div class="quotesview-add">
    <button
      (click)="openNewQuoteQuestionSheet()"
      mat-fab
      class="quotesview-add-button"
    >
      <i class="fas fa-plus"></i>
    </button>
  </div>

  <div class="qqeditview-questions">
    <mat-card
      *ngFor="let question of quoteQuestions; let qIndex = index"
      class="qqeditview-questions-item"
    >
      <div class="qqeditview-questions-item-q">
        <app-grow-input
          [control]="question.questionNameControl"
          (inputEmitter)="questionNameControlInput($event, qIndex)"
          (blurEmitter)="questionNameControlBlur($event, qIndex)"
          [useNativeInput]="true"
          minWidth="100%"
          maxWidth="100%"
          class="qqeditview-questions-item-q-name"
        ></app-grow-input>
        <!-- <h3 class="qqeditview-questions-item-q-name">{{ question.data.QuoteQuestion.QuoteQuestion_Name }}</h3> -->
        <!-- <h5 class="qqeditview-questions-item-q-desc">{{ question.data.QuoteQuestion.QuoteQuestion_Desc }}</h5> -->
        <mat-form-field
          appearance="outline"
          class="qqeditview-questions-item-ff"
        >
          <textarea
            matInput
            rows="2"
            [formControl]="question.questionDescControl"
            class="qqeditview-questions-item-ff-in"
          ></textarea>
        </mat-form-field>
        <div class="qqeditview-questions-item-q-controls">
          <div class="qqeditview-questions-item-q-controls-front">
            <button
              (click)="resetQuestionControls(qIndex)"
              mat-flat-button
              color="warn"
              [disabled]="isQuestionCancelButtonDisabled(qIndex)"
            >
              Cancel
            </button>
            <button
              (click)="saveQuestionChanges(qIndex)"
              mat-flat-button
              color="primary"
              [disabled]="isQuestisaveButtonDisabled(qIndex)"
            >
              Save
            </button>
          </div>
          <div class="qqeditview-questions-item-q-controls-back">
            <button
              (click)="destroyQuestionChanges(qIndex)"
              mat-flat-button
              color="warn"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
      <div class="qqeditview-questions-item-a">
        <div
          *ngFor="let answer of question.data.QuoteQuestionAnswers; let aIndex = index"
          class="qqeditview-questions-item-a-item"
        >
          <app-grow-input
            [control]="question.answersNameControl[aIndex]"
            (inputEmitter)="answerNameControlInput($event, qIndex, aIndex)"
            (blurEmitter)="answerNameControlBlur($event, qIndex, aIndex)"
            [useNativeInput]="true"
            minWidth="100%"
            maxWidth="100%"
            class="qqeditview-questions-item-q-name"
          ></app-grow-input>
          <app-korp-picker
            [control]="question.answersKitOrPartControl[aIndex]"
          >
          </app-korp-picker>
          <ng-container
            *ngIf="question.answersKitOrPartControl[aIndex].value.Part_guid != null"
          >
            <mat-form-field
              appearance="outline"
              class="qqeditview-questions-item-ff qqeditview-questions-item-phase"
            >
              <mat-label>Phase</mat-label>
              <mat-select
                [formControl]="question.answersPartPhaseControl[aIndex]"
              >
                <mat-option
                  *ngFor="let kpp of kitPartPhases"
                  [value]="kpp.valueOf()"
                  class="qqeditview-questions-item-ff-sel"
                >
                  {{ kpp }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
          <mat-form-field
            appearance="outline"
            class="qqeditview-questions-item-ff"
          >
            <textarea
              matInput
              rows="2"
              [formControl]="question.answersDescControl[aIndex]"
              class="qqeditview-questions-item-ff-in"
            ></textarea>
          </mat-form-field>
          <div class="qqeditview-questions-item-q-controls">
            <div class="qqeditview-questions-item-q-controls-front">
              <button
                (click)="resetAnswerControls(qIndex, aIndex)"
                mat-flat-button
                color="warn"
                [disabled]="isAnswerCancelButtonDisabled(qIndex, aIndex)"
              >
                Cancel
              </button>
              <button
                (click)="saveAnswerChanges(qIndex, aIndex)"
                mat-flat-button
                color="primary"
                [disabled]="isAnswerSaveButtonDisabled(qIndex, aIndex)"
              >
                Save
              </button>
            </div>
            <div class="qqeditview-questions-item-q-controls-back">
              <button
                (click)="destroyAnswer(qIndex, aIndex)"
                mat-flat-button
                color="warn"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="quotesview-questions-item-add">
        <button
          (click)="newQuoteQuestionAnswerIndex = qIndex; newQuoteAnswerOpen = true;"
          mat-mini-fab
          class="quotesview-questions-item-add-button"
        >
          <i class="fas fa-plus"></i>
        </button>
      </div>
    </mat-card>
  </div>

  <app-bottom-sheet
    [open]="newQuoteQuestionOpen"
    (sheetClosed)="newQuoteQuestionOpen=false"
    [sheetWidth]="screenSize.getClassesArray().includes('smd')? '100vw' : '75vw'"
    class="qqeditview-newq"
  >
    <h1>
      Create Quote Question
      <mat-spinner
        *ngIf="newQuoteQuestionLoading"
        [diameter]="30"
        class="qqeditview-newq-spinner"
      ></mat-spinner>
    </h1>
    <div class="qqeditview-newq-controls">
      <mat-form-field
        appearance="outline"
        class="qqeditview-newq-ff qqeditview-newq-name"
      >
        <mat-label>Name</mat-label>
        <input
          [formControl]="newQuoteQuestionNameControl"
          [disabled]="newQuoteQuestionLoading"
          matInput
          type="text"
          class="qqeditview-newq-ff-in"
        />
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        class="quotesview-create-ff qqeditview-newq-type"
      >
        <mat-label>Type</mat-label>
        <mat-select
          [formControl]="newQuoteQuestionTypeControl"
          [disabled]="newQuoteQuestionLoading"
        >
          <mat-option
            *ngFor="let qqt of quoteQuestionTypes"
            [value]="qqt.valueOf()"
            >{{qqt}}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        class="qqeditview-newq-ff qqeditview-newq-desc"
      >
        <mat-label>Description</mat-label>
        <textarea
          [formControl]="newQuoteQuestionDescControl"
          [disabled]="newQuoteQuestionLoading"
          matInput
          type="text"
          class="qqeditview-newq-ff-in"
        ></textarea>
      </mat-form-field>
    </div>
    <span *ngIf="newQuoteQuestionErrorMsg != null" class="qqeditview-newq-error"
      >*{{newQuoteQuestionErrorMsg}}</span
    >
    <div class="qqeditview-newq-actions">
      <button
        (click)="createQuoteQuestion()"
        [disabled]="!newQuoteQuestionValid()"
        color="primary"
        mat-flat-button
        class="qqeditview-newq-actions-submit"
      >
        Create Question
      </button>
    </div>
  </app-bottom-sheet>

  <app-create-qqanswer
    [open]="newQuoteAnswerOpen"
    [quoteQuestion]="newQuoteQuestionAnswerIndex>=0?quoteQuestions[newQuoteQuestionAnswerIndex].data.QuoteQuestion:null"
    (sheetClosed)="newQuoteAnswerOpen=false"
    (answerCreated)="answerCreated($event)"
  >
  </app-create-qqanswer>
</div>