<div class="container pb-5">
  <div class="card my-5 shadow-lg">
    <ng-container *ngIf="!receiptSelected">
      <div class="row m-0 pt-2 pb-1" id="headingInfo">
        <div class="col-8">
          <h2>Pending Receipts</h2>
        </div>
        <div class="col-4">
          <a
            class="btn btn-primary mb-2 mr-2 float-right text-light"
            routerLink="/Material/Receiving/Create"
            >New Receipt</a
          >
        </div>
        <div
          class="col-12 text-center font-weight-bold"
          *ngIf="receiptsPending.length === 0"
        >
          You're all caught up! No Pending Receipts.
        </div>
        <div class="col-6" *ngIf="receiptsPending.length !== 0">
          <div class="card m-3 border-0">
            <form>
              <div class="form-group m-0">
                <label for="poSearch"> PO Search </label>
                <input
                  class="form-control"
                  name="poSearch"
                  [(ngModel)]="receiptPendingSearch"
                  [typeahead]="receiptsPending"
                  typeaheadOptionField="purchaseOrderNo"
                  autocomplete="off"
                />
              </div>
            </form>
          </div>
        </div>
        <div class="col-6">
          <div class="card mx-3 mt-4 mb-3 border-0">
            <form>
              <div>
                <mat-form-field appearance="fill" style="width: 100%">
                  <mat-label>Profit Center</mat-label>
                  <mat-select [(value)]="receiptProfitCenterSelected">
                    <mat-option selected value=""> All </mat-option>
                    <mat-option
                      *ngFor="let profitCenter of receiptProfitCenters"
                      [value]="profitCenter.code"
                    >
                      {{ profitCenter.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="row m-0 p-3" id="pendingReceipts">
        <ng-container *ngFor="let profitCenter of filterProfitCenter()">
          <div class="col-12">
            <h3>
              Profit Center: {{ profitCenter.code }} - {{ profitCenter.name }}
            </h3>
          </div>

          <ng-container
            *ngFor="
              let receipt of filterByProfitCenter(profitCenter.code);
              let receiptIndex = index
            "
          >
            <div
              class="col-sm-4 pb-3"
              *ngIf="
                receiptPendingSearch === '' ||
                receipt.purchaseOrderNo.includes(receiptPendingSearch)
              "
            >
              <div class="card shadow-lg">
                <div class="card-body">
                  <h5 class="card-title">
                    PO: {{ receipt.purchaseOrderNo }}
                    <span class="badge badge-pill badge-dark float-right">
                      {{ receipt.lines.length }} Items
                    </span>
                  </h5>
                  <h5>Receipt Date: {{ receipt.receiptDate | date }}</h5>
                  <h5>Invoice No: {{ receipt.invoiceNo }}</h5>
                  <h5>Vendor: {{ receipt.vendor }}</h5>
                  <h5>BatchNo: {{ receipt.batchNo }}</h5>
                  <h5>
                    Warehouse:
                    <span
                      [ngStyle]="{ Color: receipt.whse === '003' ? 'red' : '' }"
                      >{{ receipt.whse }}</span
                    >
                  </h5>
                  <div class="row">
                    <div class="col-10 pr-0">
                      <button
                        class="btn btn-primary btn-block"
                        (click)="receiptChosen(receipt)"
                      >
                        Pick me!
                      </button>
                    </div>
                    <div class="col-2">
                      <a
                        class="btn px-0"
                        (click)="openModal(deleteBatch, receipt.batchNo)"
                        ><i class="fas fa-trash-alt fa-lg text-danger"></i
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <div class="row m-0 p-3" id="historyLog">
        <div class="col-6">
          <h2>Receving History</h2>
        </div>
        <div class="col-6 text-right">
          <button class="btn btn-primary" (click)="getReceiptsHist()">
            Refresh history
          </button>
        </div>
        <div class="col-3">
          <label for="IS-select"> <b>Internal Status</b> </label>
          <select
            [(ngModel)]="IntStatus"
            (change)="logStatus()"
            class="form-control"
            name="IS-select"
            id="IS-select"
            [disabled]="loadingHistory"
          >
            <option value="All">All</option>
            <option value="Success">Success</option>
            <option value="Pending">Pending</option>
            <option value="Error">Error</option>
          </select>
        </div>
        <div class="col-3">
          <label for="OrderDate"> <b>Earliest Received Date</b> </label>
          <input
            type="text"
            class="form-control"
            id="OrderDate"
            name="invoice"
            bsDatepicker
            [bsConfig]="bsConfig"
            [(bsValue)]="LastRecevingDate"
            [disabled]="loadingHistory"
          />
        </div>
        <div class="col-3">
          <label for="PONo"> <b>PO #</b> </label>
          <input
            class="form-control"
            id="PONo"
            name="PONo"
            [(ngModel)]="poNumFilter"
            autocomplete="off"
            [disabled]="loadingHistory"
          />
        </div>
        <div class="col-3">
          <label for="BatchNo"> <b>Batch #</b> </label>
          <input
            class="form-control"
            id="BatchNo"
            name="BatchNo"
            [(ngModel)]="BatchNumFilter"
            autocomplete="off"
            [disabled]="loadingHistory"
          />
        </div>
      </div>
      <div class="row m-0 p-3">
        <table class="table table-striped table-hover">
          <thead class="text-center">
            <tr>
              <td>Received Date</td>
              <td>Item Code</td>
              <td>Batch #</td>
              <td>QTY</td>
              <td>PO #</td>
              <td>Warehouse</td>
              <td
                (click)="sortReceiptHistByError()"
                [ngStyle]="{ cursor: 'pointer' }"
              >
                Internal Status
              </td>
            </tr>
          </thead>
          <tbody class="text-center">
            <ng-container
              *ngFor="let step of sortedReceiptsByTypeAndDate; let i = index"
            >
              <tr
                *ngIf="historyFilters(step)"
                [ngStyle]="{ color: changeTextColorIfError(step) }"
              >
                <td>{{ step.ReceivedDate | date: 'MM/dd/yyyy' }}</td>
                <td>{{ step.ItemCode }}</td>
                <td>{{ step.BatchNo }}</td>
                <td>{{ step.Qty }}</td>
                <td>{{ step.PONumber }}</td>
                <td>{{ step.Warehouse }}</td>
                <td>{{ step.internalStatus }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </ng-container>

    <ng-container *ngIf="receiptSelected">
      <div class="p-2" id="poHeader">
        <form>
          <div class="form-group row m-0">
            <h3>Update Receipt</h3>
          </div>
          <div class="form-group row m-0">
            <div
              class="col-12 text-danger text-center"
              *ngIf="
                receiptsPending[receiptSelectedIndex].purchaseOrderNo === '003'
              "
            >
              <b>WARNING:</b> This PO is currently pointing to Warehouse 003.
              Please Confirm if this is the right destination.
            </div>
            <label
              for="purchaseOrderNo"
              class="col-12 col-md-3 pt-2 font-weight-bold"
            >
              Purchase Order
            </label>
            <div class="col-12 col-md-3">
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                id="purchaseOrderNo"
                value="{{
                  receiptsPending[receiptSelectedIndex].purchaseOrderNo
                }}"
              />
            </div>
            <!--            <label for="poDate" class="col-12 col-md-3 pt-2 font-weight-bold"> PO Date </label>-->
            <!--            <div class="col-12 col-md-3">-->
            <!--              <input-->
            <!--                type="text"-->
            <!--                class="form-control" id="poDate" name="poDate"-->
            <!--                bsDatepicker-->
            <!--                [bsConfig]="bsConfig"-->
            <!--                (bsValueChange)="this.logPODate($event, receiptsPending[receiptSelectedIndex])"-->
            <!--                [(bsValue)]="selectedPODate"-->
            <!--                disabled>-->
            <!--              <ng-container *ngIf="badPODate">-->
            <!--                <label class="text-danger">Bad Purchase Order Date. current set date: {{receiptsPending[receiptSelectedIndex].poDate.substring(0, 10)}}</label>-->
            <!--              </ng-container>-->
            <!--            </div>-->
            <!--          </div>-->
            <!--          <div class="form-group row m-0">-->
            <label for="vendor" class="col-12 col-md-3 pt-2 font-weight-bold">
              Vendor No.
            </label>
            <div class="col-12 col-md-3">
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                id="vendor"
                value="{{ receiptsPending[receiptSelectedIndex].vendor }}"
              />
            </div>
          </div>
          <div class="form-group row m-0">
            <label
              for="invoiceNo"
              class="col-12 col-md-3 pt-2 font-weight-bold"
            >
              Invoice No.
            </label>
            <div class="col-12 col-md-3">
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                id="invoiceNo"
                name="invoiceNo"
                value="{{ receiptsPending[receiptSelectedIndex].invoiceNo }}"
              />
            </div>
            <!--          </div>-->
            <!--          <div class="form-group row m-0">-->
            <label
              for="receiptDate"
              class="col-12 col-md-3 pt-2 font-weight-bold"
            >
              Receipt Date
            </label>
            <div class="col-12 col-md-3">
              <input
                type="text"
                class="form-control"
                id="receiptDate"
                name="receiptDate"
                bsDatepicker
                [bsConfig]="bsConfig"
                (bsValueChange)="
                  this.logDate($event, receiptsPending[receiptSelectedIndex])
                "
                [(bsValue)]="selectedReceiptDate"
              />
              <ng-container *ngIf="badReceiptDate">
                <label class="text-danger"
                  >Bad Receipt Date. current set date:
                  {{
                    receiptsPending[receiptSelectedIndex].receiptDate.substring(
                      0,
                      10
                    )
                  }}</label
                >
              </ng-container>
            </div>
          </div>
        </form>
      </div>
      <div class="px-3 pt-3" id="poItems">
        <ul class="list-group list-group-striped">
          <ng-container
            *ngFor="
              let line of receiptsPending[receiptSelectedIndex].lines;
              let lineIndex = index;
              let isFirst = first
            "
          >
            <div class="list-group list-group-horizontal" *ngIf="isFirst">
              <a
                class="list-group-item py-1 col-3 col-md-3 col-lg-3 font-weight-bold text-light bg-primary"
              >
                Item
              </a>
              <a
                class="list-group-item py-1 col-3 col-md-3 col-lg-3 font-weight-bold text-light bg-primary"
              >
                Description
              </a>
              <a
                class="list-group-item py-1 col-3 col-md-3 col-lg-3 font-weight-bold text-center text-light bg-primary"
              >
                Quantity Available
              </a>
              <a
                class="list-group-item py-1 col-3 col-md-3 col-lg-3 font-weight-bold text-center text-light bg-primary"
              >
                Quantity Received
              </a>
            </div>
            <ul class="list-group list-group-horizontal">
              <li
                class="list-group-item py-2 col-3 col-md-3 col-lg-3 border-0 p-1 bg-transparent"
              >
                {{ line.itemCode | titlecase }}
              </li>
              <li
                class="list-group-item py-2 col-3 col-md-3 col-lg-3 border-0 p-1 bg-transparent"
              >
                {{ line.description | titlecase }}
              </li>
              <li
                class="list-group-item py-2 col-3 col-md-3 col-lg-3 border-0 p-1 text-center bg-transparent"
              >
                {{ line.qtyAvailable }}
                <small
                  ><br />
                  Qty Sent: {{ line.qtyReceived }}
                </small>
              </li>
              <li
                class="list-group-item py-2 col-3 col-md-3 col-lg-3 border-0 p-1 text-center bg-transparent"
              >
                <input
                  type="number"
                  class="form-control text-center"
                  id="qtyReceived"
                  name="qtyReceived"
                  (click)="$event.target.select()"
                  [(ngModel)]="line.qtyCounted"
                  [ngClass]="{
                    'is-invalid':
                      line.qtyCounted > line.qtyAvailable + line.qtyReceived
                  }"
                />
                <div class="invalid-feedback">Will Be Over Received</div>
              </li>
            </ul>
          </ng-container>
        </ul>
      </div>
      <div class="px-3 my-3" id="poLoadedFooter">
        <div class="row">
          <div class="col-3">
            <div class="float-left">
              <button
                class="btn btn-secondary btn-md"
                (click)="cancelReceipt()"
              >
                Cancel Receiver
              </button>
            </div>
          </div>
          <div class="col-6 text-center">
            <a
              class="btn p-0 mr-3"
              (click)="openModal(fileExplorer)"
              (click)="fileNames()"
              ><i class="far fa-folder-open fa-3x text-dark"></i
            ></a>
            <a
              class="btn"
              (click)="openModal(AddItems)"
              (click)="purchaseLines()"
              ><i class="fas fa-plus-square fa-3x text-info"></i
            ></a>
          </div>
          <div class="col-3">
            <div class="float-right">
              <button
                class="btn btn-primary btn-md"
                (click)="putUpdateReceiptOfGoods()"
                [disabled]="
                  itemsOverReceived() ||
                  receiptsPending[receiptSelectedIndex].purchaseOrderNo ===
                    '003'
                "
              >
                <span *ngIf="!receiptUpdateLoading">Accept</span
                ><span *ngIf="receiptUpdateLoading"
                  ><i class="fas fa-sync fa-spin"></i
                ></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #deleteBatch>
  <div class="container pb-3">
    <div class="row p-2">
      <div class="col-10 offset-1 text-center">
        <h2>Delete Receipt Batch: {{ receiptDeleteBatchNo }}</h2>
      </div>
      <div class="col-1">
        <button type="button" class="close" (click)="closeModal()">
          <span><i class="fas fa-window-close"></i></span>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-center">
        <h2>
          <strong
            >Are you sure you want to delete batch
            {{ receiptDeleteBatchNo }}?</strong
          >
        </h2>
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-center mt-3 mb-3">
        <button
          type="button"
          class="btn btn-secondary btn-lg mr-2"
          (click)="closeModal()"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary btn-lg ml-2"
          (click)="putDeleteReceiptOfGoods()"
        >
          <span *ngIf="!receiptDeleteLoading">Delete</span>
          <span *ngIf="receiptDeleteLoading"
            ><i class="fas fa-sync fa-spin"></i
          ></span>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #fileExplorer>
  <div class="container pb-3">
    <div class="row p-2">
      <div class="col-10 offset-1 text-center">
        <h2>
          File Contents for PO:
          {{
            receiptsPending[this.receiptSelectedIndex].purchaseOrderNo
              | titlecase
          }}
        </h2>
      </div>
      <div class="col-1">
        <button type="button" class="close" (click)="closeModal()">
          <span><i class="fas fa-window-close"></i></span>
        </button>
      </div>
    </div>

    <div class="row mb-1" id="files">
      <div class="col-3 pt-2">
        <div class="card text-center">
          <label class="pt-2 mb-0">
            <i class="fas fa-file-upload fa-4x text-info"></i>
            <input
              type="file"
              (change)="fileChosen($event)"
              style="display: none"
            />
          </label>
          <small>Upload</small>
          <ng-container *ngFor="let file of fileArray">
            <br />
            <small> {{ file.name }}</small>
          </ng-container>
        </div>
      </div>
      <div class="col-3 pt-2" *ngFor="let file of fileList">
        <div class="card text-center">
          <a class="btn p-0 pt-2" (click)="fileDownload(file.name)"
            ><i class="fas fa-file-alt fa-4x"></i
          ></a>
          <small>{{ file.name }}</small>
        </div>
      </div>
    </div>

    <form>
      <div class="form-row pt-2">
        <div class="col">
          <button class="btn btn-primary float-right" (click)="closeModal()">
            Close
          </button>
          <button
            class="btn btn-info float-right mr-2"
            (click)="fileUpload()"
            *ngIf="fileArray.length > 0"
          >
            <span *ngIf="!fileLoading">Upload</span
            ><span *ngIf="fileLoading"
              ><i class="fas fa-sync fa-spin"></i
            ></span>
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #AddItems>
  <div class="container pb-3">
    <div class="row p-2">
      <div class="col-10 offset-1 text-center">
        <h2>
          Add Items from PO:
          {{
            receiptsPending[this.receiptSelectedIndex].purchaseOrderNo
              | titlecase
          }}
        </h2>
      </div>
      <div class="col-1">
        <button type="button" class="close" (click)="closeModal()">
          <span><i class="fas fa-window-close"></i></span>
        </button>
      </div>
    </div>

    <div class="row mb-1">
      <div class="px-3 pt-3 col-12" id="poItems">
        <ul class="list-group list-group-striped">
          <ng-container
            *ngFor="
              let line of poLines;
              let lineIndex = index;
              let isFirst = first
            "
          >
            <div class="list-group list-group-horizontal" *ngIf="isFirst">
              <a
                class="list-group-item py-1 col-3 col-md-3 col-lg-3 font-weight-bold text-light bg-primary"
              >
                Item
              </a>
              <a
                class="list-group-item py-1 col-3 col-md-3 col-lg-3 font-weight-bold text-light bg-primary"
              >
                Description
              </a>
              <a
                class="list-group-item py-1 col-4 col-md-4 col-lg-4 font-weight-bold text-center text-light bg-primary"
              >
                Quantity To Add
              </a>
              <a
                class="list-group-item py-1 col-2 col-md-2 col-lg-2 font-weight-bold text-center text-light bg-primary"
              >
                Action
              </a>
            </div>
            <ul class="list-group list-group-horizontal">
              <li
                class="list-group-item py-2 col-3 col-md-3 col-lg-3 border-0 p-1 bg-transparent"
              >
                {{ line.itemCode | titlecase }}
              </li>
              <li
                class="list-group-item py-2 col-3 col-md-3 col-lg-3 border-0 p-1 bg-transparent"
              >
                {{ line.description | titlecase }}
              </li>
              <li
                class="list-group-item py-2 col-4 col-md-4 col-lg-4 border-0 p-1 text-center bg-transparent"
              >
                {{ line.qtyAvailable }}
              </li>
              <li
                class="list-group-item py-2 col-2 col-md-2 col-lg-2 border-0 p-1 text-center bg-transparent"
              >
                <button
                  class="btn btn-info"
                  (click)="addPurchaseOrderLine(line, lineIndex)"
                >
                  Add
                </button>
              </li>
            </ul>
          </ng-container>
          <ng-container *ngIf="poLines.length == 0">
            <li
              class="list-group-item py-2 col-12 col-md-12 col-lg-12 border-0 p-1 bg-transparent text-center"
            >
              No Items to Add (All items received on PO)
            </li>
          </ng-container>
        </ul>
      </div>
    </div>

    <form>
      <div class="form-row pt-2">
        <div class="col">
          <button class="btn btn-primary float-right" (click)="closeModal()">
            Close
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
