import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import ContextQuoteDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextQuoteDtoInterface';
import QuoteDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteDtoInterface';
import { SageApiService } from 'src/app/_services/sageApi/sageApi.service';
import { AddItemsOutputInterface } from '../KitOrPartPickerBotSheetComponent/KitOrPartPickerBotSheetComponent';
import ContextQuoteKitPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextQuoteKitPartDtoInterface';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import QuoteDataEditableInterface from '../../../_services/QuoteEditableService/interfaces/QuoteDataEditableInterface';
import QuoteKitPartEditableInterface, {
  QuoteKitPartEditableDataInterface,
} from '../../../_services/QuoteEditableService/interfaces/QuoteKitPartEditableInterface';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from '../../Platform/confirm-modal/confirm-modal.component';
import ContextQuotePartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextQuotePartInterface';
import QuotePartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuotePartDtoInterface';
import PartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/PartDtoInterface';
import CustomQuoteKitToAddInterface from 'src/app/_services/sageApi/interfaces/pullReport/CustomQuoteKitToAddInterface';
import QuoteKitPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/QuoteKitPartDtoInterface';
import ContextPartDtoInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextPartDtoInterface';
import {
  FinalKitInterface,
  PartBreakdownItemGroupInterface,
  PartBreakdownItemInterface,
  QuoteEditableService,
} from 'src/app/_services/QuoteEditableService/QuoteEditableService';

interface AuditPartsInterface {
  PartCode: string;
  Quantity: number;
  IndividualQuantity: number;
  Cost: number;
  TotalCost: number;
  Phase: string;
  Kit: string;
  KitParents: string; // (string[]).join(", ")
  Desc: string;
  Tags: string; // (string[]).join(", ")
  Location: string;
}

@Component({
  selector: 'app-quoteslicer',
  templateUrl: './QuoteDataSlicerComponent.html',
  styleUrls: ['./QuoteDataSlicerComponent.css', '../QuotingTheme.scss'],
})
export class QuoteDataSlicerComponent implements OnInit, OnChanges {
  @Input() partGroups: PartBreakdownItemGroupInterface[] = [];
  @Input() pdfTitle: string | null = null;
  pbiGroups: PartBreakdownItemGroupInterface[] = this.partGroups;

  allParts: AuditPartsInterface[] = [];
  filteredPartsCount = 0;
  filteredPartsCost = 0;
  filteredRawPartsCost = 0;

  kitInfo: FinalKitInterface[] = [];

  constructor(
    private screenSizeService: ScreenSizeService,
    private router: Router,
    private api: SageApiService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private qe: QuoteEditableService
  ) {}

  ngOnInit(): void {
    this.allParts = this.getAuditParts(this.pbiGroups);
    this.kitInfo = this.qe.getFinalKits();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.pbiGroups = this.partGroups;
  }

  getAuditParts(breakdownGroups: PartBreakdownItemGroupInterface[]) {
    const flat: AuditPartsInterface[] = [];
    for (const group of breakdownGroups) {
      for (const item of group.items) {
        flat.push({
          PartCode: item.data.QuotePart.QuotePart.QuotePart_Code,
          Quantity: item.quantity,
          IndividualQuantity: item.data.QuoteKitPart.QuoteKitPart_Quantity,
          Cost: item.data.QuotePart.QuotePart.QuotePart_Cost,
          TotalCost:
            item.quantity * item.data.QuotePart.QuotePart.QuotePart_Cost,
          Phase: item.data.QuoteKitPart.QuoteKitPart_Phase,
          Kit: item.parents[item.parents.length - 1],
          KitParents: item.parents.join(', '),
          Desc: item.data.QuotePart.QuotePart.QuotePart_Desc,
          Tags: item.data.QuotePart.QuotePartTags.map(t => t.Tag.Tag_Name).join(
            ', '
          ),
          Location: item.location.BuildLocation_Code,
        });
      }
    }
    return flat;
  }

  normalizeMoney(row: AuditPartsInterface, val: string): string {
    return `$${parseFloat(val).toFixed(2)}`;
  }

  partsFiltered(pts: readonly AuditPartsInterface[]) {
    let newFilteredPartsCount = 0;
    let newFilteredPartsCost = 0;
    let newFilteredRawPartsCost = 0;

    // Loop over pts and sum up the count and cost
    for (const pt of pts) {
      newFilteredPartsCount += pt.Quantity;
      newFilteredPartsCost += pt.Tags.includes('Labor')
        ? pt.Quantity *
          pt.Cost *
          this.qe.quote.data.Quote.Quote_LaborMargin *
          this.qe.quote.data.Quote.Quote_GibsonMargin
        : pt.Quantity *
          pt.Cost *
          this.qe.quote.data.Quote.Quote_MaterialMargin *
          this.qe.quote.data.Quote.Quote_TaxMargin *
          this.qe.quote.data.Quote.Quote_GibsonMargin;
      newFilteredRawPartsCost += pt.TotalCost;
    }

    this.filteredPartsCount = newFilteredPartsCount;
    this.filteredPartsCost = newFilteredPartsCost;
    this.filteredRawPartsCost = newFilteredRawPartsCost;
  }
}
