<div class="container pb-5">
  <div class="card my-5 shadow-lg">
    <!--    <div class="row">-->
    <!--      <div class="col-sm-4">-->
    <!--        <div class="card m-3 border-0">-->
    <!--          <button class="btn btn-primary">Test Button</button>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="row">
      <div class="col-12 mt-4 ml-4">
        <h2><b>Material Requisition</b></h2>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3">
        <div class="card m-3 border-0">
          <form>
            <div class="form-group m-0">
              <!--              <div class="row">-->
              <!--                <div class="col-7">-->
              <label for="purchaseOrderNo"> Purchase Order No. </label>
              <input
                class="form-control"
                name="purchaseOrderNo"
                [(ngModel)]="purchaseOrderSearch"
                (typeaheadOnSelect)="purchaseOrderSelected($event)"
                [typeahead]="ExistingPOList"
                typeaheadOptionField="PurchaseOrderNo"
                autocomplete="off"
                [disabled]="purchaseOrderListLoading"
              />
              <!--                </div>-->
              <!--                <div class="col-4 mt-4">-->
              <!--                  <button class="btn mt-1 btn-sm btn-primary" (click)="getnextPO()">Next PO#</button>-->
              <!--                </div>-->
              <!--              </div>-->
            </div>
          </form>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="card m-3 border-0">
          <form>
            <div class="form-group m-0">
              <label for="ShipToAddress"> Ship to Address </label>
              <input
                class="form-control"
                name="ShipToAddress"
                [(ngModel)]="ShipToAddressSearch"
                (typeaheadOnSelect)="ShiptoAddressSelected($event)"
                [typeahead]="ShiptoAddressList"
                typeaheadOptionField="address"
                autocomplete="off"
                disabled
              />
            </div>
            <ng-container *ngIf="hasWhse">
              <div class="col-12 text-danger text-center">
                <b>WARNING:</b> The selected address does not have Warehouse
                connected to it. Please set a warehouse to this address and try
                again later.
              </div>
            </ng-container>
          </form>
        </div>
      </div>
      <div class="col-sm-2">
        <div class="card m-3 border-0">
          <form>
            <div class="form-group m-0">
              <label for="ShipToAddress"> Whse </label>
              <input
                class="form-control"
                name="ShipToAddress"
                [(ngModel)]="SelectedAddress.whse"
                autocomplete="off"
                disabled
              />
            </div>
          </form>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="card m-3 border-0">
          <form>
            <label for="OrderDate"> Order/Required Date </label>
            <input
              type="text"
              class="form-control"
              id="OrderDate"
              name="invoice"
              bsDatepicker
              [bsConfig]="bsConfig"
              (bsValueChange)="
                this.logDate($event, purchaseOrderHeader.invoiceDate)
              "
              [(bsValue)]="selectedOrderDate"
            />
          </form>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col mx-2 my-2">
        <button
          class="btn btn-sm btn-primary"
          (click)="showfinishedPOs = !showfinishedPOs"
        >
          {{ showfinishedPOs ? 'Hide Finished Items' : 'Show Finished Items' }}
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table class="table table-striped table-hover">
          <thead class="text-center">
            <tr>
              <td>ItemCode</td>
              <td>Item Code Desc.</td>
              <td>QTY on Hand</td>
              <td>Requested</td>
              <td>Issued</td>
              <td>Requisition</td>
              <!--            <td>UnitCost</td>-->
              <!--            <td>G/L Account</td>-->
              <!--            <td>Extension</td>-->
              <!--            <td>Comment</td>-->
              <!--            <td>Action</td>-->
            </tr>
          </thead>
          <tbody class="text-center">
            <ng-container *ngFor="let step of POItemList; let i = index">
              <tr
                *ngIf="showfinishedPOs || !step.itemFinished"
                [ngStyle]="{ 'background-color': badAmount(step) ? 'red' : '' }"
                id="table-info"
              >
                <td>
                  <input
                    class="form-control form-control-sm text-center"
                    [(ngModel)]="step.ItemCode"
                    (typeaheadOnSelect)="ItemCodeSelected($event, step)"
                    [typeahead]="ItemCodeList"
                    typeaheadOptionField="ItemCode"
                    autocomplete="off"
                    [disabled]="
                      ItemCOdeListLoading || hasWhse || step.LineKey != ''
                    "
                  />
                </td>
                <td>
                  <input
                    class="form-control form-control-sm text-center"
                    [(ngModel)]="step.ItemCodeDesc"
                    (typeaheadOnSelect)="ItemCodeSelected($event, step)"
                    [typeahead]="ItemCodeList"
                    typeaheadOptionField="ItemCode"
                    autocomplete="off"
                    disabled
                  />
                </td>
                <td>
                  <input
                    class="form-control form-control-sm text-center"
                    style="width: 75px"
                    type="number"
                    [(ngModel)]="step.QtyOnHand"
                    (click)="calcExtention(step, $event)"
                    disabled
                  />
                </td>
                <td>
                  <input
                    class="form-control form-control-sm text-center"
                    style="width: 75px"
                    type="number"
                    [(ngModel)]="step.Requested"
                    (click)="calcExtention(step, $event)"
                    disabled
                  />
                </td>
                <td>
                  <input
                    class="form-control form-control-sm text-center"
                    style="width: 75px"
                    type="number"
                    [(ngModel)]="step.Issued"
                    (change)="calcExtention(step, $event)"
                    disabled
                  />
                </td>
                <td>
                  <input
                    class="form-control form-control-sm text-center"
                    style="width: 75px"
                    type="number"
                    [(ngModel)]="step.newIssued"
                    (change)="calcExtention(step, $event)"
                    min="0"
                    [disabled]="
                      ItemCOdeListLoading || hasWhse || step.itemFinished
                    "
                  />
                </td>
                <!--              <td>-->
                <!--                <input class="form-control form-control-sm text-center" style="width: 75px;" type="number"-->
                <!--                       [(ngModel)]="step.UnitCost" (click)="calcExtention(step,$event)" disabled>-->
                <!--              </td>-->
                <!--              <td>-->
                <!--                <input class="form-control form-control-sm text-center" name="purchaseOrderNo" [(ngModel)]="step.GLAccount"-->
                <!--                       (typeaheadOnSelect)="GLaccountSelected($event,step)"-->
                <!--                       [typeahead]="GLAccountList" typeaheadOptionField="GLAccount"-->
                <!--                       autocomplete="off" disabled>-->
                <!--              </td>-->
                <!--              <td>-->
                <!--                <input class="form-control form-control-sm text-center" style="width: 75px;" type="number"-->
                <!--                       [(ngModel)]="step.Extension" (click)="$event.target.select()" disabled>-->
                <!--              </td>-->
                <!--              <td>-->
                <!--                <input class="form-control form-control-sm" type="text" maxlength="50"-->
                <!--                       [(ngModel)]="step.Comment" (click)="$event.target.select()" [disabled]="ItemCOdeListLoading || hasWhse"/>-->
                <!--              </td>-->
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
    <!--    <div class="row justify-content-center mb-2">-->
    <!--      <div class="col-3 text-center">-->
    <!--        <button class="btn btn-primary" (click)="addPOline()" [disabled]="ItemCOdeListLoading || hasWhse">AddLine</button>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="row justify-content-end mb-2">
      <!--      <div class="col-3 text-center">-->
      <!--        <button class="btn btn-primary" (click)="clearPO()" [disabled]="ItemCOdeListLoading">Clear</button>-->
      <!--      </div>-->
      <div class="col-3 text-center">
        <button
          class="btn btn-primary mr-2"
          (click)="clearPO()"
          [disabled]="ItemCOdeListLoading"
        >
          Reset
        </button>
        <button
          class="btn btn-primary ml-2"
          (click)="submitRequisition()"
          [disabled]="ItemCOdeListLoading || posubmitCheck()"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</div>
