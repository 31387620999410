import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AlertifyService } from '../../../_services/alertify/alertify.service';
import { SageApiService } from '../../../_services/sageApi/sageApi.service';
import { AuthService } from '../../../_services/auth/auth.service';
import { ScreenSizeService } from 'src/app/_services/ScreenSizeService/ScreenSizeService';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MultiIsLoadingService } from 'src/app/_services/multi-is-loading/multi-is-loading.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { BaseModalService } from 'src/app/_services/BaseModalService/BaseModalService';
import ContextCustomerInterface from 'src/app/_services/sageApi/interfaces/pullReport/ContextCustomerInterface';
import CustomerTypeInterface from 'src/app/_services/sageApi/interfaces/pullReport/CustomerTypeInterface';

interface CustomersRowInterface {
  AppCustomer_guid: number;
  AppCustomer_FirstName: FormControl;
  AppCustomer_LastName: FormControl;
  AppCustomer_Email: FormControl;
  AppCustomer_Phone: FormControl;
  AppCustomerType_guid: FormControl;
}

@Component({
  selector: 'app-customers-manager',
  templateUrl: './CustomersManagerComponent.html',
  styleUrls: ['./CustomersManagerComponent.scss'],
  providers: [
    { provide: 'resultsLoading', useClass: MultiIsLoadingService },
    { provide: 'saving', useClass: MultiIsLoadingService },
  ],
})
export class CustomersManagerComponent implements OnInit, AfterViewInit {
  customersRaw: ContextCustomerInterface[] = [];
  customerTypes: CustomerTypeInterface[] = [];
  customers: MatTableDataSource<CustomersRowInterface> = new MatTableDataSource(
    []
  );
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sorter: MatSort;
  displayedColumns: string[] = [
    'first',
    'last',
    'email',
    'phone',
    'type',
    'actions',
  ];

  customerNameFilterControl = new FormControl('');
  customerEmailFilterControl = new FormControl('');
  customerPhoneFilterControl = new FormControl('');

  createCustomerModalOpen = false;
  createCustomerLoading = false;
  ccFirstCtrl = new FormControl('', [Validators.required]);
  ccLastCtrl = new FormControl('', [Validators.required]);
  ccEmailCtrl = new FormControl('', [Validators.email]);
  ccPhoneCtrl = new FormControl('', [Validators.required]);
  ccTypeCtrl = new FormControl('', [Validators.required]);

  constructor(
    private alertify: AlertifyService,
    private sageApi: SageApiService,
    private authService: AuthService,
    public scr: ScreenSizeService,
    public cd: ChangeDetectorRef,
    @Inject('resultsLoading') public resultsLoading: MultiIsLoadingService,
    @Inject('saving') public saving: MultiIsLoadingService,
    private snkbr: MatSnackBar,
    private bm: BaseModalService
  ) {}

  ngOnInit(): void {
    this.loadCustomers();
    this.loadCustomerTypes();
    this.setFilter();

    this.customerNameFilterControl.valueChanges.subscribe(() => {
      this.setFilter();
    });
    this.customerEmailFilterControl.valueChanges.subscribe(() => {
      this.setFilter();
    });
    this.customerPhoneFilterControl.valueChanges.subscribe(() => {
      this.setFilter();
    });

    this.resultsLoading.loadingStateChange.subscribe(() => {
      this.setTheSorter();
    });
  }

  ngAfterViewInit(): void {
    this.setTheSorter();
    this.setFilter();
    console.log();
  }

  loadCustomers(): Observable<any> {
    const obs = this.resultsLoading.loadingUntilComplete(
      this.sageApi.pullReport('customers', {
        matchCriteria: ['customers'],
        exp: 60000 * 5, // Cache for 5 minutes - prolly won't change often
      })
    );
    obs.subscribe(
      (customers: ContextCustomerInterface[]) => {
        this.customersRaw = customers;

        // Now loop over customers and create a new array of CustomersRowInterface
        const customersRows: CustomersRowInterface[] = [];
        for (const customer of customers) {
          customersRows.push({
            AppCustomer_guid: customer.AppCustomer.AppCustomer_guid,
            AppCustomer_FirstName: new FormControl(
              customer.AppCustomer.AppCustomer_FirstName
            ),
            AppCustomer_LastName: new FormControl(
              customer.AppCustomer.AppCustomer_LastName
            ),
            AppCustomer_Email: new FormControl(
              customer.AppCustomer.AppCustomer_Email,
              [Validators.email]
            ),
            AppCustomer_Phone: new FormControl(
              customer.AppCustomer.AppCustomer_Phone
            ),
            AppCustomerType_guid: new FormControl(
              customer.AppCustomerType.AppCustomerType_guid
            ),
          });
        }
        this.customers = new MatTableDataSource(customersRows);
        this.setTheSorter();
        this.setFilter();
      },
      err => {
        console.log(err);
        this.snkbr.open('Failed to load customers', 'Dismiss', {
          duration: Infinity,
        });
      }
    );
    return obs;
  }

  loadCustomerTypes(): Observable<any> {
    const obs = this.resultsLoading.loadingUntilComplete(
      this.sageApi.pullReport('customer-types', {
        matchCriteria: ['customer-types'],
        exp: 60000 * 5, // Cache for 5 minutes - prolly won't change often
      })
    );
    obs.subscribe(
      (customerTypes: CustomerTypeInterface[]) => {
        this.customerTypes = customerTypes;
      },
      err => {
        console.log(err);
        this.snkbr.open('Failed to load customer types', 'Dismiss', {
          duration: Infinity,
        });
      }
    );
    return obs;
  }

  setTheSorter() {
    this.customers.paginator = this.paginator;
    this.customers.sort = this.sorter;
  }

  setFilter() {
    // Set the filterPredicate function to filter the data based on the filter controls
    this.customers.filterPredicate = (data, filter) => {
      const filterNameText = this.customerNameFilterControl.value
        .toLowerCase()
        .trim();
      const filterEmailText = this.customerEmailFilterControl.value
        .toLowerCase()
        .trim();
      const filterPhoneText = this.customerPhoneFilterControl.value
        .toLowerCase()
        .trim();

      const fname = data.AppCustomer_FirstName.value.toLowerCase().trim();
      const lname = data.AppCustomer_LastName.value.toLowerCase().trim();
      const fullName = `${fname} ${lname}`; // To make searching by full name easier

      // If all three are empty, return true to show all data
      const result =
        (filterNameText == '' || fullName.includes(filterNameText)) &&
        // If filterPhoneText is not empty, check if the AppCustomer_Phone includes the filterPhoneText
        (filterPhoneText == '' ||
          data.AppCustomer_Phone.value
            .toLowerCase()
            .includes(filterPhoneText)) &&
        (filterEmailText == '' ||
          data.AppCustomer_Email.value.toLowerCase().includes(filterEmailText));
      return result;
    };
    this.customers.filter = [
      this.customerNameFilterControl.value,
      this.customerEmailFilterControl.value,
      this.customerPhoneFilterControl.value,
    ].join(' ');
  }

  rowToCustomer(row: CustomersRowInterface): ContextCustomerInterface {
    // Find the customer in this.customersRaw
    const customer = this.customersRaw.find(
      c => c.AppCustomer.AppCustomer_guid === row.AppCustomer_guid
    );
    return customer;
  }

  customerTypeFromGuid(guid: string): CustomerTypeInterface {
    return this.customerTypes.find(ct => ct.AppCustomerType_guid === guid);
  }

  isRowChanged(row: CustomersRowInterface): boolean {
    const customer = this.rowToCustomer(row);
    if (
      customer.AppCustomer.AppCustomer_FirstName !==
        row.AppCustomer_FirstName.value ||
      customer.AppCustomer.AppCustomer_LastName !==
        row.AppCustomer_LastName.value ||
      customer.AppCustomer.AppCustomer_Email !== row.AppCustomer_Email.value ||
      customer.AppCustomer.AppCustomer_Phone !== row.AppCustomer_Phone.value ||
      customer.AppCustomerType.AppCustomerType_guid !==
        row.AppCustomerType_guid.value
    ) {
      return true;
    }
    return false;
  }

  isRowValid(row: CustomersRowInterface): boolean {
    if (
      row.AppCustomer_FirstName.invalid ||
      row.AppCustomer_LastName.invalid ||
      row.AppCustomer_Email.invalid ||
      row.AppCustomer_Phone.invalid ||
      row.AppCustomerType_guid.invalid
    ) {
      return false;
    }
    return true;
  }

  changedRows(): CustomersRowInterface[] {
    const rows = this.customers.data;
    const changedRows: CustomersRowInterface[] = [];
    for (const row of rows) {
      if (this.isRowChanged(row)) {
        changedRows.push(row);
      }
    }
    return changedRows;
  }

  resetRows(rows: CustomersRowInterface[]) {
    for (const row of rows) {
      const customer = this.rowToCustomer(row);
      row.AppCustomer_FirstName.setValue(
        customer.AppCustomer.AppCustomer_FirstName
      );
      row.AppCustomer_LastName.setValue(
        customer.AppCustomer.AppCustomer_LastName
      );
      row.AppCustomer_Email.setValue(customer.AppCustomer.AppCustomer_Email);
      row.AppCustomer_Phone.setValue(customer.AppCustomer.AppCustomer_Phone);
      row.AppCustomerType_guid.setValue(
        customer.AppCustomerType.AppCustomerType_guid
      );
    }
  }

  disableRows(rows: CustomersRowInterface[]) {
    for (const row of rows) {
      row.AppCustomer_FirstName.disable();
      row.AppCustomer_LastName.disable();
      row.AppCustomer_Email.disable();
      row.AppCustomer_Phone.disable();
      row.AppCustomerType_guid.disable();
    }
  }

  enableRows(rows: CustomersRowInterface[]) {
    for (const row of rows) {
      row.AppCustomer_FirstName.enable();
      row.AppCustomer_LastName.enable();
      row.AppCustomer_Email.enable();
      row.AppCustomer_Phone.enable();
      row.AppCustomerType_guid.enable();
    }
  }

  createCustomer() {
    this.createCustomerLoading = true;
    const newCustomer = {
      AppCustomer: {
        AppCustomer_FirstName: this.ccFirstCtrl.value,
        AppCustomer_LastName: this.ccLastCtrl.value,
        AppCustomer_Email: this.ccEmailCtrl.value,
        AppCustomer_Phone: this.ccPhoneCtrl.value,
      },
      AppCustomerType: {
        AppCustomerType_guid: this.ccTypeCtrl.value,
      },
    };
    this.saving
      .loadingUntilComplete(this.sageApi.postRequest('customer', newCustomer))
      .subscribe(
        (customer: ContextCustomerInterface) => {
          // Add the customer to this.customersRaw
          this.customersRaw.unshift(customer);

          // Add the customer to this.customers
          this.customers.data.unshift({
            AppCustomer_guid: customer.AppCustomer.AppCustomer_guid,
            AppCustomer_FirstName: new FormControl(
              customer.AppCustomer.AppCustomer_FirstName
            ),
            AppCustomer_LastName: new FormControl(
              customer.AppCustomer.AppCustomer_LastName
            ),
            AppCustomer_Email: new FormControl(
              customer.AppCustomer.AppCustomer_Email
            ),
            AppCustomer_Phone: new FormControl(
              customer.AppCustomer.AppCustomer_Phone
            ),
            AppCustomerType_guid: new FormControl(
              customer.AppCustomerType.AppCustomerType_guid
            ),
          });
          this.customers._updateChangeSubscription();

          // Reset the controls
          this.resetCustomerControls();

          // Close the modal
          this.createCustomerModalOpen = false;
          this.createCustomerLoading = false;

          // Show a snackbar
          this.snkbr.open('Customer created', 'Dismiss', {
            duration: 3000,
          });
        },
        err => {
          console.log(err);
          this.snkbr.open('Failed to create customer', 'Dismiss', {
            duration: Infinity,
          });
          this.createCustomerLoading = false;
        }
      );
  }

  updateCustomer(row: CustomersRowInterface, silent = false) {
    this.disableRows([row]);
    const customer = this.rowToCustomer(row);
    const newCustomer = {
      AppCustomer: {
        AppCustomer_FirstName: row.AppCustomer_FirstName.value,
        AppCustomer_LastName: row.AppCustomer_LastName.value,
        AppCustomer_Email: row.AppCustomer_Email.value,
        AppCustomer_Phone: row.AppCustomer_Phone.value,
      },
      AppCustomerType: {
        AppCustomerType_guid: row.AppCustomerType_guid.value,
      },
    };

    this.saving
      .loadingUntilComplete(
        this.sageApi.patchRequest(
          `customer/${customer.AppCustomer.AppCustomer_guid}`,
          newCustomer
        )
      )
      .subscribe(
        () => {
          // Get the customer type from the guid
          const ctype = this.customerTypeFromGuid(
            row.AppCustomerType_guid.value
          );

          // Update the customer in this.customersRaw
          customer.AppCustomer.AppCustomer_FirstName =
            row.AppCustomer_FirstName.value;
          customer.AppCustomer.AppCustomer_LastName =
            row.AppCustomer_LastName.value;
          customer.AppCustomer.AppCustomer_Email = row.AppCustomer_Email.value;
          customer.AppCustomer.AppCustomer_Phone = row.AppCustomer_Phone.value;
          customer.AppCustomerType.AppCustomerType_guid =
            row.AppCustomerType_guid.value;
          customer.AppCustomerType.AppCustomerType_Name =
            ctype.AppCustomerType_Name;
          customer.AppCustomerType.AppCustomerType_Desc =
            ctype.AppCustomerType_Desc;

          this.enableRows([row]);

          if (!silent) {
            // Show a snackbar
            this.snkbr.open('Customer updated', 'Dismiss', {
              duration: 3000,
            });
          }
        },
        err => {
          this.enableRows([row]);
          console.log(err);
          if (!silent) {
            this.snkbr.open('Failed to update customer', 'Dismiss', {
              duration: Infinity,
            });
          }
        }
      );
  }

  updateAllCustomers(areYouSure = false) {
    const changedRows = this.changedRows();
    if (!areYouSure) {
      this.bm
        .confirm(
          `Update ${changedRows.length} Customers`,
          `Are you sure you want to update ${changedRows.length} customers?`
        )
        .subscribe(result => {
          if (result) {
            this.updateAllCustomers(true);
          }
        });
      return;
    }

    if (changedRows.length === 0) {
      return;
    }
    this.disableRows(changedRows);
    const obs = [];
    for (const row of changedRows) {
      const ob = this.updateCustomer(row, true);
      obs.push(ob);
    }

    // Listen to all the observables in obs. If one fails, enable all rows and show a snackbar
    Observable.forkJoin(obs).subscribe(
      () => {
        this.snkbr.open('All customers updated', 'Dismiss', {
          duration: 3000,
        });
      },
      err => {
        console.log(err);
        this.snkbr.open(
          'Failed to update some customers customers',
          'Dismiss',
          {
            duration: Infinity,
          }
        );
      }
    );
  }

  cancelAllUpdates() {
    const changedRows = this.changedRows();
    this.resetRows(changedRows);
  }

  deleteCustomer(row: CustomersRowInterface) {
    const customer = this.rowToCustomer(row);
    this.disableRows([row]);
    this.saving
      .loadingUntilComplete(
        this.sageApi.deleteRequest(
          `customer/${customer.AppCustomer.AppCustomer_guid}`
        )
      )
      .subscribe(
        () => {
          this.enableRows([row]);

          // Remove the row from this.customers
          const index = this.customers.data.indexOf(row);
          this.customers.data.splice(index, 1);
          this.customers._updateChangeSubscription();

          // Remove the customer from this.customersRaw
          const index2 = this.customersRaw.indexOf(customer);
          this.customersRaw.splice(index2, 1);

          // Show a snackbar
          this.snkbr.open('Customer deleted', 'Dismiss', {
            duration: 3000,
          });
        },
        err => {
          this.enableRows([row]);
          console.log(err);
          this.snkbr.open('Failed to delete customer', 'Dismiss', {
            duration: Infinity,
          });
        }
      );
  }

  customerToRow(customer: ContextCustomerInterface): CustomersRowInterface {
    // Find the row in this.customers
    const row = this.customers.data.find(
      r => r.AppCustomer_guid === customer.AppCustomer.AppCustomer_guid
    );
    return row;
  }

  openCreateCustomerModal() {
    this.createCustomerModalOpen = true;
    this.resetCustomerControls();
  }

  openDeleteCustomerModal(cstmr: CustomersRowInterface) {
    // TODO: Open the edit customer modal
    this.bm
      .confirm(
        `Delete ${cstmr.AppCustomer_FirstName.value} ${cstmr.AppCustomer_LastName.value}?`,
        `Are you sure you want to delete ${cstmr.AppCustomer_FirstName.value} ${cstmr.AppCustomer_LastName.value}?`
      )
      .subscribe(result => {
        if (result) {
          this.deleteCustomer(cstmr);
        }
      });
  }

  resetCustomerControls() {
    this.ccFirstCtrl.setValue('');
    this.ccLastCtrl.setValue('');
    this.ccEmailCtrl.setValue('');
    this.ccPhoneCtrl.setValue('');
    this.ccTypeCtrl.setValue('');
  }
}
